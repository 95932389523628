import { useMutation, useQuery, useQueryClient } from "react-query";
import { QueryKeys } from "../../../variables/queryKeys";
import { ERROR_MESSAGE } from "../../../variables/system";
import {
  addTrueWalletSetting,
  deleteTrueWalletSetting,
  fetchTrueWalletSetting,
  updateTrueWalletSetting,
} from "../../../services/finance-setting/trueWalletSettingService";
import { useErrorHandler } from "../../common/useErrorHandler";
import { useSuccessHandler } from "../../common/useSuccessHandler";

export const useTrueWalletSetting = () => {
  const handleError = useErrorHandler();
  const handleSuccess = useSuccessHandler();
  const queryClient = useQueryClient();

  const { data: trueWalletData, isLoading } = useQuery(
    QueryKeys.TRUE_WALLET_SETTING,
    async () => {
      const response = await fetchTrueWalletSetting();
      return response.data.data;
    },
    {
      onError: (error) => handleError(error, ERROR_MESSAGE),
    },
  );

  const saveMutation = useMutation(
    async ({ values, tw }) => {
      const payload = tw ? { ...tw, ...values } : values;
      return tw
        ? updateTrueWalletSetting(tw.id, payload)
        : addTrueWalletSetting(payload);
    },
    {
      onSuccess: (response) => {
        handleSuccess(response.data.message);
        queryClient.invalidateQueries([QueryKeys.TRUE_WALLET_SETTING]);
      },
      onError: (error) => handleError(error, ERROR_MESSAGE),
    },
  );

  const deleteMutation = useMutation(
    async (id) => await deleteTrueWalletSetting(id),
    {
      onSuccess: (response) => {
        handleSuccess(response.data.message);
        queryClient.invalidateQueries([QueryKeys.TRUE_WALLET_SETTING]);
      },
      onError: (error) => handleError(error, ERROR_MESSAGE),
    },
  );

  const handleSubmit = (values, tw, actions, onClose) => {
    saveMutation.mutate(
      { values, tw },
      {
        onSettled: () => {
          actions.setSubmitting(false);
          onClose();
        },
      },
    );
  };

  const handleDelete = (id) => {
    deleteMutation.mutate(id);
  };

  return {
    trueWalletData,
    handleSubmit,
    handleDelete,
    isLoading,
    isSaving: saveMutation.isLoading,
    isDeleting: deleteMutation.isLoading,
  };
};
