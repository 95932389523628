import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  addPromotionSetting,
  deletePromotionSetting,
  fetchPromotionSetting,
  updatePromotionSetting,
} from "../../../services/system-setting/promotionSettingService";
import { useErrorHandler } from "../../common/useErrorHandler";
import { ERROR_MESSAGE } from "../../../variables/system";
import { QueryKeys } from "../../../variables/queryKeys";
import { useSuccessHandler } from "../../common/useSuccessHandler";

export const usePromotionSetting = () => {
  const handleSuccess = useSuccessHandler();
  const handleError = useErrorHandler();
  const queryClient = useQueryClient();

  const { data: promotionData, isLoading } = useQuery(
    [QueryKeys.PROMOTION_SETTING],
    async () => {
      const response = await fetchPromotionSetting();
      return response.data.data;
    },
    {
      onError: (error) => handleError(error, ERROR_MESSAGE),
    },
  );

  const mutation = useMutation(
    async ({ values, promotion }) => {
      const payload = promotion ? { ...promotion, ...values } : values;
      return promotion
        ? updatePromotionSetting(promotion.id, payload)
        : addPromotionSetting(payload);
    },
    {
      onSuccess: (response) => {
        handleSuccess(response.data.message);
        queryClient.invalidateQueries([QueryKeys.PROMOTION_SETTING]);
      },
      onError: (error) => handleError(error, ERROR_MESSAGE),
    },
  );

  const deleteMutation = useMutation(
    async (id) => await deletePromotionSetting(id),
    {
      onSuccess: (response) => {
        handleSuccess(response.data.message);
        queryClient.invalidateQueries([QueryKeys.PROMOTION_SETTING]);
      },
      onError: (error) => handleError(error, ERROR_MESSAGE),
    },
  );

  const handleToggleProvider = (
    providerId,
    providerName,
    setFieldValue,
    values,
  ) => {
    const isCurrentlyDisabled = values.gameBlockList.some(
      (item) => item.provider === providerName,
    );

    const updatedGameBlockList = isCurrentlyDisabled
      ? values.gameBlockList.filter((item) => item.provider !== providerName)
      : [...values.gameBlockList, { provider: providerName }];

    setFieldValue("gameBlockList", updatedGameBlockList);
  };

  const handleSubmit = (values, promotion, actions, onClose) => {
    mutation.mutate(
      { values, promotion },
      {
        onSettled: () => {
          actions.setSubmitting(false);
          onClose();
        },
      },
    );
  };

  const handleDeletePromotion = (id) => {
    deleteMutation.mutate(id);
  };

  return {
    promotionData,
    isLoading,
    handleSubmit,
    handleToggleProvider,
    handleDeletePromotion,
    isDeleting: deleteMutation.isLoading,
  };
};
