import { useState } from "react";
import { MARKETING_ROLE } from "variables/system";
import { mapJsonToTableData } from "utils/CommonUtils";
import {
  fetchMarketingMemberReportByType,
  fetchMemberMemberReportByType,
} from "services/member-report/memberReportService";

export const useMemberReport = (role, searchType, columnsData) => {
  const [downloadableData, setDownloadableData] = useState([]);

  const fetchData = async (pageIndex, pageSize, search) => {
    const response =
      role !== MARKETING_ROLE
        ? await fetchMemberMemberReportByType(
            pageIndex,
            pageSize,
            search,
            searchType,
          )
        : await fetchMarketingMemberReportByType(
            pageIndex,
            pageSize,
            search,
            searchType,
          );

    const formattedRecords = mapJsonToTableData(
      response.results,
      columnsData(),
    );
    setDownloadableData(formattedRecords);
    return response;
  };

  return {
    fetchData,
    downloadableData,
  };
};
