import { Badge, Flex, Text } from "@chakra-ui/react";
import { numberWithCommas } from "../../../utils/ColumnsUtils";
import {
  getStaffCreditStatusBackground,
  getStaffCreditStatusNiceName,
} from "../../../utils/CommonUtils";

export const staffCreditHistoryColumnsData = [
  {
    Header: "สถานะ",
    accessor: "mistakeType",
    textAlign: "left",
    // width: "135px",
    justifyContent: "flex-start",
    Cell: ({ cell }) => (
      <Flex align="center">
        <Badge
          bg={getStaffCreditStatusBackground(cell.value)}
          w="85px"
          h="28px"
          borderRadius="15px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          fontWeight="normal"
        >
          {getStaffCreditStatusNiceName(cell.value)}
        </Badge>
      </Flex>
    ),
  },
  {
    Header: "ยูสเซอร์",
    accessor: "username",
    textAlign: "left",
    justifyContent: "flex-start",
  },
  {
    Header: "เครดิตก่อนทำรายการ",
    accessor: "beforeAmount",
    textAlign: "right",
    justifyContent: "flex-end",
    Cell: ({ value }) => numberWithCommas(value),
  },
  {
    Header: "จำนวน",
    accessor: "amount",
    textAlign: "right",
    justifyContent: "flex-end",
    Cell: ({ value }) => (
      <Text fontWeight="bold" color="#fff">
        {numberWithCommas(value)}
      </Text>
    ),
  },
  {
    Header: "เครดิตหลังทำรายการ",
    accessor: "afterAmount",
    textAlign: "right",
    justifyContent: "flex-end",
    Cell: ({ value }) => numberWithCommas(value),
  },

  {
    Header: "วันที่",
    accessor: "createdDate",
    textAlign: "left",
    justifyContent: "flex-start",
  },
  {
    Header: "หมายเหตุ",
    accessor: "reason",
    textAlign: "left",
    justifyContent: "flex-start",
  },
  {
    Header: "ทำโดย",
    accessor: "createdBy",
    textAlign: "left",
    justifyContent: "flex-start",
  },
];
