import { Badge, Flex, Image, Spinner, Text } from "@chakra-ui/react";
import { banks } from "../../bank";
import avatar8 from "assets/img/avatars/avatar8.png";
import { formatDate } from "utils/DateformatUtils";
import { getDepositStatus } from "../../system";
import tw from "assets/img/banks/ทรูวอเล็ต.png";
import DepositEditButtons from "views/transaction-report/deposit/components/DepositEditButtons";
import { getTransactionStatusBackground } from "utils/CommonUtils";

const StatusBadge = ({ status }) => {
  return (
    <Flex align="center">
      <Badge
        bg={getTransactionStatusBackground(status)}
        px={3}
        h="28px"
        speed="0.8s"
        borderRadius="15px"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {status === "PENDING" && <Spinner size="xs" mr={1} />}
        {
          <Text color="#fff" fontWeight="normal">
            {getDepositStatus(status)}
          </Text>
        }
      </Badge>
    </Flex>
  );
};

export const depositTransactionReportColumnsData = (handleModalOpen) => {
  return [
    {
      Header: "สถานะ",
      accessor: "status",
      textAlign: "left",
      justifyContent: "flex-start",
      Cell: ({ cell }) => <StatusBadge status={cell.value} />,
    },
    {
      Header: "ยูสเซอร์",
      accessor: "username",
      disableSortBy: true,
      textAlign: "left",
      justifyContent: "flex-start",
    },
    {
      Header: "ชื่อ-สกุล",
      accessor: "fullName",
      textAlign: "left",
      justifyContent: "flex-start",
      Cell: ({ row }) => {
        return (
          <>
            {row.original.firstName} {row.original.lastName}
          </>
        );
      },
    },
    {
      Header: "ยอดเงินฝาก",
      accessor: "amount",
      textAlign: "right",
      justifyContent: "flex-end",
      Cell: ({ value }) => (
        <Text color="#fff" fontWeight="bold">
          {value}
        </Text>
      ),
    },
    {
      Header: "ยอดโบนัส",
      accessor: "bonus",
      disableSortBy: true,
      textAlign: "right",
      justifyContent: "flex-end",
    },
    {
      Header: "เครดิตก่อนฝาก",
      accessor: "beforeAmount",
      textAlign: "right",
      justifyContent: "flex-end",
    },
    {
      Header: "เครดิตหลังฝาก",
      accessor: "afterAmount",
      textAlign: "right",
      justifyContent: "flex-end",
    },
    {
      Header: "ธนาคารที่โอนเข้า",
      Cell: ({ cell, row }) => (
        <Flex align="center" justify="center" w="100%">
          {row.original.bankCode ? (
            <Image
              src={`${banks[row.original.bankCode]?.logo}`}
              alt={cell.value}
              backgroundColor={banks[row.original.bankCode]?.color}
              boxSize="30px"
              borderRadius="50%"
              boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
              padding="5px"
            />
          ) : row.original.type === "TRUEWALLET" ? (
            <Image
              src={tw}
              alt={cell.value}
              // backgroundColor={banks[row.original.bankCode]?.color}
              boxSize="30px"
              borderRadius="50%"
              boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
            />
          ) : (
            <Image
              src={avatar8}
              alt={cell.value}
              backgroundColor={banks[row.original.bankCode]?.color}
              boxSize="30px"
              borderRadius="50%"
              boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
            />
          )}
        </Flex>
      ),
      disableSortBy: true,
    },
    {
      Header: "แก้ไข",
      minWidth: "235px",
      textAlign: "left",
      justifyContent: "flex-start",
      Cell: ({ row }) => (
        <Flex align="center" w="100%">
          <DepositEditButtons row={row} handleModalOpen={handleModalOpen} />
        </Flex>
      ),
    },
    {
      Header: "โปรโมชั่น",
      accessor: "promotionName",
      textAlign: "left",
      justifyContent: "flex-start",
    },
    {
      Header: "เพิ่มโดย",
      accessor: "createdBy",
      textAlign: "left",
      justifyContent: "flex-start",
    },
    {
      Header: "แก้ไขโดย",
      accessor: "updatedBy",
      textAlign: "left",
      justifyContent: "flex-start",
    },
    {
      Header: "หมายเหตุ",
      accessor: "reason",
      textAlign: "left",
      justifyContent: "flex-start",
    },
    {
      Header: "วันที่ทำรายการ",
      accessor: "createdDate",
      textAlign: "left",
      justifyContent: "flex-start",
      Cell: ({ value }) => formatDate(value),
    },
    {
      Header: "วันที่อัพเดต",
      accessor: "updatedDate",
      textAlign: "left",
      justifyContent: "flex-start",
      Cell: ({ value }) => formatDate(value),
    },
  ];
};
