import React from "react";
import { Box, Button, Flex, Switch, Text } from "@chakra-ui/react";
import ImageUploader from "components/ImageUploader/ImageUploader";
import Card from "components/Card/Card";
import { DeleteIcon } from "../../../../components/Icons/Icons";

const ActivityImageCard = ({
  title,
  imageSrc,
  onDelete,
  onToggle,
  isActive,
  onImageUpload,
}) => {
  const handleImageUpload = (image) => {
    onImageUpload(image);
  };

  return (
    <Card
      borderRadius="lg"
      overflow="hidden"
      p={0}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Text
        fontSize="14px"
        fontWeight="normal"
        alignSelf="flex-start"
        color="#fff"
      >
        {title}{" "}
        <Box as="span" color="green.500" fontWeight="normal" fontSize="14px">
          (ขนาด 768 x 768 px)
        </Box>
      </Text>
      <Flex justify="space-between" align="center" width="100%" mb={2}>
        <Flex>
          <Switch isChecked={isActive} onChange={onToggle} />
          <Text ml={2} color={"#8C9592"} fontSize="14px">
            แสดงผล
          </Text>
        </Flex>
        <Button
          bg={"red.500"}
          onClick={onDelete}
          variant="outline"
          size="sm"
          border="none"
        >
          <DeleteIcon />
        </Button>
      </Flex>
      <ImageUploader initialImage={imageSrc} onUpload={handleImageUpload} />
    </Card>
  );
};

export default ActivityImageCard;
