import { useMutation, useQuery, useQueryClient } from "react-query";
import { QueryKeys } from "../../../variables/queryKeys";
import {
  ERROR_MESSAGE,
  MARKETING_ROLE,
  PREFIX,
} from "../../../variables/system";
import {
  addStaff,
  fetchStaff,
  fetchStaffRole,
  updateStaff,
} from "../../../services/system-setting/staffSettingService";
import { useErrorHandler } from "../../common/useErrorHandler";
import { useSuccessHandler } from "../../common/useSuccessHandler";

export const useStaffSetting = () => {
  const prefix = localStorage.getItem(PREFIX);
  const handleError = useErrorHandler();
  const handleSuccess = useSuccessHandler();
  const queryClient = useQueryClient();

  const { data: staffData, isLoading: isStaffDataLoading } = useQuery(
    [QueryKeys.STAFF_SETTING],
    async () => {
      const response = await fetchStaff(prefix);
      return response.data.data.filter((item) => item.role !== MARKETING_ROLE);
    },
    {
      onError: (error) => handleError(error, ERROR_MESSAGE),
    },
  );

  const { data: staffRolesData, isLoading: isRoleDataLoading } = useQuery(
    [QueryKeys.STAFF_ROLES],
    async () => {
      const response = await fetchStaffRole();
      return response.data.data;
    },
    {
      onError: (error) => handleError(error, ERROR_MESSAGE),
    },
  );

  const mutation = useMutation(
    async ({ values, staff }) => {
      const payload = staff ? { ...staff, ...values } : values;
      return staff ? updateStaff(payload) : addStaff(payload);
    },
    {
      onSuccess: (response, { isMarketing }) => {
        handleSuccess(response.data.message);
        queryClient.invalidateQueries(
          isMarketing ? QueryKeys.MARKETING_SETTING : QueryKeys.STAFF_SETTING,
        );
      },
      onError: (error) => {
        handleError(error, ERROR_MESSAGE);
      },
    },
  );

  const handleSaveStaff = (
    values,
    staff,
    actions,
    isMarketing = false,
    onClose,
  ) => {
    mutation.mutate(
      { values, staff, isMarketing },
      {
        onSettled: () => {
          actions.setSubmitting(false);
          onClose();
        },
      },
    );
  };

  return {
    staffData,
    isStaffDataLoading,
    staffRolesData,
    isRoleDataLoading,
    handleSaveStaff,
  };
};
