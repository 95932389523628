import React from "react";
import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";

const QrCodeModal = ({ isOpen, onClose, transaction }) => {
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Box textAlign="left">
            <Text fontSize="20px" color="#fff">
              QR CODE
            </Text>
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p={8}>
          {transaction?.image ? (
            <Flex justifyContent="center" alignItems="center" height="100%">
              <img
                src={transaction.image ? transaction.image : ""}
                alt="icon"
              />
            </Flex>
          ) : null}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default QrCodeModal;
