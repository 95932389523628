import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  addBanner,
  deleteBanner,
  fetchBanner,
  updateBanner,
} from "../../../services/website-setting/bannerService";
import { ERROR_MESSAGE } from "../../../variables/system";
import { useErrorHandler } from "../../common/useErrorHandler";
import { QueryKeys } from "../../../variables/queryKeys";
import { useSuccessHandler } from "../../common/useSuccessHandler";

export const useBannerSetting = () => {
  const queryClient = useQueryClient();
  const handleError = useErrorHandler();
  const handleSuccess = useSuccessHandler();

  const { data: bannerData, isLoading } = useQuery(
    [QueryKeys.BANNER_SETTING],
    async () => {
      const response = await fetchBanner();
      return response.data.data;
    },
    {
      onError: (error) => handleError(error, ERROR_MESSAGE),
    },
  );

  const mutation = useMutation(
    async ({ values, banner }) => {
      const payload = banner ? { ...banner, ...values } : values;
      return banner ? updateBanner(payload) : addBanner(payload);
    },
    {
      onSuccess: (response) => {
        handleSuccess(response.data.message);
        queryClient.invalidateQueries([QueryKeys.BANNER_SETTING]);
      },
      onError: (error) => handleError(error, ERROR_MESSAGE),
    },
  );

  const deleteMutation = useMutation(async (id) => await deleteBanner(id), {
    onSuccess: (response) => {
      handleSuccess(response.data.message);
      queryClient.invalidateQueries([QueryKeys.BANNER_SETTING]);
    },
    onError: (error) => handleError(error, ERROR_MESSAGE),
  });

  const handleSubmit = (values, banner, actions, onClose) => {
    mutation.mutate(
      { values, banner },
      {
        onSettled: () => {
          actions.setSubmitting(false);
          onClose();
        },
      },
    );
  };

  const handleDeleteBanner = (id) => {
    deleteMutation.mutate(id);
  };

  return {
    bannerData,
    isLoading,
    isSaving: mutation.isLoading,
    isDeleting: deleteMutation.isLoading,
    handleSubmit,
    handleDeleteBanner,
  };
};
