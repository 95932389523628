import { useMutation, useQuery, useQueryClient } from "react-query";
import { QueryKeys } from "../../../variables/queryKeys";
import { ERROR_MESSAGE } from "../../../variables/system";
import {
  fetchLiveChatSetting,
  updateLiveChatSetting,
} from "../../../services/website-setting/liveChatService";
import { useErrorHandler } from "../../common/useErrorHandler";
import { useSuccessHandler } from "../../common/useSuccessHandler";

export const useLiveChatSetting = () => {
  const handleError = useErrorHandler();
  const handleSuccess = useSuccessHandler();
  const queryClient = useQueryClient();

  const { data: liveChatSettingData, isLoading } = useQuery(
    [QueryKeys.LIVECHAT_SETTING],
    async () => {
      const response = await fetchLiveChatSetting();
      return response.data.data;
    },
    {
      onError: (error) => handleError(error, ERROR_MESSAGE),
    },
  );

  const mutation = useMutation((payload) => updateLiveChatSetting(payload), {
    onSuccess: (response) => {
      handleSuccess(response.data.message);
      queryClient.invalidateQueries(QueryKeys.LIVECHAT_SETTING);
    },
    onError: (error) => {
      handleError(error, ERROR_MESSAGE);
    },
  });

  const handleSave = (payload) => {
    mutation.mutate(payload);
  };

  return {
    liveChatSettingData,
    isLoading,
    handleSave,
    isSaving: mutation.isLoading,
  };
};
