import { useErrorHandler } from "../../common/useErrorHandler";
import { useState } from "react";
import { ERROR_MESSAGE } from "../../../variables/system";
import { QueryKeys } from "../../../variables/queryKeys";
import { useQuery } from "react-query";
import { mapJsonToTableData } from "../../../utils/CommonUtils";
import { fetchListMarketingReport } from "../../../services/website-report/marketingReportService";
import { marketingReportColumnsData } from "../../../variables/columns/website-report/marketingReportColumnsData";

export const useMarketingReport = (search) => {
  const handleError = useErrorHandler();
  const [downloadableData, setDownloadableData] = useState([]);

  const queryKey = [
    QueryKeys.MARKETING_REPORT,
    search.createdDateFrom,
    search.createdDateTo,
  ];

  const { data, isLoading } = useQuery(
    queryKey,
    async () => {
      const response = await fetchListMarketingReport(search);
      return response.data.data;
    },
    {
      onError: (error) => handleError(error, ERROR_MESSAGE),
      onSuccess: (data) => {
        const formattedRecords = mapJsonToTableData(
          data,
          marketingReportColumnsData,
        );
        setDownloadableData(formattedRecords);
      },
    },
  );

  return {
    data,
    isLoading,
    downloadableData,
  };
};
