import {
  Flex,
  Stack,
  SimpleGrid,
  Box,
  Button,
  Icon,
  Text,
} from "@chakra-ui/react";
import AnimatedFlex from "components/AnimatedFlex/MotionFlex";
import PromotionCard from "./components/PromotionCard";
import React, { useState } from "react";
import DeletePromotionModal from "./components/DeletePromotionModal";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import { CreateIcon } from "components/Icons/Icons";
import ManagePromotionModal from "./components/ManagePromotionModal";
import { usePromotionSetting } from "hooks/business/system-setting/usePromotionSetting";

const PromotionSetting = ({ pageName }) => {
  const [selectedPromotion, setSelectedPromotion] = useState(null);
  const {
    promotionData,
    handleSubmit,
    handleToggleProvider,
    handleDeletePromotion,
    isDeleting,
  } = usePromotionSetting();

  const [modalState, setModalState] = useState({
    managePromotionModalOpen: false,
    deletePromotionModalOpen: false,
  });

  const handleModalOpen = (modal, promotion = null) => {
    setSelectedPromotion(promotion);
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: true,
    }));
  };

  const handleModalClose = (modal) => {
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: false,
    }));
    setSelectedPromotion(null);
  };

  return (
    <Flex direction="column" overflow="hidden">
      <Stack direction="column" spacing="15px" w="100%">
        <AnimatedFlex justify="space-between" duration={0.4} delay={0.4}>
          <Text
            fontSize="24px"
            fontWeight="bold"
            color="green.500"
            mb="12px"
            mt="12px"
          >
            {pageName}
          </Text>
        </AnimatedFlex>
        <Card pb="64px">
          <CardHeader pb="32px" pt="6px">
            <Flex justifyContent="space-between" w="100%">
              <Text fontSize="18px" fontWeight="bold" color="#fff">
                จัดการตั้งค่าโปรโมชั่น
              </Text>
              <Button
                width={{ base: "100%", md: "auto" }}
                minW={{ base: "none", md: "150px" }}
                background="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
                variant="solid"
                borderRadius="33px"
                fontSize="14px"
                fontWeight="normal"
                onClick={() => handleModalOpen("managePromotionModal")}
              >
                <Icon as={CreateIcon} mr="5px" />
                เพิ่มโปรโมชั่น
              </Button>
            </Flex>
          </CardHeader>
          <AnimatedFlex justify="flex-end" duration={0.4} delay={0.6}>
            <SimpleGrid
              columns={{ base: 1, md: 2, lg: 3 }}
              spacing={6}
              w="100%"
            >
              {promotionData?.map((promo) => (
                <Box key={promo.id}>
                  <PromotionCard
                    promotion={promo}
                    onEdit={() =>
                      handleModalOpen("managePromotionModal", promo)
                    }
                    onDelete={() =>
                      handleModalOpen("deletePromotionModal", promo)
                    }
                  />
                </Box>
              ))}
            </SimpleGrid>
          </AnimatedFlex>
        </Card>
        <ManagePromotionModal
          isOpen={modalState.managePromotionModalOpen}
          onClose={() => handleModalClose("managePromotionModal")}
          promotion={selectedPromotion}
          handleSubmit={handleSubmit}
          handleToggle={handleToggleProvider}
        />
        <DeletePromotionModal
          isOpen={modalState.deletePromotionModalOpen}
          onClose={() => handleModalClose("deletePromotionModal")}
          promotion={selectedPromotion}
          handleSubmit={handleDeletePromotion}
          isSubmitting={isDeleting}
        />
      </Stack>
    </Flex>
  );
};

export default PromotionSetting;
