import { Box, Flex, Spacer, Stack, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { membersColumnsData } from "variables/columns/member-management/membersColumnsData";

import SearchTableRemote from "components/Tables/SearchTableRemote";

import AnimatedFlex from "components/AnimatedFlex/MotionFlex";
import CustomRangePicker from "components/CustomRangePicker/CustomRangePicker";
import AddCreditModal from "./components/AddCreditModal";
import AddCreditSlipModal from "./components/AddCreditSlipModal";

import RemoveCreditModal from "./components/RemoveCreditModal";
import ResetPasswordModal from "./components/ResetPasswordModal";
import ManageMemberModal from "./components/ManageMemberModal";

import { QueryClientProvider, useQueryClient } from "react-query";

import { AddMemberIcon } from "components/Icons/Icons";
import { useSearch } from "hooks/common/useSearch";
import { APP_PROFILE, MembersSearchTypes } from "variables/system";
import { QueryKeys } from "variables/queryKeys";
import { useCredit } from "hooks/business/member-management/useCredit";
import { useMemberReport } from "hooks/business/member-report/useMemberReport";
import { useMember } from "hooks/business/member-management/useMember";

const Members = ({ pageName }) => {
  const queryClient = useQueryClient();

  const [modalState, setModalState] = useState({
    manageMemberModalOpen: false,
    addCreditModalOpen: false,
    addCreditSlipModalOpen: false,
    removeCreditModalOpen: false,
    resetPasswordModalOpen: false,
  });

  const [selectedMember, setSelectedMember] = useState(null);

  const role = JSON.parse(localStorage.getItem(APP_PROFILE)).role;

  const dateFieldsNames = {
    from: "createdDateFrom",
    to: "createdDateTo",
  };

  const dateSearchFormat = "DD/MM/yyyy HH:mm";

  const { search, setSearch, selectedDates, handleDateChange } = useSearch(
    dateFieldsNames,
    dateSearchFormat,
    null,
    null,
    false,
  );

  const { handleSaveMember } = useMember();

  const { fetchData, downloadableData } = useMemberReport(
    role,
    MembersSearchTypes.ALL,
    membersColumnsData,
  );

  const { handleCredit } = useCredit();

  const handleModalOpen = (modal, user = null) => {
    setSelectedMember(user);
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: true,
    }));
  };

  const handleModalClose = (modal) => {
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: false,
    }));
    setSelectedMember(null);
  };

  return (
    <Flex direction="column" overflow="hidden">
      <Stack direction="column" spacing="15px" w="100%">
        <AnimatedFlex justify="space-between" duration={0.4} delay={0.4}>
          <Text
            fontSize="24px"
            fontWeight="bold"
            color="green.500"
            mb="12px"
            mt="12px"
          >
            {pageName}
          </Text>
        </AnimatedFlex>
        <AnimatedFlex duration={0.4} delay={0.8}>
          <Card px="22px" pb="38px">
            <CardHeader pb="12px" pt="12px">
              <Flex direction="column" justifyContent="center">
                <Text fontSize="18px" fontWeight="bold" mb="6px" color="#fff">
                  จัดการสมาชิกทั้งหมด
                </Text>
              </Flex>
              <Spacer />
              <Box>
                <CustomRangePicker
                  selectedDates={selectedDates}
                  onDateChange={handleDateChange}
                />
              </Box>
            </CardHeader>
            <CardBody>
              <QueryClientProvider client={queryClient}>
                <SearchTableRemote
                  query={QueryKeys.MEMBERS}
                  columnsData={membersColumnsData(handleModalOpen, queryClient)}
                  fetchData={fetchData}
                  search={search}
                  setSearch={setSearch}
                  showExport={true}
                  showAction={true}
                  handleModalOpen={handleModalOpen}
                  modalName={"manageMemberModal"}
                  actionButtonIcon={AddMemberIcon}
                  actionButtonText={"เพิ่มสมาชิก"}
                  downloadableData={downloadableData}
                  filename={pageName}
                />
              </QueryClientProvider>
            </CardBody>
          </Card>
        </AnimatedFlex>
        <ManageMemberModal
          isOpen={modalState.manageMemberModalOpen}
          onClose={() => handleModalClose("manageMemberModal")}
          member={selectedMember}
          handleSubmit={handleSaveMember}
        />
        <AddCreditModal
          isOpen={modalState.addCreditModalOpen}
          onClose={() => handleModalClose("addCreditModal")}
          member={selectedMember}
          handleSubmit={handleCredit}
        />
        <AddCreditSlipModal
          isOpen={modalState.addCreditSlipModalOpen}
          onClose={() => handleModalClose("addCreditSlipModal")}
          member={selectedMember}
          handleSubmit={handleCredit}
        />
        <RemoveCreditModal
          isOpen={modalState.removeCreditModalOpen}
          onClose={() => handleModalClose("removeCreditModal")}
          member={selectedMember}
          handleSubmit={handleCredit}
        />
        <ResetPasswordModal
          isOpen={modalState.resetPasswordModalOpen}
          onClose={() => handleModalClose("resetPasswordModal")}
          member={selectedMember}
        />
      </Stack>
    </Flex>
  );
};

export default Members;
