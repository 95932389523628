import React from "react";
import {
  Box,
  // Image,
  Text,
  Flex,
  Switch,
  IconButton,
  Icon,
  Stack,
  Image,
} from "@chakra-ui/react";
import { DeleteIcon, EditIcon } from "../../../../components/Icons/Icons";
import { banks } from "../../../../variables/bank";

const BankCard = ({ bank, onEdit, onDelete }) => {
  return (
    <Box borderRadius="10px" overflow="hidden" bg="#1E252C">
      <Box h="285px" bg="#171D23" borderRadius="10px" p={6}>
        <Flex align="flex-start" pb="18px">
          <Image
            src={`${banks[bank.bankCode]?.logo}`}
            p={2}
            backgroundColor={banks[bank.bankCode]?.color}
            boxSize="50px"
            borderRadius="50%"
            boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
          />
          <Stack spacing={1} align="flex-start" ml={6}>
            <Text fontWeight="normal" fontSize="12px">
              ประเภทบริการ:{" "}
              <Box as="span" color="#fff" fontWeight="bold">
                {bank.serviceType}
              </Box>
            </Text>
            <Text fontSize="18px" color="gray.400" fontWeight="bold">
              {banks[bank.bankCode]?.name_th}
            </Text>
          </Stack>
        </Flex>

        <Flex
          direction="column"
          align="flex-start"
          mt="12px"
          borderBottom="1px solid #373F48"
          pb="18px"
        >
          <Text fontSize="14px" color="gray.400">
            เลขบัญชี
          </Text>

          <Text fontSize="29px" fontWeight="bold" color="green.500">
            {bank.bankAccountNo}
          </Text>
        </Flex>

        <Stack spacing={1} align="flex-start" mt="16px">
          <Text fontSize="14px" color="#8C9592">
            ชื่อบัญชี
          </Text>
          <Text fontSize="18px" color="#8C9592" fontWeight="bold">
            {bank.bankAccountName}
          </Text>
        </Stack>
      </Box>
      <Box p={6}>
        <Box
          border="1px solid #373F48"
          borderRadius="6px"
          px={4}
          bg="#252d35"
          mt={2}
        >
          <Flex
            justify="space-between"
            p={3}
            mx={-4}
            borderBottom="1px solid #373F48"
          >
            <Text fontWeight="medium" fontSize="sm">
              ฝาก
            </Text>
            <Switch
              isChecked={bank.bankType == "DEPOSIT" ? true : false}
              readOnly
            />
          </Flex>
          <Flex
            justify="space-between"
            p={3}
            mx={-4}
            borderBottom="1px solid #373F48"
          >
            <Text fontWeight="medium" fontSize="sm">
              ถอน
            </Text>
            <Switch
              isChecked={bank.bankType == "WITHDRAW" ? true : false}
              readOnly
            />
          </Flex>
          <Flex justify="space-between" p={3} mx={-4}>
            <Text fontWeight="medium" fontSize="sm">
              สถานะเปิดใช้งาน
            </Text>
            <Switch isChecked={bank.active} readOnly />
          </Flex>
        </Box>
        <Flex justify="space-between" align="center">
          <Text fontSize="14px">จัดการ</Text>
          <Flex mt={4}>
            <IconButton
              icon={<Icon as={EditIcon} />}
              w={"36px"}
              h={"26px"}
              cursor="pointer"
              variant="solid"
              color="#fff"
              bg="green.500"
              size="sm"
              onClick={onEdit}
              mr={2}
            />
            <IconButton
              icon={<Icon as={DeleteIcon} />}
              w={"36px"}
              h={"26px"}
              cursor="pointer"
              color="#fff"
              bg="red.500"
              variant="solid"
              onClick={onDelete}
            />
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};

export default BankCard;
