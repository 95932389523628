import {
  Badge,
  Flex,
  IconButton,
  Input,
  NumberInput,
  NumberInputField,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { getJackpotBackground } from "../../../utils/CommonUtils";
import {
  GiveJackpotIcon,
  SystemSettingIcon,
} from "../../../components/Icons/Icons";

export const jackpotSettingColumnsData = (
  handleModalOpen,
  handleValueChange,
) => [
  {
    Header: "ประเภท",
    accessor: "type",
    textAlign: "left",
    justifyContent: "flex-start",
    width: "325px",
    Cell: ({ cell }) => (
      <Flex align="center">
        <Badge
          bg={getJackpotBackground(cell.value)}
          w="74px"
          h="28px"
          borderRadius="15px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          fontWeight="normal"
        >
          {cell.value.toUpperCase()}
        </Badge>
      </Flex>
    ),
    disableSortBy: true,
  },
  {
    Header: "ค่่าเริ่มต้น",
    accessor: "value",
    textAlign: "right",
    justifyContent: "flex-end",
    width: "275px",
    Cell: ({ row }) => {
      const [localValue, setLocalValue] = useState(
        row.original.value.toFixed(2),
      );
      const handleChange = (e) => {
        setLocalValue(e.target.value);
      };

      const handleBlur = () => {
        let val = parseFloat(localValue);
        if (isNaN(val) || val < 0) {
          val = 0;
        }
        setLocalValue(val.toFixed(2));
        handleValueChange(row.original.id, "value", val);
      };

      return (
        <Input
          borderRadius="6px"
          value={localValue}
          onChange={handleChange}
          onBlur={handleBlur}
          fontSize="sm"
          textAlign="right"
        />
      );
    },
    disableSortBy: true,
  },
  {
    Header: "อัตราการขยับ / วินาที",
    accessor: "rate",
    textAlign: "right",
    justifyContent: "flex-end",
    width: "275px",
    Cell: ({ row }) => {
      const [localRate, setLocalRate] = useState(row.original.rate.toFixed(2));
      const handleChange = (e) => {
        setLocalRate(e.target.value);
      };

      const handleBlur = () => {
        let val = parseFloat(localRate);
        if (isNaN(val) || val < 0) {
          val = 0;
        }
        setLocalRate(val.toFixed(2));
        handleValueChange(row.original.id, "rate", val);
      };

      return (
        <Input
          borderRadius="6px"
          value={localRate}
          onChange={handleChange}
          onBlur={handleBlur}
          fontSize="sm"
          textAlign="right"
        />
      );
    },
    disableSortBy: true,
  },
  {
    Header: "ค่าปัจจุบัน",
    accessor: "current",
    textAlign: "right",
    justifyContent: "flex-end",
    Cell: ({ row, value }) => {
      const initialValue = parseFloat(value);
      const incrementPerSec = parseFloat(row.original.rate);
      const [currentValue, setCurrentValue] = useState(initialValue);

      useEffect(() => {
        const interval = setInterval(() => {
          setCurrentValue((prev) => prev + incrementPerSec);
        }, 1000);

        return () => clearInterval(interval);
      }, [incrementPerSec]);

      return (
        <NumberInput value={currentValue.toFixed(2)} step={0.01} isReadOnly>
          <NumberInputField
            fontSize="14px"
            bgColor="transparent"
            textAlign="right"
            fontWeight="bold"
            border="none"
            color="#fff"
            p={0}
          />
        </NumberInput>
      );
    },
    disableSortBy: true,
  },
  {
    Header: "",
    accessor: "setting",
    textAlign: "right",
    justifyContent: "flex-end",
    width: "35px",
    Cell: ({ row }) => (
      <>
        <IconButton
          variant="outline"
          border="none"
          mr={0}
          icon={<SystemSettingIcon h="24px" w="24px" color="green.500" />}
          onClick={() =>
            handleModalOpen("jackpotBotSettingModal", row.original)
          }
        />
        <IconButton
          variant="outline"
          border="none"
          icon={<GiveJackpotIcon h="24px" w="24px" color="green.500" />}
          onClick={() => handleModalOpen("manageJackpotModal", row.original)}
        />
      </>
    ),
    disableSortBy: true,
  },
];
