import { Button, Flex, Stack, Switch, Text } from "@chakra-ui/react";
import AnimatedFlex from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import SimpleTable from "components/Tables/SimpleTable";

import React, { useState } from "react";
import { jackpotSettingColumnsData } from "variables/columns/system-setting/jackpotSettingColumnsData";

import ManageJackpotModal from "./components/ManageJackpotModal";
import JackpotBotSettingModal from "./components/JackpotBotSettingModal";
import { useJackpotSetting } from "hooks/business/system-setting/useJackpotSetting";

const JackpotSetting = ({ pageName }) => {
  const [selectedJackpot, setSelectedJackpot] = useState(null);
  const {
    jackpotData,
    verifySuccessMessage,
    verifyUnSuccessMessage,
    isUserVerified,
    isLoading,
    isActive,
    isSaving,
    handleValueChange,
    handleSave,
    handleSwitchChange,
    handleSaveJackpotBotSetting,
    handleGiveJackpot,
    handleVerifyJackpot,
  } = useJackpotSetting();

  const [modalState, setModalState] = useState({
    manageJackpotModalOpen: false,
    jackpotBotSettingModalOpen: false,
  });

  const handleModalOpen = (modal, jackpot = null) => {
    setSelectedJackpot(jackpot);
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: true,
    }));
  };

  const handleModalClose = (modal) => {
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: false,
    }));
    setSelectedJackpot(null);
  };

  return (
    <Flex direction="column">
      <Stack direction="column" spacing="15px" w="100%">
        <AnimatedFlex justify="space-between" duration={0.4} delay={0.4}>
          <Text
            fontSize="24px"
            fontWeight="bold"
            color="green.500"
            mb="12px"
            mt="12px"
          >
            {pageName}
          </Text>
        </AnimatedFlex>
        <AnimatedFlex duration={0.4} delay={0.6}>
          <Card px="22px" pb="64px">
            <CardHeader pb="12px" pt="12px">
              <Flex justifyContent="space-between" alignItems="center" w="100%">
                <Text color="white" fontSize="18px" fontWeight="bold" pb="32px">
                  รางวัลแจ๊คพ๊อต
                </Text>
              </Flex>
            </CardHeader>
            <Flex>
              <Switch
                colorScheme="green"
                isChecked={isActive}
                onChange={handleSwitchChange}
              />
              <Text mb="2px" ml={6}>
                เปิด/ปิด รางวัลแจ็คพอต
              </Text>
            </Flex>
            <CardBody>
              <SimpleTable
                tableData={jackpotData}
                columnsData={jackpotSettingColumnsData(
                  handleModalOpen,
                  handleValueChange,
                )}
                isLoading={isLoading}
              />
            </CardBody>
            <AnimatedFlex
              justify="flex-start"
              duration={0.4}
              delay={0.8}
              pt="15px"
            >
              <Button
                width={{ base: "100%", md: "auto" }}
                minW={{ base: "none", md: "150px" }}
                background="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
                borderRadius="33px"
                fontSize="14px"
                fontWeight="normal"
                onClick={handleSave}
                isLoading={isSaving}
              >
                บันทึกข้อมูล
              </Button>
            </AnimatedFlex>
          </Card>
        </AnimatedFlex>
        <ManageJackpotModal
          isOpen={modalState.manageJackpotModalOpen}
          onClose={() => handleModalClose("manageJackpotModal")}
          jackpot={selectedJackpot}
          handleSubmit={handleGiveJackpot}
          handleVerify={handleVerifyJackpot}
          isUserVerified={isUserVerified}
          verifySuccessMessage={verifySuccessMessage}
          verifyUnSuccessMessage={verifyUnSuccessMessage}
        />
        <JackpotBotSettingModal
          isOpen={modalState.jackpotBotSettingModalOpen}
          onClose={() => handleModalClose("jackpotBotSettingModal")}
          jackpot={selectedJackpot}
          jackpotData={jackpotData}
          isActive={isActive}
          handleSubmit={handleSaveJackpotBotSetting}
        />
      </Stack>
    </Flex>
  );
};

export default JackpotSetting;
