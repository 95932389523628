import React, { useEffect, useState } from "react";
import SockJS from "sockjs-client";
import Stomp from "stompjs";

import {
  API_BASE_URL,
  URL_WEBSOCKET,
  ERROR_MESSAGE,
  PREFIX,
} from "../../variables/system";
import { useErrorHandler } from "hooks/common/useErrorHandler";
import { NotificationToast } from "../NotificationToast/NotificationToast";
import { useToast } from "@chakra-ui/react";

const getRandomMilliseconds = () => {
  const minSeconds = 30; // Minimum number of seconds (30 seconds)
  const maxSeconds = 60; // Maximum number of seconds (1 minute)

  // Convert seconds to milliseconds
  const minMilliseconds = minSeconds * 1000;
  const maxMilliseconds = maxSeconds * 1000;

  // Generate a random number between minMilliseconds and maxMilliseconds
  const randomMilliseconds =
    Math.floor(Math.random() * (maxMilliseconds - minMilliseconds + 1)) +
    minMilliseconds;

  return randomMilliseconds;
};

const getCurrentFormattedTime = () => {
  const now = new Date();
  const pad = (n) => n.toString().padStart(2, "0");
  return `${now.getFullYear().toString().slice(2)}-${pad(
    now.getMonth() + 1,
  )}-${pad(now.getDate())} ${pad(now.getHours())}:${pad(
    now.getMinutes(),
  )}:${pad(now.getSeconds())}`;
};

const NotificationWebsocket = ({ setTask }) => {
  const [sock, setSock] = useState(null);
  const audioRef = React.createRef();
  const soundFile = "/sound/noti-livechat.mp3";
  const [lastNotificationId, setLastNotificationId] = useState(null);

  const handleError = useErrorHandler();
  const toast = useToast();

  useEffect(() => {
    const notisound = audioRef.current;
    const connect = () => {
      const socket = new SockJS(API_BASE_URL + URL_WEBSOCKET);
      const stompClient = Stomp.over(socket);
      const prefix = localStorage.getItem(PREFIX);

      stompClient.connect({}, () => {
        stompClient.subscribe("/topic/noti-" + prefix, (message) => {
          let obj = JSON.parse(message.body);

          if (obj.id === lastNotificationId) {
            return;
          }
          setLastNotificationId(obj.id);
          setTask(obj);

          if (notisound && obj.isSound) {
            let isplay = notisound.cloneNode(true).play();
            if (isplay) {
              isplay
                .then(() => {
                  const title =
                    obj.data.type === "DEPOSIT"
                      ? "รายการฝากเงิน"
                      : "รายการถอนเงิน";
                  toast({
                    title: "รายการฝากเงิน",
                    position: "top-right",
                    render: () => (
                      <NotificationToast
                        title={title}
                        username={obj.data.username}
                        amount={obj.data.amount}
                        date={getCurrentFormattedTime()}
                      />
                    ),
                    status: "success",
                    duration: 10000,
                    isClosable: true,
                  });
                })
                .catch((error) => {
                  handleError(ERROR_MESSAGE, error);
                  // todo toast
                });
            }
          }
        });
      });

      socket.onclose = () => {
        reconnect();
      };

      setSock(socket);
    };

    const reconnect = () => {
      setTimeout(connect, getRandomMilliseconds());
    };

    connect();

    return () => {
      if (sock) {
        sock.close();
      }
    };
  }, []);

  return (
    <div>
      <audio id="soundnoti" ref={audioRef} preload="auto">
        <source src={soundFile} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
      {/* <button onClick={() => { audioRef.current.cloneNode(true).play() }}>Test</button> */}
    </div>
  );
};

export default NotificationWebsocket;
