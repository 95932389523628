import {
  Badge,
  Flex,
  IconButton,
  Image,
  Text,
  useClipboard,
} from "@chakra-ui/react";
import { banks } from "../../bank";
import { formatDate } from "../../../utils/DateformatUtils";
import { useHistory } from "react-router-dom";
import { CopyIcon } from "../../../components/Icons/Icons";

export const newMemberTodayColumnsData = () => {
  return [
    {
      Header: "ยูสเซอร์",
      accessor: "username",
      textAlign: "left",
      justifyContent: "flex-start",
      Cell: ({ row }) => {
        const history = useHistory();
        const { onCopy } = useClipboard(row.original.username);
        return (
          <Flex align="center">
            <IconButton
              icon={<CopyIcon color="green.500" />}
              _hover={{ boxShadow: "none", bg: "none" }}
              // _selected={{ boxShadow: "none", bg: "none" }}
              onClick={onCopy}
              aria-label="Copy link"
              variant="outline"
              size="sm"
              border="none"
              mr={2}
            />
            <Text
              cursor="pointer"
              _hover={{ textDecoration: "underline" }}
              onClick={() =>
                history.push(`/admin/member/${row.original.username}`)
              }
            >
              {row.original.username}
            </Text>
          </Flex>
        );
      },
    },
    {
      Header: "เบอร์โทร",
      accessor: "tel",
      textAlign: "left",
      justifyContent: "flex-start",
    },
    {
      Header: "ชื่อ-นามสกุล",
      accessor: "fullName",
      textAlign: "left",
      justifyContent: "flex-start",
    },
    {
      Header: "ธนาคาร",
      accessor: "ธนาคาร",
      Cell: ({ cell, row }) => (
        <Flex align="center" justify="center" w="100%">
          <Image
            src={`${banks[row.original.bankName]?.logo}`}
            alt={cell.value}
            backgroundColor={banks[row.original.bankName]?.color}
            boxSize="30px"
            borderRadius="50%"
            boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
            padding="5px"
          />
        </Flex>
      ),
      disableSortBy: true,
    },
    {
      Header: "เลขบัญชี",
      accessor: "accountNumber",
      textAlign: "left",
      justifyContent: "flex-start",
    },
    {
      Header: "สถานะโปรโมชั่น",
      accessor: "isBonus",
      Cell: ({ cell }) => (
        <Flex align="center" justify="center">
          <Badge
            bg={cell.value === true ? "#31AA93" : "#373F48"}
            color="#fff"
            w="90px"
            h="26px"
            borderRadius="15px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            fontWeight="400"
          >
            {cell.value === true ? "รับโปรโมชั่น" : "ไม่รับ"}
          </Badge>
        </Flex>
      ),
    },
    {
      Header: "วันลงทะเบียน",
      accessor: "createdDate",
      Cell: ({ value }) => formatDate(value),
      textAlign: "left",
      justifyContent: "flex-start",
    },
    {
      Header: "IP",
      accessor: "ip",
      disableSortBy: true,
      textAlign: "left",
      justifyContent: "flex-start",
    },
  ];
};
