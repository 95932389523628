import {
  buildBasePayload,
  buildFetchMemberPayload,
  buildMemberFetchMemberDepositPayload,
  buildMemberFirstDepositPayload,
  buildMemberNotDepositPayload,
} from "../../utils/PayloadUtils";
import { callListApi } from "../../utils/APIUtils";
import { MembersSearchTypes } from "../../variables/system";

const getMemberPayloadBuilder = (searchType) => {
  switch (searchType) {
    case MembersSearchTypes.ALL:
      return buildFetchMemberPayload;
    case MembersSearchTypes.FIRST_DEPOSIT:
      return buildMemberFirstDepositPayload;
    case MembersSearchTypes.DEPOSIT:
      return buildMemberFetchMemberDepositPayload;
    case MembersSearchTypes.NOT_DEPOSIT:
      return buildMemberNotDepositPayload;
  }
};

export const fetchMemberMemberReportByType = async (
  page,
  pageSize,
  search,
  searchType,
  sort = "A",
  sortField = "id",
) => {
  const req = buildBasePayload(
    page,
    pageSize,
    search,
    sort,
    sortField,
    getMemberPayloadBuilder(searchType),
  );

  const response = await callListApi("/api/admin/webuser/search", "POST", req)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw new Error(`API error:${e?.message}`);
    });

  const data2 = await response;

  const data = {
    count: data2.page.totalElements,
    results: data2.datas,
  };

  return data;
};

export const fetchMarketingMemberReportByType = async (
  page,
  pageSize,
  search,
  searchType,
  sort = "D",
  sortField = "id",
) => {
  const req = buildBasePayload(
    page,
    pageSize,
    search,
    sort,
    sortField,
    getMemberPayloadBuilder(searchType),
  );

  const response = await callListApi(
    "/api/admin/marketing/webuser/search",
    "POST",
    req,
  )
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw new Error(`API error:${e?.message}`);
    });

  const data2 = await response;

  const data = {
    count: data2.page.totalElements,
    results: data2.datas,
  };

  return data;
};
