import { useMutation, useQuery, useQueryClient } from "react-query";
import { QueryKeys } from "../../../variables/queryKeys";
import { ERROR_MESSAGE } from "../../../variables/system";
import { useErrorHandler } from "../../common/useErrorHandler";
import {
  fetchCanonical,
  updateCanonical,
} from "../../../services/website-setting/canonicalService";
import { useSuccessHandler } from "../../common/useSuccessHandler";

export const useCanonicalSetting = () => {
  const queryClient = useQueryClient();
  const handleError = useErrorHandler();
  const handleSuccess = useSuccessHandler();

  const { data: canonicalData, isLoading } = useQuery(
    [QueryKeys.CANONICAL_SETTING],
    async () => {
      const response = await fetchCanonical();
      return response.data.data;
    },
    {
      onError: (error) => handleError(error, ERROR_MESSAGE),
    },
  );

  const mutation = useMutation((payload) => updateCanonical(payload), {
    onSuccess: (response) => {
      handleSuccess(response.data.message);
      queryClient.invalidateQueries(QueryKeys.CANONICAL_SETTING);
    },
    onError: (error) => {
      handleError(error, ERROR_MESSAGE);
    },
  });

  const handleSave = (payload) => {
    mutation.mutate(payload);
  };

  return {
    canonicalData,
    isLoading,
    handleSave,
    isSaving: mutation.isLoading,
  };
};
