import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSuccessHandler } from "../../common/useSuccessHandler";
import { useErrorHandler } from "../../common/useErrorHandler";
import { useState } from "react";
import {
  fetchWebSetting,
  updateWebSetting,
} from "../../../services/website-setting/webSettingService";
import { ERROR_MESSAGE } from "../../../variables/system";
import { QueryKeys } from "../../../variables/queryKeys";

export const useConditionSetting = () => {
  const queryClient = useQueryClient();
  const handleSuccess = useSuccessHandler();
  const handleError = useErrorHandler();

  const [showInputField2, setShowInputField2] = useState(false);
  const [showColumn2Inputs, setShowColumn2Inputs] = useState(false);
  const [showColumn3Input, setShowColumn3Input] = useState(false);

  const { data: conditionData, isLoading } = useQuery(
    QueryKeys.CONDITION_SETTING,
    async () => {
      const response = await fetchWebSetting();
      return response.data.data;
    },
    {
      onSuccess: (data) => {
        setShowInputField2(data.config?.approveWithdrawAuto || false);
        setShowColumn2Inputs(data.config?.winLoseRefund || false);
        setShowColumn3Input(data.config?.limitWithDraw || false);
      },
      onError: (error) => handleError(error, ERROR_MESSAGE),
    },
  );

  const mutation = useMutation(updateWebSetting, {
    onSuccess: (response) => {
      handleSuccess(response.data.message);
      queryClient.invalidateQueries(QueryKeys.CONDITION_SETTING);
    },
    onError: (error) => handleError(error, ERROR_MESSAGE),
  });

  const handleSubmit = (values) => {
    const req = {
      ...conditionData,
      ...values,
    };
    mutation.mutate(req);
  };

  return {
    conditionData,
    isLoading,
    showInputField2,
    setShowInputField2,
    showColumn2Inputs,
    setShowColumn2Inputs,
    showColumn3Input,
    setShowColumn3Input,
    handleSubmit,
    isSaving: mutation.isLoading,
  };
};
