import Card from "components/Card/Card";
import { Box, Flex, Text } from "@chakra-ui/react";

const TopTransactionCard = ({ index, username, number }) => {
  // const color = isDeposit ? "green.500" : "red.500";
  // const prefix = isDeposit ? "+" : "-";
  let badgeColor;
  let backgroundColor;

  if (index === 1) {
    badgeColor = "#B18628";
    backgroundColor = "rgb(55,63,72,1)";
  } else if (index === 2) {
    badgeColor = "#767F89";
    backgroundColor = "rgba(50,58,66,1)";
  } else if (index === 3) {
    badgeColor = "#694B47";
    backgroundColor = "rgba(48,54,62,1)";
  } else if (index === 4) {
    badgeColor = "#262D35";
    backgroundColor = "rgba(42,50,59,1)";
  } else {
    badgeColor = "#262D35";
    backgroundColor = "rgba(39,47,55,1)";
  }

  return (
    <Card padding="15px 15px" bg={backgroundColor}>
      <Flex align="center" justify="space-between">
        <Flex align="center">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            boxSize="30px"
            borderRadius="full"
            bg={badgeColor}
            color="white"
            fontWeight="bold"
            fontSize="sm"
          >
            {index}
          </Box>
          <Text color="#8C9592" fontSize="16px" ml="24px">
            {username}
          </Text>
        </Flex>

        <Box>
          <Text fontWeight="bold" color="#fff" fontSize="16px">
            {number}
          </Text>
        </Box>
      </Flex>
    </Card>
  );
};

export default TopTransactionCard;
