import { Box, Flex, Spacer, Stack, Text } from "@chakra-ui/react";
import React from "react";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { profitLossColumnsData } from "variables/columns/website-report/profitLossColumnsData";
import "assets/css/styles.css";
import AnimatedFlex from "components/AnimatedFlex/MotionFlex";
import SimpleTable from "components/Tables/SimpleTable";
import CustomRangePicker from "components/CustomRangePicker/CustomRangePicker";

import { APP_PROFILE } from "variables/system";

import { useSearch } from "hooks/common/useSearch";
import { useProfitAndLossReport } from "hooks/business/website-report/useProfitAndLossReport";

const ProfitLossReport = ({ pageName }) => {
  const role = JSON.parse(localStorage.getItem(APP_PROFILE))?.role;

  const dateSearchFormat = "yyyy-MM-DD HH:mm:ss";
  const dateFieldsNames = {
    from: "createdDateFrom",
    to: "createdDateTo",
  };

  const { search, selectedDates, handleDateChange } = useSearch(
    dateFieldsNames,
    dateSearchFormat,
  );
  const {
    data: profitLossData,
    isLoading,
    downloadableData,
  } = useProfitAndLossReport(role, search);

  return (
    <Flex direction="column">
      <Stack direction="column" spacing="24px" w="100%">
        <AnimatedFlex justify="space-between" duration={0.4} delay={0.4}>
          <Text
            fontSize="24px"
            fontWeight="bold"
            color="green.500"
            mb="12px"
            mt="12px"
          >
            {pageName}
          </Text>
        </AnimatedFlex>
        <AnimatedFlex duration={0.4} delay={0.6}>
          <Card px="22px" pb="38px">
            <CardHeader pb="12px" pt="12px">
              <Flex direction="column">
                <Text color="white" fontSize="18px" fontWeight="bold" mb="6px">
                  สรุป กำไร-ขาดทุน
                </Text>
              </Flex>
              <Spacer />
              <Box>
                <CustomRangePicker
                  selectedDates={selectedDates}
                  onDateChange={handleDateChange}
                />
              </Box>
            </CardHeader>
            <CardBody>
              <SimpleTable
                tableData={profitLossData}
                columnsData={profitLossColumnsData}
                showExport={true}
                downloadableData={downloadableData}
                filename={pageName}
                isLoading={isLoading}
              />
            </CardBody>
          </Card>
        </AnimatedFlex>
      </Stack>
    </Flex>
  );
};

export default ProfitLossReport;
