import { Box, Flex, Spacer, Stack, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";

import "assets/css/styles.css";
import AnimatedFlex from "components/AnimatedFlex/MotionFlex";
import { QueryClientProvider, useQueryClient } from "react-query";
import CustomRangePicker from "components/CustomRangePicker/CustomRangePicker";
import SearchTableRemote from "components/Tables/SearchTableRemote";

import { depositTransactionReportColumnsData } from "variables/columns/transaction-report/depositTransactionReportColumnsData";
import ApproveBlockAutoTransactionModal from "./components/ApproveBlockAutoTransactionModal";
import ApproveInvalidTransactionModal from "../invalid/components/ApproveInvalidTransactionModal";
import RejectInvalidTransactionModal from "../invalid/components/RejectInvalidTransactionModal";
import RejectBlockAutoTransactionModal from "./components/RejectBlockAutoTransactionModal";
import QrCodeModal from "./components/QrCodeModal";
import { APP_PROFILE, DepositTransactionTypes } from "variables/system";
import { QueryKeys } from "variables/queryKeys";
import { useSearch } from "hooks/common/useSearch";
import { useDepositTransaction } from "hooks/business/transaction-report/useDepositTransaction";

const DepositTransactionReport = ({ pageName }) => {
  const queryClient = useQueryClient();
  const [selectedTransaction, setSelectedTransaction] = useState(null);

  const dateFieldsNames = {
    from: "createdDateFrom",
    to: "createdDateTo",
  };
  const dateSearchFormat = "DD/MM/yyyy HH:mm";

  const role = JSON.parse(localStorage.getItem(APP_PROFILE))?.role;

  const { search, setSearch, selectedDates, handleDateChange } = useSearch(
    dateFieldsNames,
    dateSearchFormat,
  );

  const {
    fetchData,
    downloadableData,
    handleSaveTransaction,
    handleInvalidTransaction,
  } = useDepositTransaction(
    role,
    QueryKeys.DEPOSIT_TRANSACTION_REPORT,
    depositTransactionReportColumnsData,
    DepositTransactionTypes.ALL,
  );

  const [modalState, setModalState] = useState({
    approveBlockAutoTransactionModalOpen: false,
    rejectBlockAutoTransactionModalOpen: false,
    approveInvalidTransactionModalOpen: false,
    rejectInvalidTransactionModalOpen: false,
    qrCodeModalOpen: false,
  });

  const handleModalOpen = (modal, transaction = null) => {
    setSelectedTransaction(transaction);
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: true,
    }));
  };

  const handleModalClose = (modal) => {
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: false,
    }));
    setSelectedTransaction(null);
  };

  const searchOptions = [
    {
      label: "all",
      value: "",
    },
    {
      label: "success",
      value: "SUCCESS",
    },
    {
      label: "inprogress",
      value: "PENDING",
    },
    {
      label: "error",
      value: "ERROR",
    },
    {
      label: "reject",
      value: "REJECT",
    },
    // {
    //   label: "rejectAndRefund",
    //   value: "REJECT_N_REFUND",
    // },
    {
      label: "blockAuto",
      value: "BLOCK_AUTO",
    },
    {
      label: "notSure",
      value: "NOT_SURE",
    },
  ];

  const handleOptionChange = (selectedOption) => {
    setSearch({
      ...search,
      status: selectedOption ? selectedOption.value : null,
    });
  };

  return (
    <Flex direction="column">
      <Stack direction="column" spacing="24px" w="100%">
        <AnimatedFlex justify="space-between" duration={0.4} delay={0.4}>
          <Text
            fontSize="24px"
            fontWeight="bold"
            color="green.500"
            mb="12px"
            mt="12px"
          >
            {pageName}
          </Text>
        </AnimatedFlex>
        <AnimatedFlex duration={0.4} delay={0.6}>
          <Card px="22px" pb="38px">
            <CardHeader pb="12px" pt="12px">
              <Flex direction="column" justifyContent="center">
                <Text fontSize="18px" fontWeight="bold" mb="6px" color="#fff">
                  เช็คข้อมูลฝาก
                </Text>
              </Flex>
              <Spacer />
              <Box>
                <CustomRangePicker
                  selectedDates={selectedDates}
                  onDateChange={handleDateChange}
                />
              </Box>
            </CardHeader>
            <CardBody>
              <QueryClientProvider client={queryClient}>
                <SearchTableRemote
                  query={QueryKeys.DEPOSIT_TRANSACTION_REPORT}
                  columnsData={depositTransactionReportColumnsData(
                    handleModalOpen,
                  )}
                  fetchData={fetchData}
                  search={search}
                  setSearch={setSearch}
                  showExport={true}
                  downloadableData={downloadableData}
                  filename={pageName}
                  showSearchableDropdown={true}
                  onChange={handleOptionChange}
                  searchOptions={searchOptions}
                />
              </QueryClientProvider>
            </CardBody>
          </Card>
        </AnimatedFlex>
        <ApproveBlockAutoTransactionModal
          isOpen={modalState.approveBlockAutoTransactionModalOpen}
          onClose={() => handleModalClose("approveBlockAutoTransactionModal")}
          transaction={selectedTransaction}
          handleSubmit={handleSaveTransaction}
        />
        <RejectBlockAutoTransactionModal
          isOpen={modalState.rejectBlockAutoTransactionModalOpen}
          onClose={() => handleModalClose("rejectBlockAutoTransactionModal")}
          transaction={selectedTransaction}
          handleSubmit={handleSaveTransaction}
        />
        <ApproveInvalidTransactionModal
          isOpen={modalState.approveInvalidTransactionModalOpen}
          onClose={() => handleModalClose("approveInvalidTransactionModal")}
          transaction={selectedTransaction}
          handleSubmit={handleInvalidTransaction}
        />
        <RejectInvalidTransactionModal
          isOpen={modalState.rejectInvalidTransactionModalOpen}
          onClose={() => handleModalClose("rejectInvalidTransactionModal")}
          transaction={selectedTransaction}
          handleSubmit={handleInvalidTransaction}
        />
        <QrCodeModal
          isOpen={modalState.qrCodeModalOpen}
          onClose={() => handleModalClose("qrCodeModal")}
          transaction={selectedTransaction}
        />
      </Stack>
    </Flex>
  );
};

export default DepositTransactionReport;
