import {
  Button,
  Flex,
  FormControl,
  Grid,
  GridItem,
  Input,
  Stack,
  Text,
  Checkbox,
  SimpleGrid,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useErrorHandler } from "hooks/common/useErrorHandler";
import { useSuccessHandler } from "hooks/common/useSuccessHandler";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import AnimatedFlex from "components/AnimatedFlex/MotionFlex";
import { ERROR_MESSAGE } from "variables/system";
import {
  saveRole,
  fetchRoleById,
} from "services/system-setting/roleSettingService";
import { groupPermissionsByMenuGroup } from "../../../../utils/RoleUtils";
import CardHeader from "../../../../components/Card/CardHeader";
import {
  DashboardIcon,
  FinanceSettingIcon,
  MemberManagementIcon,
  MemberReportIcon,
  SystemSettingIcon,
  ThiefIcon,
  TransactionReportIcon,
  WebsiteReportIcon,
  WebsiteSettingIcon,
} from "../../../../components/Icons/Icons";

const ManageRoleView = ({ pageName }) => {
  const { roleId } = useParams();
  const history = useHistory();
  const [roleData, setRoleData] = useState({ roleName: "", permissions: {} });
  const handleError = useErrorHandler();
  const handleSuccess = useSuccessHandler();
  const [permissionsData, setPermissionsData] = useState([]);
  const [updateArray, setUpdateArray] = useState([]);

  const getMenuGroupIcon = (menuGroup) => {
    switch (menuGroup) {
      case "ภาพรวม":
        return <DashboardIcon w="24px" h="24px" color="green.500" />;
      case "รายชื่อมิจฉาชีพ":
        return <ThiefIcon w="24px" h="24px" color="green.500" />;
      case "จัดการสมาชิค":
        return <MemberManagementIcon w="24px" h="24px" color="green.500" />;
      case "รายงานสมาชิค":
        return <MemberReportIcon w="24px" h="24px" color="green.500" />;
      case "รายงานการเงิน":
        return <TransactionReportIcon w="24px" h="24px" color="green.500" />;
      case "รายงานเว็บไซต์":
        return <WebsiteReportIcon w="24px" h="24px" color="green.500" />;
      case "ตั้งค่าเว็บไซต์":
        return <WebsiteSettingIcon w="24px" h="24px" color="green.500" />;
      case "ตั้งค่าระบบ":
        return <SystemSettingIcon w="24px" h="24px" color="green.500" />;
      case "ตั้งค่าการเงิน":
        return <FinanceSettingIcon w="24px" h="24px" color="green.500" />;
    }
  };

  const fetchPermissionData = () => {
    fetchRoleById(1)
      .then((response) => {
        let masterPermissions = response.data.data.menuList;

        if (roleId) {
          fetchRoleById(roleId)
            .then((roleResponse) => {
              let selectedRolePermissions = roleResponse.data.data.menuList;

              if (roleResponse.data.data.roleName === "MARKETING") {
                masterPermissions = masterPermissions.filter((permission) => {
                  const allowedMenuIds = [1, 6, 7, 8, 9, 11, 14, 15, 16, 3, 17];
                  return allowedMenuIds.includes(permission.menuId);
                });
              }

              const groupedMasterPermissions =
                groupPermissionsByMenuGroup(masterPermissions);
              setPermissionsData(groupedMasterPermissions);

              const groupedRolePermissions = groupPermissionsByMenuGroup(
                selectedRolePermissions,
              );
              setRoleData({
                ...roleResponse.data.data,
                permissions: groupedRolePermissions,
              });

              setUpdateArray(selectedRolePermissions);
            })
            .catch((error) => {
              handleError(error, ERROR_MESSAGE);
            });
        } else {
          const groupedMasterPermissions =
            groupPermissionsByMenuGroup(masterPermissions);
          setPermissionsData(groupedMasterPermissions);
        }
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  };

  useEffect(() => {
    fetchPermissionData();
  }, [roleId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRoleData({ ...roleData, [name]: value });
  };

  const handlePermissionChange = (menuGroup, menu) => {
    setRoleData((prevData) => {
      const updatedPermissions = { ...prevData.permissions };

      if (!updatedPermissions[menuGroup]) {
        updatedPermissions[menuGroup] = [];
      }

      if (
        updatedPermissions[menuGroup].some(
          (perm) => perm.menuId === menu.menuId,
        )
      ) {
        updatedPermissions[menuGroup] = updatedPermissions[menuGroup].filter(
          (perm) => perm.menuId !== menu.menuId,
        );
        setUpdateArray((prevArray) =>
          prevArray.filter((item) => item.menuId !== menu.menuId),
        );
      } else {
        updatedPermissions[menuGroup].push(menu);
        setUpdateArray((prevArray) => [...prevArray, menu]);
      }

      return { ...prevData, permissions: updatedPermissions };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = {
      roleId: roleId ? parseInt(roleId) : null,
      roleName: roleData.roleName,
      menuList: updateArray,
    };

    saveRole(payload)
      .then((response) => {
        handleSuccess(response.data.message);
        history.push("/admin/system-setting/roles");
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  };

  return (
    <Flex direction="column" overflow="hidden">
      <Stack direction="column" spacing="24px" w="100%">
        <Text
          fontSize="24px"
          fontWeight="bold"
          color="green.500"
          mb="12px"
          mt="12px"
        >
          {pageName}
        </Text>
        <AnimatedFlex duration={0.4} delay={0.4}>
          <Card w="100%">
            <CardHeader pb="12px" pt="12px">
              <Flex direction="column">
                <Text fontSize="18px" fontWeight="bold" mb="6px" color="#fff">
                  ตั้งค่าตำแหน่งและสิทธิ์
                </Text>
              </Flex>
            </CardHeader>
            <CardBody>
              <Grid
                templateColumns={{
                  base: "1fr",
                  sm: "repeat(2, 1fr)",
                  md: "repeat(2, 1fr)",
                }}
                gap="24px"
                w="100%"
              >
                <GridItem>
                  <FormControl>
                    <Text fontWeight="normal">ชื่อตำแหน่ง</Text>
                    <Input
                      name="roleName"
                      fontSize="sm"
                      value={roleData.roleName}
                      onChange={handleChange}
                      isReadOnly={!!roleId}
                      backgroundColor={roleId ? "#373F48" : "#1E252C"}
                      placeholder="ชื่อตำแหน่ง"
                    />
                  </FormControl>
                </GridItem>
                <GridItem />
                {Object.keys(permissionsData).map((menuGroup, index) => (
                  <GridItem key={index} colSpan={2}>
                    <Card boxShadow="none" border="1px solid #373F48" p={4}>
                      <Flex justifyContent="space-between">
                        <Flex flex="1" alignItems="center">
                          {getMenuGroupIcon(menuGroup)}
                          <Text
                            fontWeight="bold"
                            fontSize="16px"
                            color="#fff"
                            ml={8}
                          >
                            {menuGroup}
                          </Text>
                        </Flex>
                        <SimpleGrid
                          columns={4}
                          spacing={3}
                          flex="3"
                          width="100%"
                          align="start"
                          mt={2}
                        >
                          {permissionsData[menuGroup].map((menu) => (
                            <Checkbox
                              colorScheme="green"
                              key={menu.menuId}
                              isChecked={
                                roleData.permissions[menuGroup]?.some(
                                  (perm) => perm.menuId === menu.menuId,
                                ) || false
                              }
                              onChange={() =>
                                handlePermissionChange(menuGroup, menu)
                              }
                              sx={{
                                "& .chakra-checkbox__control": {
                                  _checked: {
                                    bg: "green.500",
                                    borderColor: "green.500",
                                    color: "black",
                                  },
                                },
                              }}
                            >
                              <Text fontSize="sm">{menu.menuCode}</Text>
                            </Checkbox>
                          ))}
                        </SimpleGrid>
                      </Flex>
                    </Card>
                  </GridItem>
                ))}
              </Grid>
            </CardBody>
            <AnimatedFlex
              justify="flex-start"
              duration={0.4}
              delay={0.6}
              mt={6}
              mb={6}
            >
              <Button
                fontSize="14px"
                fontWeight="normal"
                bg="#373F48"
                color="#8C9592"
                width={{ base: "100%", md: "auto" }}
                minW={{ base: "none", md: "175px" }}
                borderRadius="33px"
                mr={2}
                onClick={() => history.push("/admin/system-setting/roles")}
              >
                ยกเลิก
              </Button>
              <Button
                background="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
                width={{ base: "100%", md: "auto" }}
                minW={{ base: "none", md: "175px" }}
                borderRadius="33px"
                fontSize="14px"
                fontWeight="normal"
                onClick={handleSubmit}
              >
                บันทึกข้อมูล
              </Button>
            </AnimatedFlex>
          </Card>
        </AnimatedFlex>
      </Stack>
    </Flex>
  );
};

export default ManageRoleView;
