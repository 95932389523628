import { useQuery, useMutation, useQueryClient } from "react-query";
import { useSuccessHandler } from "../../common/useSuccessHandler";
import { useErrorHandler } from "../../common/useErrorHandler";
import { ERROR_MESSAGE } from "../../../variables/system";
import { QueryKeys } from "../../../variables/queryKeys";
import {
  fetchAffiliateSetting,
  updateAffiliateSetting,
} from "../../../services/system-setting/affiliateSettingService";

export const useAffiliateSetting = () => {
  const queryClient = useQueryClient();
  const handleSuccess = useSuccessHandler();
  const handleError = useErrorHandler();

  const {
    data: affiliateData,
    isLoading,
    error,
  } = useQuery(
    QueryKeys.AFFILIATE_SETTING,
    async () => {
      const response = await fetchAffiliateSetting();
      return response.data.data;
    },
    {
      onError: (error) => handleError(error, ERROR_MESSAGE),
    },
  );

  const { mutate: saveAffiliateSetting, isLoading: isSaving } = useMutation(
    updateAffiliateSetting,
    {
      onSuccess: (response) => {
        handleSuccess(response.data.message);
        queryClient.invalidateQueries(QueryKeys.AFFILIATE_SETTING);
      },
      onError: (error) => handleError(error, ERROR_MESSAGE),
    },
  );

  const handleSubmit = (values) => {
    saveAffiliateSetting(values);
  };

  return {
    affiliateData,
    isLoading,
    error,
    handleSubmit,
    isSaving,
  };
};
