import { useState } from "react";
import { ERROR_MESSAGE, MARKETING_ROLE } from "variables/system";
import {
  createBlockAutoWithdrawTransaction,
  fetchWithdrawHistories,
  refreshErrorWithdrawTransaction,
  updateWithdrawTransactionStatus,
} from "services/transaction-report/withdrawTransactionReportService";
import { fetchMarketingDepositHistories } from "services/transaction-report/depositTransactionReportService";
import { mapJsonToTableData } from "utils/CommonUtils";
import { withdrawTransactionColumnsData } from "variables/columns/transaction-report/withdrawTransactionReportColumnsData";
import { useMutation, useQueryClient } from "react-query";
import { QueryKeys } from "variables/queryKeys";
import { useSuccessHandler } from "../../common/useSuccessHandler";
import { useErrorHandler } from "../../common/useErrorHandler";

export const useWithdrawTransaction = (role) => {
  const queryClient = useQueryClient();
  const handleSuccess = useSuccessHandler();
  const handleError = useErrorHandler();

  const [downloadableData, setDownloadableData] = useState([]);

  const fetchData = async (pageIndex, pageSize, search) => {
    const response =
      role !== MARKETING_ROLE
        ? await fetchWithdrawHistories(pageIndex, pageSize, search)
        : await fetchMarketingDepositHistories(pageIndex, pageSize, search);

    const formattedRecords = mapJsonToTableData(
      response.results,
      withdrawTransactionColumnsData(),
    );

    setDownloadableData(formattedRecords);
    return response;
  };

  const updateWithdrawTransactionMutation = useMutation(
    updateWithdrawTransactionStatus,
    {
      onSuccess: (response) => {
        handleSuccess(response.data.message);
        queryClient.invalidateQueries(QueryKeys.WITHDRAW_TRANSACTION_REPORT);
      },
      onError: (error) => {
        handleError(error, ERROR_MESSAGE);
      },
    },
  );

  const updateBlockAutoWithdrawTransactionMutation = useMutation(
    createBlockAutoWithdrawTransaction,
    {
      onSuccess: (response) => {
        handleSuccess(response.data.message);
        queryClient.invalidateQueries(QueryKeys.WITHDRAW_TRANSACTION_REPORT);
      },
      onError: (error) => {
        handleError(error, ERROR_MESSAGE);
      },
    },
  );

  const refreshErrorWithdrawTransactionMutation = useMutation(
    refreshErrorWithdrawTransaction,
    {
      onSuccess: (response) => {
        handleSuccess(response.data.message);
        queryClient.invalidateQueries(QueryKeys.WITHDRAW_TRANSACTION_REPORT);
      },
      onError: (error) => {
        handleError(error, ERROR_MESSAGE);
      },
    },
  );

  const handleSaveTransaction = (values, actions, onClose) => {
    updateWithdrawTransactionMutation.mutate(values, {
      onSettled: () => {
        actions.setSubmitting(false);
        onClose();
      },
    });
  };

  const handleBlockAutoTransaction = (values, actions, onClose) => {
    updateBlockAutoWithdrawTransactionMutation.mutate(values, {
      onSettled: () => {
        actions.setSubmitting(false);
        onClose();
      },
    });
  };

  const handleRefreshErrorTransaction = (values, actions, onClose) => {
    refreshErrorWithdrawTransactionMutation.mutate(values, {
      onSettled: () => {
        actions.setSubmitting(false);
        onClose();
      },
    });
  };

  return {
    fetchData,
    downloadableData,
    handleSaveTransaction,
    handleBlockAutoTransaction,
    handleRefreshErrorTransaction,
  };
};
