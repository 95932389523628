import React from "react";
import {
  Box,
  Button,
  FormControl,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Grid,
  GridItem,
  Text,
  useBreakpointValue,
  Select,
  Flex,
  FormLabel,
} from "@chakra-ui/react";
import { Formik, Form } from "formik";
import { DEVICE_DROPDOWN } from "variables/system";
import ImageUploader from "components/ImageUploader/ImageUploader";
import moment from "moment/moment";

const ManagePopupModal = ({ isOpen, onClose, popup = null, handleSubmit }) => {
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const modalSize = useBreakpointValue({ base: "full", md: "xl" });

  const handlePopupSubmit = (values, actions) => {
    const formattedValues = {
      ...values,
      startDate: moment(values.startDate).format("YYYY/MM/DD"),
      endDate: moment(values.endDate).format("YYYY/MM/DD"),
    };

    handleSubmit(formattedValues, popup, actions);
  };

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size={modalSize}
    >
      <ModalOverlay />
      <ModalContent maxH="100vh">
        <ModalHeader
          backgroundColor="gray.800"
          borderTopRadius="12px"
          borderBottom="1px solid rgba(55, 63, 72, 1)"
          mb={4}
        >
          <Box textAlign="left">
            <Text fontSize="20px" color="#fff">
              เพิ่ม / แก้ไข ป๊อปอัพ
            </Text>
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody overflowY="auto">
          <Formik
            initialValues={{
              name: popup?.name || "",
              content: popup?.content || "",
              actionLink: popup?.actionLink || "",
              image: popup?.image || "",
              device: popup?.device || "",
              language: popup?.language || "TH",
              sort: popup?.sort || 1,
              status: popup?.status || true,
              startDate: moment(popup?.startDate).format("yyyy-MM-DD") || "",
              endDate: moment(popup?.endDate).format("yyyy-MM-DD") || "",
            }}
            onSubmit={(values, actions) => {
              handlePopupSubmit(values, actions);
              onClose();
            }}
            enableReinitialize
          >
            {({ values, handleChange, setFieldValue, isSubmitting }) => (
              <Form>
                <Grid
                  templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }}
                  gap={4}
                >
                  <GridItem colSpan={2}>
                    <Box>
                      <FormLabel>
                        รูปป๊อปอัพ{" "}
                        <Box as="span" color="green.500" fontWeight="normal">
                          (ขนาด 1040 x 1040 px)
                        </Box>
                      </FormLabel>
                      <ImageUploader
                        initialImage={values.image}
                        onUpload={(uri) => setFieldValue("image", uri)}
                      />
                    </Box>
                  </GridItem>
                  <GridItem colSpan={2}>
                    <FormControl>
                      <FormLabel>ชื่อโปรโมชั่น</FormLabel>
                      <Input
                        name="name"
                        onChange={handleChange}
                        value={values.name}
                        fontSize="sm"
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={2}>
                    <FormControl>
                      <FormLabel>รายละเอียด</FormLabel>
                      <Input
                        name="content"
                        onChange={handleChange}
                        value={values.content}
                        fontSize="sm"
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={2}>
                    <FormControl>
                      <FormLabel>ลิ้งค์</FormLabel>
                      <Input
                        name="actionLink"
                        onChange={handleChange}
                        value={values.actionLink}
                        fontSize="sm"
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={{ base: 1, md: 1 }}>
                    <FormControl>
                      <FormLabel>ลำดับ</FormLabel>
                      <Input
                        name="sort"
                        onChange={handleChange}
                        value={values.sort}
                        fontSize="sm"
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={{ base: 1, md: 1 }}>
                    <FormControl>
                      <FormLabel>อุปกรณ์</FormLabel>
                      <Select
                        name="device"
                        onChange={handleChange}
                        value={values.device}
                        placeholder="เลือกอุปกรณ์"
                        fontSize="sm"
                        borderRadius="33px"
                      >
                        {DEVICE_DROPDOWN.map((type, id) => (
                          <option key={id} value={type.val}>
                            {type.text}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={{ base: 1, md: 1 }}>
                    <FormControl>
                      <FormLabel>จากวันที่</FormLabel>
                      <Input
                        name="startDate"
                        onChange={(e) =>
                          setFieldValue("startDate", e.target.value)
                        }
                        value={values.startDate}
                        fontSize="sm"
                        type="date"
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem colSpan={{ base: 1, md: 1 }}>
                    <FormControl>
                      <FormLabel>ถึงวันที่</FormLabel>
                      <Input
                        name="endDate"
                        onChange={(e) =>
                          setFieldValue("endDate", e.target.value)
                        }
                        value={values.endDate}
                        fontSize="sm"
                        type="date"
                      />
                    </FormControl>
                  </GridItem>
                  <GridItem>
                    <Flex>
                      <Switch
                        isChecked={values.status === "true"}
                        onChange={(e) =>
                          setFieldValue("status", e.target.checked.toString())
                        }
                      />
                      <Text mb={0} pl={4}>
                        สถานะเปิดใช้งาน
                      </Text>
                    </Flex>
                  </GridItem>
                </Grid>
                <ModalFooter pl={0} pr={0} mb={6}>
                  <Button
                    background="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
                    w="100%"
                    type="submit"
                    fontWeight="normal"
                    isLoading={isSubmitting}
                    fontSize="14px"
                  >
                    ทำรายการ
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ManagePopupModal;
