import { useMutation, useQueryClient } from "react-query";
import { createMistakeCredit } from "services/member-management/memberService";
import { QueryKeys } from "variables/queryKeys";
import { ERROR_MESSAGE } from "variables/system";
import { useErrorHandler } from "../../common/useErrorHandler";
import { useSuccessHandler } from "../../common/useSuccessHandler";

export const useCredit = () => {
  const queryClient = useQueryClient();
  const handleError = useErrorHandler();
  const handleSuccess = useSuccessHandler();

  const mutation = useMutation((values) => createMistakeCredit(values), {
    onSuccess: (response) => {
      handleSuccess(response.data.message);
      queryClient.invalidateQueries(QueryKeys.MEMBERS);
    },
    onError: (error) => handleError(error, ERROR_MESSAGE),
  });

  const handleCredit = (values, actions, onClose) => {
    mutation.mutate(values, {
      onSettled: () => {
        actions.setSubmitting(false);
        onClose();
      },
    });
  };

  return {
    handleCredit,
  };
};
