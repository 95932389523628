import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";

const DeleteBankModal = ({
  isOpen,
  onClose,
  obj,
  handleSubmit,
  isSubmitting,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Box textAlign="center">ยืนยันทำรายการ</Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>คุณแน่ใจจะลบธนาคาร ?</ModalBody>
        <ModalFooter>
          <Button
            backgroundColor="green.500"
            color={"#000000"}
            onClick={() => {
              handleSubmit(obj.id);
              onClose();
            }}
            w="100%"
            isLoading={isSubmitting}
          >
            ทำรายการ
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteBankModal;
