import { useQuery } from "react-query";
import { ERROR_MESSAGE, MARKETING_ROLE } from "../../../variables/system";
import {
  fetchLast20MarketingWithdrawHistory,
  fetchLast20WithdrawHistory,
} from "../../../services/dashboard/dashboardService";
import { useErrorHandler } from "../../common/useErrorHandler";
import { QueryKeys } from "../../../variables/queryKeys";

export const useLast20Withdraw = (role, mid = null) => {
  const handleError = useErrorHandler();

  const fetchLastWithdraw = async () => {
    const response =
      role !== MARKETING_ROLE
        ? await fetchLast20WithdrawHistory()
        : await fetchLast20MarketingWithdrawHistory({ mid: mid });
    return response.data.data;
  };

  const { data: lastWithdraw, isLoading: isLastWithdrawLoading } = useQuery(
    QueryKeys.LAST_20_WITHDRAW,
    fetchLastWithdraw,
    {
      onError: (error) => handleError(error, ERROR_MESSAGE),
    },
  );

  return { lastWithdraw, isLastWithdrawLoading };
};
