import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
} from "@chakra-ui/react";
import React from "react";
import { Field, Form, Formik } from "formik";

const UpdatePaymentGatewaySettingModal = ({
  isOpen,
  onClose,
  paymentGateway,
  handleSubmit,
}) => {
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const initialConfig = paymentGateway ? JSON.parse(paymentGateway.config) : {};

  const initialValues = {
    ...initialConfig,
    active: paymentGateway ? paymentGateway.active : false,
  };

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>ตั้งค่า Payment Gateway</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Formik
            initialValues={initialValues}
            onSubmit={(values, actions) => {
              handleSubmit(values, paymentGateway, actions, onClose);
            }}
          >
            {(props) => (
              <Form>
                {Object.keys(initialValues)
                  .filter((key) => key !== "active")
                  .map((key, index) => (
                    <Field name={key} key={key}>
                      {({ field }) => (
                        <FormControl mt={4}>
                          <FormLabel>{key.replace(/_/g, " ")}</FormLabel>
                          <Input
                            {...field}
                            placeholder={key}
                            isReadOnly={index === 0}
                            backgroundColor={
                              index === 0 ? "#373F48" : "gray.800"
                            }
                            color={"white"}
                          />
                        </FormControl>
                      )}
                    </Field>
                  ))}
                <Field name="active">
                  {({ field }) => (
                    <FormControl display="flex" alignItems="center" mt={4}>
                      <Switch {...field} isChecked={field.value} />
                      <FormLabel ml={4}>สถานะเปิดใช้งาน</FormLabel>
                    </FormControl>
                  )}
                </Field>
                <ModalFooter pl={0} pr={0} mt={4}>
                  <Button
                    background="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
                    fontWeight="normal"
                    fontSize="14px"
                    w="100%"
                    type="submit"
                    isLoading={props.isSubmitting}
                    disabled={props.isSubmitting}
                  >
                    ยืนยัน
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default UpdatePaymentGatewaySettingModal;
