import { HamburgerIcon } from "@chakra-ui/icons";
import { Flex, Icon, Image, useColorModeValue } from "@chakra-ui/react";
import { SidebarContext } from "contexts/SidebarContext";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { CgMenuRight } from "react-icons/cg";
import LOGO from "assets/img/logo.png";

export default function AdminNavbar(props) {
  const [scrolled, setScrolled] = useState(false);
  const { sidebarWidth, setSidebarWidth, toggleSidebar, setToggleSidebar } =
    useContext(SidebarContext);
  // const {
  //   variant,
  //   children,
  //   fixed,
  //   secondary,
  //   brandText,
  //   onOpen,
  //   ...rest
  // } = props;

  // Here are all the props that may change depending on navbar's type or state.(secondary, variant, scrolled)
  let mainText = useColorModeValue("gray.700", "gray.200");
  // let secondaryText = useColorModeValue("gray.400", "gray.200");
  let navbarPosition = "absolute";
  let navbarShadow = "none";
  let navbarBg = "transparent";
  let navbarBorder = "transparent";
  let secondaryMargin = "0px";
  let paddingS = "15px";
  let paddingX = "15px";
  if (props.fixed === true)
    if (scrolled === true) {
      navbarPosition = "fixed";
      navbarShadow = useColorModeValue(
        "0px 7px 23px rgba(0, 0, 0, 0.05)",
        "none",
      );
      navbarBg = useColorModeValue(
        "linear-gradient(112.83deg, rgba(255, 255, 255, 0.82) 0%, rgba(255, 255, 255, 0.8) 110.84%)",
        "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)",
      );
      navbarBorder = useColorModeValue("#FFFFFF", "rgba(255, 255, 255, 0.31)");
    }
  if (props.secondary) {
    navbarPosition = "absolute";
    mainText = "white";
    // secondaryText = "white";
    secondaryMargin = "22px";
    paddingS = "40px";
    paddingX = "30px";
  }
  const changeNavbar = () => {
    if (window.scrollY > 1) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  window.addEventListener("scroll", changeNavbar);
  return (
    <Flex
      position={navbarPosition}
      boxShadow={navbarShadow}
      bg={navbarBg}
      borderColor={navbarBorder}
      borderWidth="1.5px"
      borderStyle="solid"
      transitionDelay="0s, 0s, 0s, 0s"
      transitionDuration="0.25s, 0.25s, 0.25s, 0s"
      transitionProperty="box-shadow, background-color, filter, border"
      transitionTimingFunction="linear, linear, linear, linear"
      alignItems="center"
      borderRadius="0px"
      minH="65px"
      lineHeight="25.6px"
      mx="auto"
      mt={secondaryMargin}
      pb="20px"
      px={{
        sm: paddingX,
        md: "0px",
      }}
      ps={{
        sm: paddingS,
        md: "0px",
      }}
      ml="20px"
      // ml={{ sm: "none", xl: "20px" }}
      pt="20px"
      w={sidebarWidth}
      justifyContent="space-between"
    >
      <Flex alignItems="center" justifyContent="space-between" w="100%">
        <Image src={LOGO} alt="Logo" w="125px" h="32px" />
        {toggleSidebar ? (
          <Icon
            as={CgMenuRight}
            h="20px"
            ms="20px"
            cursor="pointer"
            display="none"
            onClick={() => {
              setSidebarWidth(sidebarWidth === 280 ? 80 : 280);
              setToggleSidebar(!toggleSidebar);
            }}
          />
        ) : (
          <HamburgerIcon
            h="20px"
            color={props.secondary ? "white" : mainText}
            cursor="pointer"
            display="none"
            onClick={() => {
              setSidebarWidth(sidebarWidth === 280 ? 80 : 280);
              setToggleSidebar(!toggleSidebar);
            }}
          />
        )}
      </Flex>
    </Flex>
  );
}

AdminNavbar.propTypes = {
  brandText: PropTypes.string,
  variant: PropTypes.string,
  secondary: PropTypes.bool,
  fixed: PropTypes.bool,
  onOpen: PropTypes.func,
};
