import { Box, Flex, Spacer, Stack, Text } from "@chakra-ui/react";
import React from "react";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import "assets/css/styles.css";
import AnimatedFlex from "components/AnimatedFlex/MotionFlex";
import SimpleTable from "components/Tables/SimpleTable";
import CustomRangePicker from "components/CustomRangePicker/CustomRangePicker";

import { marketingReportColumnsData } from "variables/columns/website-report/marketingReportColumnsData";

import { useSearch } from "hooks/common/useSearch";
import { useMarketingReport } from "hooks/business/website-report/useMarketingReport";

const MarketingReport = ({ pageName }) => {
  const dateSearchFormat = "yyyy-MM-DD HH:mm:ss";
  const dateFieldsNames = {
    from: "createdDateFrom",
    to: "createdDateTo",
  };

  const { search, selectedDates, handleDateChange } = useSearch(
    dateFieldsNames,
    dateSearchFormat,
  );

  const {
    data: marketingReportData,
    isLoading,
    downloadableData,
  } = useMarketingReport(search);

  return (
    <Flex direction="column">
      <Stack direction="column" spacing="24px" w="100%">
        <AnimatedFlex
          justify="space-between"
          align="center"
          duration={0.4}
          delay={0.4}
        >
          <Text
            fontSize="24px"
            fontWeight="bold"
            color="green.500"
            mb="12px"
            mt="12px"
          >
            {pageName}
          </Text>
        </AnimatedFlex>
        <AnimatedFlex duration={0.4} delay={0.6}>
          <Card px="22px" pb="38px">
            <CardHeader pb="12px" pt="12px">
              <Flex direction="column">
                <Text color="white" fontSize="18px" fontWeight="bold" mb="6px">
                  สรุป กำไร-ขาดทุน (การตลาด)
                </Text>
              </Flex>
              <Spacer />
              <Box>
                <CustomRangePicker
                  selectedDates={selectedDates}
                  onDateChange={handleDateChange}
                />
              </Box>
            </CardHeader>
            <CardBody>
              <SimpleTable
                tableData={marketingReportData}
                columnsData={marketingReportColumnsData}
                showExport={true}
                downloadableData={downloadableData}
                filename={pageName}
                isLoading={isLoading}
              />
            </CardBody>
          </Card>
        </AnimatedFlex>
      </Stack>
    </Flex>
  );
};

export default MarketingReport;
