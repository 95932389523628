import { Flex, Text, Box } from "@chakra-ui/react";
import AdminNavbarLinks from "./AdminNavbarLinks";

const AdminNavbarLinksWrapper = ({ activeRoutes }) => {
  const isOverview =
    activeRoutes === "ภาพรวม" || activeRoutes === "Default Brand Text";

  return (
    <Flex
      alignItems="center"
      w="100%"
      justifyContent={{ sm: "end", lg: "space-between" }}
      borderRadius="0px"
      minH="65px"
      lineHeight="25.6px"
      mx="auto"
      pb="20px"
      px={{
        md: "2px",
        sm: "20px",
      }}
      ps={{
        md: "0px",
      }}
      pt={{ sm: "45px", lg: "20px" }}
    >
      <Flex display={{ sm: "none", lg: "flex" }}>
        <Text
          fontSize="18px"
          fontWeight="medium"
          ml={{ sm: "none", lg: "20px" }}
          color={isOverview ? "#8C9592" : "green.500"}
        >
          หน้าหลักภาพรวม
        </Text>
        {!isOverview && (
          <Text fontSize="18px" fontWeight="medium" ml="16px">
            {" "}
            /
            <Text as="span" ml="16px">
              {activeRoutes}
            </Text>{" "}
          </Text>
        )}
      </Flex>
      <Box>
        <AdminNavbarLinks
        // onOpen={props.onOpen}
        // logoText={props.logoText}
        // username={props.username}
        // profileUrl={props.profileUrl}
        // secondary={props.secondary}
        // fixed={props.fixed}
        />
      </Box>
    </Flex>
  );
};

export default AdminNavbarLinksWrapper;
