import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  addMemberTag,
  deleteMemberTag,
  fetchMemberTag,
  updateMemberTag,
} from "../../../services/member-management/memberTagService";
import { ERROR_MESSAGE } from "../../../variables/system";
import { useErrorHandler } from "../../common/useErrorHandler";
import { QueryKeys } from "../../../variables/queryKeys";
import { useSuccessHandler } from "../../common/useSuccessHandler";

export const useMemberTag = () => {
  const queryClient = useQueryClient();
  const handleSuccess = useSuccessHandler();
  const handleError = useErrorHandler();

  const { data, isLoading } = useQuery(
    QueryKeys.MEMBER_TAG,
    async () => {
      const response = await fetchMemberTag();
      return response.data.data;
    },
    {
      onError: (error) => handleError(error, ERROR_MESSAGE),
    },
  );

  const saveMemberTagMutation = useMutation(
    async (values) => {
      return values.id ? updateMemberTag(values) : addMemberTag(values);
    },
    {
      onSuccess: (response) => {
        handleSuccess(response.data.message);
        queryClient.invalidateQueries(QueryKeys.MEMBER_TAG);
      },
      onError: (error) => {
        handleError(error, ERROR_MESSAGE);
      },
    },
  );

  const { mutate: deleteTag, isLoading: isDeleting } = useMutation(
    async (id) => await deleteMemberTag(id),
    {
      onSuccess: (response) => {
        handleSuccess(response.data.message);
        queryClient.invalidateQueries(QueryKeys.MEMBER_TAG);
      },
      onError: (error) => {
        handleError(error, ERROR_MESSAGE);
      },
    },
  );

  const handelSaveMemberTag = (values, actions, onClose) => {
    saveMemberTagMutation.mutate(values, {
      onSettled: () => {
        actions.setSubmitting(false);
        onClose();
      },
    });
  };

  return {
    data,
    isLoading,
    handelSaveMemberTag,
    deleteTag,
    isDeleting,
  };
};
