import React from "react";
import { Flex, Grid, Stack, Text } from "@chakra-ui/react";
import CommissionForm from "./components/CommissionForm";
import Card from "components/Card/Card";
import { useCommissionSetting } from "hooks/business/system-setting/useCommissionSetting";
import { useMemberLevel } from "hooks/business/member-management/useMemberLevel";

const CommissionSetting = ({ pageName }) => {
  const {
    commissionData,
    isCommissionLoading,
    isSaving,
    handleCommissionSubmit,
  } = useCommissionSetting();

  const { memberLevelData, isLoading: isMemberLevelDataLoading } =
    useMemberLevel();

  const handleCommissionTurnOver = (values) => {
    handleCommissionSubmit("commissionTurnOver", values);
  };

  const handleCommissionAffiliate = (values) => {
    handleCommissionSubmit("commissionAffiliate", values);
  };

  const handleCommissionWinlose = (values) => {
    handleCommissionSubmit("commissionWinlose", values);
  };

  return (
    <Flex direction="column" overflow="hidden">
      <Stack direction="column" spacing="15px" w="100%">
        <Text
          fontSize="24px"
          fontWeight="bold"
          color="green.500"
          mb="12px"
          mt="12px"
        >
          {pageName}
        </Text>
        <Card p={6}>
          <Grid templateColumns={{ base: "1fr", md: "repeat(1, 1fr)" }} gap={6}>
            <CommissionForm
              title="(แนะนำเพื่อน)"
              initialValues={{
                commissions: commissionData?.commissionAffiliate,
              }}
              handleSubmit={handleCommissionAffiliate}
              memberLevelData={memberLevelData}
              isMemberLevelDataLoading={isMemberLevelDataLoading}
              isCommissionDataLoading={isCommissionLoading}
              isSaving={isSaving}
            />
            <CommissionForm
              title="(แพ้/ชนะ)"
              initialValues={{
                commissions: commissionData?.commissionWinlose,
              }}
              handleSubmit={handleCommissionWinlose}
              memberLevelData={memberLevelData}
              isMemberLevelDataLoading={isMemberLevelDataLoading}
              isCommissionDataLoading={isCommissionLoading}
              isSaving={isSaving}
            />
            <CommissionForm
              title="(เทิร์นโอเวอร์)"
              initialValues={{
                commissions: commissionData?.commissionTurnOver,
              }}
              handleSubmit={handleCommissionTurnOver}
              memberLevelData={memberLevelData}
              isMemberLevelDataLoading={isMemberLevelDataLoading}
              isCommissionDataLoading={isCommissionLoading}
              isSaving={isSaving}
            />
          </Grid>
        </Card>
      </Stack>
    </Flex>
  );
};

export default CommissionSetting;
