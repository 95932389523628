import { FieldArray, Form, Formik } from "formik";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  IconButton,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react";
import AnimatedFlex from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import React from "react";

import { DeleteIcon } from "components/Icons/Icons";
import { useCanonicalSetting } from "hooks/business/website-setting/useCanonicalSetting";

const CanonicalSetting = ({ pageName }) => {
  const { canonicalData, handleSave, isSaving } = useCanonicalSetting();
  const handleSubmit = (values) => {
    handleSave(values);
  };

  return (
    <Flex direction="column">
      <Stack direction="column" spacing="24px" w="100%">
        <AnimatedFlex justify="space-between" duration={0.4} delay={0.4}>
          <Text
            fontSize="24px"
            fontWeight="bold"
            color="green.500"
            mb="12px"
            mt="12px"
          >
            {pageName}
          </Text>
        </AnimatedFlex>
        <Card>
          <Text fontSize="18px" fontWeight="bold" pb={6} color="#fff">
            {pageName}
          </Text>
          <Formik
            initialValues={{
              domain: canonicalData?.domain || "",
              target: canonicalData?.target || "",
              chanonicalPagePayloadList:
                canonicalData?.chanonicalPagePayloadList || [
                  {
                    id: "",
                    page: "",
                    target: "",
                    isDelete: null,
                  },
                ],
            }}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ values, handleChange, setFieldValue }) => (
              <Form>
                <Flex direction="column">
                  <Stack direction="column" spacing="24px" w="100%">
                    <AnimatedFlex duration={0.4} delay={0.4}>
                      <Card
                        w={{ sm: "100%" }}
                        alignSelf="flex-end"
                        justifySelf="flex-end"
                        p={0}
                      >
                        <CardBody>
                          <Grid
                            templateColumns={{
                              base: "1fr",
                              md: "repeat(2, 1fr)",
                            }}
                            gap="24px"
                            w="100%"
                          >
                            <GridItem>
                              <FormControl>
                                <FormLabel>Page</FormLabel>
                                <Input
                                  placeholder="demolive99.com"
                                  fontSize="sm"
                                  value={values.domain}
                                  onChange={handleChange}
                                  name="domain"
                                />
                              </FormControl>
                            </GridItem>
                            <GridItem>
                              <FormControl>
                                <FormLabel>Target</FormLabel>
                                <Input
                                  placeholder="http://www.google.com"
                                  fontSize="sm"
                                  value={values.target}
                                  onChange={handleChange}
                                  name="target"
                                />
                              </FormControl>
                            </GridItem>
                          </Grid>
                        </CardBody>
                      </Card>
                    </AnimatedFlex>
                    <FieldArray name="chanonicalPagePayloadList">
                      {({ push }) => (
                        <>
                          <AnimatedFlex
                            justify="space-between"
                            align="center"
                            w="100%"
                            duration={0.4}
                            delay={0.6}
                          >
                            <Button
                              variant="outline"
                              borderColor={"green.500"}
                              color={"#fff"}
                              bg={"#1E252C"}
                              size="md"
                              borderRadius="33px"
                              onClick={() =>
                                push({
                                  id: "",
                                  page: "",
                                  target: "",
                                  isDelete: null,
                                })
                              }
                            >
                              เพิ่ม TARGET
                            </Button>
                          </AnimatedFlex>
                          <AnimatedFlex duration={0.4} delay={0.8}>
                            <Card
                              w={{ sm: "100%" }}
                              alignSelf="flex-end"
                              justifySelf="flex-end"
                              p={0}
                            >
                              <CardBody>
                                <Grid
                                  templateColumns={{
                                    base: "1fr",
                                    sm: "repeat(2, 1fr) auto",
                                    md: "repeat(2, 1fr) auto",
                                  }}
                                  gap="10px"
                                  w="100%"
                                >
                                  <GridItem colSpan={{ base: 1, sm: 2, md: 3 }}>
                                    <Grid
                                      templateColumns={{
                                        base: "1fr",
                                        sm: "repeat(2, 1fr) auto",
                                        md: "repeat(2, 1fr) auto",
                                      }}
                                      pr={8}
                                    >
                                      <GridItem
                                        display="flex"
                                        alignItems="center"
                                      >
                                        <Text>Page</Text>
                                      </GridItem>
                                      <GridItem
                                        display="flex"
                                        alignItems="center"
                                      >
                                        <Text>Target</Text>
                                      </GridItem>
                                      <GridItem />
                                    </Grid>
                                  </GridItem>
                                  {values.chanonicalPagePayloadList.map(
                                    (condition, index) =>
                                      !condition.isDelete && (
                                        <React.Fragment key={index}>
                                          <GridItem
                                            colSpan={{ base: 1, sm: 1, md: 1 }}
                                          >
                                            <FormControl>
                                              <Input
                                                value={condition.page}
                                                onChange={handleChange}
                                                name={`chanonicalPagePayloadList.${index}.page`}
                                                placeholder="About Us"
                                                fontSize="sm"
                                              />
                                            </FormControl>
                                          </GridItem>
                                          <GridItem
                                            colSpan={{ base: 1, sm: 1, md: 1 }}
                                          >
                                            <FormControl>
                                              <Input
                                                value={condition.target}
                                                onChange={handleChange}
                                                name={`chanonicalPagePayloadList.${index}.target`}
                                                placeholder="www.google.com/contact"
                                                fontSize="sm"
                                              />
                                            </FormControl>
                                          </GridItem>
                                          <GridItem
                                            colSpan={1}
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="end"
                                          >
                                            <IconButton
                                              variant="outline"
                                              borderColor={"red.500"}
                                              color={"red.500"}
                                              bg="#1E252C"
                                              aria-label="delete"
                                              size="md"
                                              icon={<DeleteIcon />}
                                              onClick={() =>
                                                setFieldValue(
                                                  `chanonicalPagePayloadList.${index}.isDelete`,
                                                  "Y",
                                                )
                                              }
                                            />
                                          </GridItem>
                                        </React.Fragment>
                                      ),
                                  )}
                                </Grid>
                              </CardBody>
                            </Card>
                          </AnimatedFlex>
                        </>
                      )}
                    </FieldArray>
                    <Flex justify="flex-start" pt="15px" pb={6}>
                      <Button
                        background="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
                        width={{ base: "100%", md: "auto" }}
                        minW={{ base: "none", md: "235px" }}
                        borderRadius="33px"
                        type="submit"
                        fontWeight="normal"
                        fontSize="14px"
                        isLoading={isSaving}
                      >
                        บันทึกข้อมูล
                      </Button>
                    </Flex>
                  </Stack>
                </Flex>
              </Form>
            )}
          </Formik>
        </Card>
      </Stack>
    </Flex>
  );
};

export default CanonicalSetting;
