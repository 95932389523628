import axios from "axios";
import { trackPromise } from "react-promise-tracker";

import {
  API_BASE_URL,
  ACCESS_TOKEN,
  APP_TOKEN,
  AUTH_URL,
  APP_PROFILE,
  ERROR_MESSAGE,
  PREFIX,
  APP_ROLES,
} from "variables/system";

import { useToast } from "@chakra-ui/react";

const duration = 4000;

const request = (options) => {
  switch (options.method) {
    case "GET":
      return axios
        .get(API_BASE_URL + options.url, {
          headers: {
            Authorization: getAuthorization(),
          },
        })
        .then((response) => {
          return response;
        })
        .catch((reason) => {
          if (
            reason.response &&
            reason.response.data &&
            reason.response.data.message
          ) {
            useToast({
              title: "Error",
              description: reason.response.data.message,
              status: "error",
              duration: duration,
              isClosable: true,
            });
            if (
              reason.response.data.errors &&
              reason.response.data.errors.length
            ) {
              reason.response.data.errors.map((res) => {
                useToast({
                  title: "Error",
                  description: res.description,
                  status: "error",
                  duration: duration,
                  isClosable: true,
                });
              });
            }
          } else {
            useToast({
              title: "Error",
              description: ERROR_MESSAGE,
              status: "error",
              duration: duration,
              isClosable: true,
            });
          }
          checkDuplicate(reason);
          return reason;
        });
    case "POST":
      return axios
        .post(API_BASE_URL + options.url, options.body, {
          headers: {
            "Content-Type": "application/json",
            Authorization: getAuthorization(),
          },
        })
        .then((response) => {
          return response;
        })
        .catch((reason) => {
          if (
            reason.response &&
            reason.response.data &&
            reason.response.data.message
          ) {
            useToast({
              title: "Error",
              description: reason.response.data.message,
              status: "error",
              duration: duration,
              isClosable: true,
            });
            if (
              reason.response.data.errors &&
              reason.response.data.errors.length
            ) {
              reason.response.data.errors.map((res) => {
                useToast({
                  title: "Error",
                  description: res.description,
                  status: "error",
                  duration: duration,
                  isClosable: true,
                });
              });
            }
          } else {
            useToast({
              title: "Error",
              description: ERROR_MESSAGE,
              status: "error",
              duration: duration,
              isClosable: true,
            });
          }
          checkDuplicate(reason);
          return reason;
        });
    case "PUT":
      return axios
        .put(API_BASE_URL + options.url, options.body, {
          headers: {
            "Content-Type": "application/json",
            Authorization: getAuthorization(),
          },
        })
        .then((response) => {
          return response;
        })
        .catch((reason) => {
          if (
            reason.response &&
            reason.response.data &&
            reason.response.data.message
          ) {
            useToast({
              title: "Error",
              description: reason.response.data.message,
              status: "error",
              duration: duration,
              isClosable: true,
            });
            if (
              reason.response.data.errors &&
              reason.response.data.errors.length
            ) {
              reason.response.data.errors.map((res) => {
                useToast({
                  title: "Error",
                  description: res.description,
                  status: "error",
                  duration: duration,
                  isClosable: true,
                });
              });
            }
          } else {
            useToast({
              title: "Error",
              description: ERROR_MESSAGE,
              status: "error",
              duration: duration,
              isClosable: true,
            });
          }
          checkDuplicate(reason);
          return reason;
        });
    case "DELETE":
      return axios
        .delete(API_BASE_URL + options.url, {
          headers: {
            Authorization: getAuthorization(),
          },
        })
        .then((response) => {
          return response;
        })
        .catch((reason) => {
          if (
            reason.response &&
            reason.response.data &&
            reason.response.data.message
          ) {
            useToast({
              title: "Error",
              description: reason.response.data.message,
              status: "error",
              duration: duration,
              isClosable: true,
            });
            if (
              reason.response.data.errors &&
              reason.response.data.errors.length
            ) {
              reason.response.data.errors.map((res) => {
                useToast({
                  title: "Error",
                  description: res.description,
                  status: "error",
                  duration: duration,
                  isClosable: true,
                });
              });
            }
          } else {
            useToast({
              title: "Error",
              description: ERROR_MESSAGE,
              status: "error",
              duration: duration,
              isClosable: true,
            });
          }
          checkDuplicate(reason);
          return reason;
        });
  }
};

export function callapi(url, method, bodyRequest) {
  if (method === "GET") {
    return trackPromise(
      request({
        url: url,
        method: method,
        params: bodyRequest,
      }),
    );
  } else {
    return trackPromise(
      request({
        url: url,
        method: method,
        body: JSON.stringify(bodyRequest),
      }),
    );
  }
}

export function callListApi(url, method, searchData) {
  let sortField = searchData.sortField;
  if (searchData.sortField === "createdBy") {
    sortField = "audit.createdBy";
  } else if (searchData.sortField === "updatedBy") {
    sortField = "audit.updatedBy";
  }

  // console.log(searchData);

  let bodyRequest = {
    ...searchData,
    page: searchData.page - 1,
    size: searchData.sizePerPage,
    // sort: searchData.sortOrder === "desc" ? "D" : "A",
    sortField: sortField,
    createdDateFrom: searchData && searchData.createdDateFrom,
    createdDateTo: searchData && searchData.createdDateTo,

    ...getFilters(searchData.filters),
    sizePerPage: undefined,
    sortOrder: undefined,
    data: undefined,
    filters: undefined,
  };
  return callapi(url, method, bodyRequest);
}

export function loginAdmin(userpass) {
  return trackPromise(
    axios.get(API_BASE_URL + AUTH_URL, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + userpass,
      },
      data: JSON.stringify({}),
    }),
  );
}

export function loginAuth(userpass, prefix) {
  return trackPromise(
    axios.post(API_BASE_URL + AUTH_URL, JSON.stringify({ prefix: prefix }), {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Basic " + userpass,
      },
    }),
  );
}

export function logout() {
  //TODO CALL LOGOUT
  localStorage.removeItem(APP_TOKEN);
  localStorage.removeItem(APP_PROFILE);
  localStorage.removeItem(PREFIX);
  localStorage.removeItem(APP_ROLES);
}

export function getAuthorization() {
  const accessToken = localStorage.getItem(APP_TOKEN);
  return ACCESS_TOKEN + accessToken;
}

function getFilters(filters) {
  let filter = {};
  if (filters && Object.keys(filters).length !== 0) {
    Object.entries(filters).forEach(([key, value]) => {
      if (value.filterType === "NUMBER") {
        filter[key] = value.filterVal.number;
      } else {
        filter[key] = value.filterVal;
      }
    });
  }
  return filter;
}

function checkDuplicate(reason) {
  if (reason?.response?.status === 401) {
    setTimeout(() => {
      logout();
    }, 2000);
  }
}

export function fileUpload(url, data) {
  return axios.post(API_BASE_URL + url, data, {
    headers: {
      "content-type": "multipart/form-data",
      Authorization: getAuthorization(),
    },
  });
}
