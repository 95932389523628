import React from "react";
import {
  Avatar,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react";
import { Formik, Form } from "formik";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import Card from "components/Card/Card";
import AnimatedFlex from "components/AnimatedFlex/MotionFlex";
import avatar8 from "assets/img/avatars/avatar8.png";
import { useAdminProfile } from "hooks/business/useAdminProfile";

const AdminProfile = ({ pageName }) => {
  const { data: adminData, handleSubmit, isSaving } = useAdminProfile();
  return (
    <Flex direction="column" pt={{ sm: "65px", lg: "15px" }} overflow="hidden">
      <Box p={{ base: "0px 0px", "2xl": "0px 375px" }}>
        <Stack direction="column" spacing="24px" w="100%">
          <AnimatedFlex justify="space-between" duration={0.4} delay={0.4}>
            <Text fontWeight="bold" fontSize="lg">
              {pageName}
            </Text>
          </AnimatedFlex>
          <Stack direction="column" spacing="24px">
            <AnimatedFlex duration={0.4} delay={0.4}>
              <Card>
                <CardBody>
                  <Flex direction="column" w="100%">
                    <Box
                      display="flex"
                      lineHeight="100%"
                      fontWeight="normal"
                      alignItems="center"
                      fontSize="11px"
                    >
                      <Link
                        border="1px solid"
                        borderColor="green.500"
                        borderRadius="50%"
                        mb="6px"
                        p="4px"
                      >
                        <Avatar src={avatar8} size="sm" />
                      </Link>
                      <Flex
                        direction="column"
                        alignItems="flex-start"
                        ml="15px"
                      >
                        <Text fontSize="md">{adminData?.username}</Text>
                        <Flex alignItems="center" mt="12px">
                          <Text fontSize="sm" color="green.500">
                            {adminData?.role}
                          </Text>
                        </Flex>
                      </Flex>
                    </Box>
                  </Flex>
                </CardBody>
              </Card>
            </AnimatedFlex>
          </Stack>
          <Stack direction="column" spacing="24px">
            <Formik
              initialValues={{
                username: adminData?.username || "",
                roleCode: adminData?.role || "",
                fullName: adminData?.fullName || "",
                tel: adminData?.tel || "",
                password: null,
              }}
              onSubmit={handleSubmit}
              enableReinitialize
            >
              {({ values, handleChange }) => (
                <Form>
                  <AnimatedFlex duration={0.4} delay={0.6}>
                    <Card>
                      <CardHeader mb="22px">
                        <Text fontSize="lg" fontWeight="bold">
                          แก้ไขข้อมูลส่วนตัว
                        </Text>
                      </CardHeader>
                      <CardBody>
                        <Stack direction="column" spacing="20px" w="100%">
                          <Stack
                            direction={{ sm: "column", md: "row" }}
                            spacing="30px"
                          >
                            <FormControl>
                              <FormLabel
                                fontSize="xs"
                                fontWeight="bold"
                                mb="10px"
                              >
                                ยูสเซอร์เนม
                              </FormLabel>
                              <Input
                                name="username"
                                fontSize="xs"
                                readOnly
                                bgColor="gray.800"
                                value={values.username}
                                onChange={handleChange}
                              />
                            </FormControl>
                            <FormControl>
                              <FormLabel
                                fontSize="xs"
                                fontWeight="bold"
                                mb="10px"
                              >
                                ตำแหน่ง
                              </FormLabel>
                              <Input
                                borderRadius="4px"
                                name="roleCode"
                                fontSize="xs"
                                readOnly
                                bgColor="gray.800"
                                value={values.roleCode}
                                onChange={handleChange}
                              />
                            </FormControl>
                          </Stack>
                          <Stack
                            direction={{ sm: "column", lg: "row" }}
                            spacing="30px"
                          >
                            <FormControl>
                              <FormLabel
                                fontSize="xs"
                                fontWeight="bold"
                                mb="10px"
                              >
                                ชื่อ-นามสกุล
                              </FormLabel>
                              <Input
                                borderRadius="4px"
                                name="fullName"
                                fontSize="xs"
                                value={values.fullName}
                                onChange={handleChange}
                              />
                            </FormControl>
                            <FormControl>
                              <FormLabel
                                fontSize="xs"
                                fontWeight="bold"
                                mb="10px"
                              >
                                เบอร์โทร
                              </FormLabel>
                              <Input
                                borderRadius="4px"
                                name="tel"
                                fontSize="xs"
                                value={values.phoneNumber}
                                onChange={handleChange}
                              />
                            </FormControl>
                          </Stack>
                          <Stack
                            direction={{ sm: "column", md: "row" }}
                            spacing="30px"
                          >
                            <FormControl>
                              <FormLabel
                                fontSize="xs"
                                fontWeight="bold"
                                mb="10px"
                              >
                                รหัสผ่าน
                              </FormLabel>
                              <Input
                                borderRadius="4px"
                                name="password"
                                type="password"
                                fontSize="xs"
                                value={values.password}
                                onChange={handleChange}
                              />
                            </FormControl>
                            <FormControl />
                          </Stack>
                        </Stack>
                      </CardBody>
                    </Card>
                  </AnimatedFlex>
                  <AnimatedFlex
                    justify="flex-end"
                    duration={0.4}
                    delay={0.8}
                    mt="24px"
                  >
                    <Button
                      backgroundColor="green.500"
                      color={"#000000"}
                      width={{ base: "100%", md: "auto" }}
                      minW={{ base: "none", md: "350px" }}
                      borderRadius="6px"
                      type="submit"
                      isLoading={isSaving}
                    >
                      บันทึกข้อมูล
                    </Button>
                  </AnimatedFlex>
                </Form>
              )}
            </Formik>
          </Stack>
        </Stack>
      </Box>
    </Flex>
  );
};

export default AdminProfile;
