import { Flex, Grid, Text } from "@chakra-ui/react";
import ActivityImageCard from "./components/ActivityImageCard";
import Card from "components/Card/Card";
import { useActivityImage } from "hooks/business/website-setting/useActivityImage";

const ActivityImageSetting = ({ pageName }) => {
  const { activityImageData, handleImageUpload, handleToggle } =
    useActivityImage();

  return (
    <Flex direction="column" overflow="hidden">
      <Text
        fontSize="24px"
        fontWeight="bold"
        color="green.500"
        mb="24px"
        mt="12px"
      >
        {pageName}
      </Text>
      <Card pb="48px">
        <Grid
          templateColumns={{ base: "repeat(1, 1fr)", lg: "repeat(3, 1fr)" }}
          gap={6}
        >
          {activityImageData?.map((activity) => (
            <ActivityImageCard
              key={activity.id}
              title={activity.title}
              imageSrc={activity.backgroundImage}
              isActive={activity.active}
              onDelete={() => handleImageUpload(activity.id, "")}
              onToggle={() => handleToggle(activity.id)}
              onImageUpload={(newImageUrl) =>
                handleImageUpload(activity.id, newImageUrl)
              }
            />
          ))}
        </Grid>
      </Card>
    </Flex>
  );
};

export default ActivityImageSetting;
