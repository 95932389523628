import React from "react";
import { Flex, Grid, GridItem, Stack, Text } from "@chakra-ui/react";

import AnimatedFlex from "components/AnimatedFlex/MotionFlex";
import ProviderCard from "./components/ProviderCard";
import Card from "components/Card/Card";
import { useGameSetting } from "hooks/business/system-setting/useGameSetting";

const GameSetting = ({ pageName }) => {
  const {
    slotProviderData,
    casinoProviderData,
    cardProviderData,
    sportProviderData,
    isLoading,
    handleToggle,
  } = useGameSetting();
  return (
    <Flex direction="column" overflow="hidden">
      <Stack direction="column" spacing="15px" w="100%">
        <AnimatedFlex justify="space-between" duration={0.4} delay={0.4}>
          <Text
            fontSize="24px"
            fontWeight="bold"
            color="green.500"
            mb="12px"
            mt="12px"
          >
            {pageName}
          </Text>
        </AnimatedFlex>
        <Card>
          <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={6}>
            <GridItem>
              <AnimatedFlex justify="space-between" duration={0.4} delay={0.6}>
                <ProviderCard
                  title="Slot"
                  providerData={slotProviderData}
                  isLoading={isLoading}
                  handleToggle={handleToggle}
                />
              </AnimatedFlex>
            </GridItem>
            <GridItem>
              <AnimatedFlex justify="space-between" duration={0.4} delay={0.8}>
                <ProviderCard
                  title="Casino"
                  providerData={casinoProviderData}
                  isLoading={isLoading}
                  handleToggle={handleToggle}
                />
              </AnimatedFlex>
            </GridItem>
            <GridItem>
              <AnimatedFlex justify="space-between" duration={0.4} delay={1.0}>
                <ProviderCard
                  title="Skill"
                  providerData={cardProviderData}
                  isLoading={isLoading}
                  handleToggle={handleToggle}
                  pt={6}
                />
              </AnimatedFlex>
            </GridItem>
            <GridItem>
              <AnimatedFlex justify="space-between" duration={0.4} delay={1.2}>
                <ProviderCard
                  title="Sport"
                  providerData={sportProviderData}
                  isLoading={isLoading}
                  handleToggle={handleToggle}
                  pt={6}
                />
              </AnimatedFlex>
            </GridItem>
          </Grid>
        </Card>
      </Stack>
    </Flex>
  );
};

export default GameSetting;
