import { useMutation, useQuery, useQueryClient } from "react-query";
import { QueryKeys } from "../../../variables/queryKeys";
import {
  fetchSpinnerSetting,
  saveSpinnerSetting,
} from "../../../services/system-setting/spinnerSettingService";
import { ERROR_MESSAGE } from "../../../variables/system";
import { useErrorHandler } from "../../common/useErrorHandler";
import { useSuccessHandler } from "../../common/useSuccessHandler";

export const useSpinnerSetting = () => {
  const queryClient = useQueryClient();
  const handleError = useErrorHandler();
  const handleSuccess = useSuccessHandler();

  const { data: spinnerSettingData, isLoading } = useQuery(
    QueryKeys.SPINNER_SETTING,
    async () => {
      const response = await fetchSpinnerSetting();
      return response.data.data;
    },
  );

  const mutation = useMutation(saveSpinnerSetting, {
    onSuccess: (response) => {
      handleSuccess(response.data.message);
      queryClient.invalidateQueries(QueryKeys.SPINNER_SETTING);
    },
    onError: (error) => handleError(error, ERROR_MESSAGE),
  });

  const handleSubmit = (values) => {
    mutation.mutate(values);
  };

  return {
    spinnerSettingData,
    isLoading,
    handleSubmit,
    isSaving: mutation.isLoading,
  };
};
