import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Grid,
  IconButton,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react";

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import {
  fetchGamesByProvider,
  fetchGameSetting,
  fetchRecommendGames,
  saveRecommendGame,
} from "services/system-setting/gameSettingService";
import { useErrorHandler } from "hooks/common/useErrorHandler";
import { ERROR_MESSAGE } from "variables/system";
import { useSuccessHandler } from "hooks/common/useSuccessHandler";
import SearchableDropdown from "components/SearchableDropdown/SearchableDropdown";
import { DeleteIcon, ListIcon } from "../../../components/Icons/Icons";

const GameRecommendSetting = ({ pageName }) => {
  const [searchOptions, setSearchOptions] = useState([]);
  const [leftItems, setLeftItems] = useState([]);
  const [rightItems, setRightItems] = useState([]);
  const [notSelectedGameSearchTerm, setNotSelectedGameSearchTerm] =
    useState("");
  const [selectedGameSearchTerm, setSelectedSearchTerm] = useState("");

  const handleError = useErrorHandler();
  const handleSuccess = useSuccessHandler();

  const fetchAllProviders = () => {
    fetchGameSetting()
      .then((response) => {
        const options = response.data.data.gameSettingList
          .filter((setting) => setting.type === "SLOT")
          .map((setting) => ({
            value: setting.provider,
            label: `${setting.gamename} (${setting.type})`,
          }));
        setSearchOptions(options);
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  };

  const fetchCurrentRecommendGames = () => {
    fetchRecommendGames()
      .then((response) => {
        const games = response.data.data.map((game) => ({
          gameName: game.gameName,
          gameCode: game.gameCode,
          provider: game.provider,
          gameImage: game.gameImage,
        }));
        setRightItems(games);
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  };

  const handleProviderSelect = (selectedOption) => {
    fetchGamesByProvider(selectedOption.value)
      .then((response) => {
        const games = response.data.data.games.map((game) => ({
          gameName: game.gameName,
          gameCode: game.gameCode,
          provider: response.data.data.provider,
          gameImage: game.gameImage,
        }));
        setLeftItems(games);
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  };

  useEffect(() => {
    fetchAllProviders();
    fetchCurrentRecommendGames();
  }, []);

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    let sourceItems, destinationItems, setSourceItems, setDestinationItems;

    if (result.source.droppableId === "left") {
      sourceItems = Array.from(leftItems);
      destinationItems = Array.from(rightItems);
      setSourceItems = setLeftItems;
      setDestinationItems = setRightItems;
    } else {
      return;
    }

    const [movedItem] = sourceItems.splice(sourceIndex, 1);

    if (
      result.source.droppableId === "left" &&
      rightItems.some((item) => item.gameCode === movedItem.gameCode)
    ) {
      setSourceItems(sourceItems);
      return;
    }

    destinationItems.splice(destinationIndex, 0, movedItem);

    setSourceItems(sourceItems);
    setDestinationItems(destinationItems);

    if (result.source.droppableId === "left") {
      const data = [...rightItems, movedItem];
      const payload = { data: data };
      saveRecommendGame(payload)
        .then((response) => {
          handleSuccess(response.data.message);
        })
        .catch((error) => {
          handleError(error, ERROR_MESSAGE);
        });
    }
  };

  const handleNotSelectedGameSearch = (e) => {
    setNotSelectedGameSearchTerm(e.target.value);
  };

  const handleSelectedGameSearch = (e) => {
    setSelectedSearchTerm(e.target.value);
  };

  const filteredRightItems = rightItems.filter((item) =>
    item.gameName.toLowerCase().includes(selectedGameSearchTerm.toLowerCase()),
  );

  const filteredLeftItems = leftItems.filter((item) =>
    item.gameName
      .toLowerCase()
      .includes(notSelectedGameSearchTerm.toLowerCase()),
  );

  const handleDelete = (gameCode) => {
    const updatedRightItems = rightItems.filter(
      (item) => item.gameCode !== gameCode,
    );
    setRightItems(updatedRightItems);

    const payload = { data: updatedRightItems };

    saveRecommendGame(payload)
      .then((response) => {
        handleSuccess(response.data.message);
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  };

  return (
    <Flex direction="column" overflow="hidden">
      <Stack direction="column" spacing="15px" w="100%">
        <Text
          fontSize="24px"
          fontWeight="bold"
          color="green.500"
          mb="12px"
          mt="12px"
        >
          {pageName}
        </Text>
        <Card>
          <Card w="100%" mb="6" p={0} boxShadow="none">
            <CardHeader>
              <Text fontSize="18px" fontWeight="bold" mb="4" color="#fff">
                เลือกค่ายเกมส์
              </Text>
            </CardHeader>
            <CardBody>
              <Grid templateColumns="repeat(2, 1fr)" w="100%" gap={6}>
                <SearchableDropdown
                  options={searchOptions}
                  isSearchable
                  placeholder="เลือกค่ายเกม"
                  onChange={handleProviderSelect}
                  fontSize="sm"
                />
              </Grid>
            </CardBody>
          </Card>
          <Card w="100%" p={0} boxShadow="none" pt={6} pb="64px">
            <CardBody>
              <DragDropContext onDragEnd={handleDragEnd}>
                <Grid templateColumns="repeat(2, 1fr)" w="100%" gap={6}>
                  <Box>
                    <Flex alignItems="center">
                      <Text
                        fontSize="18px"
                        fontWeight="bold"
                        mr={1}
                        color="#fff"
                      >
                        รายชื่อเกมส์
                      </Text>
                      <Box as="span" color="green.500" fontWeight="normal">
                        (ยังไม่แนะนำ)
                      </Box>
                    </Flex>
                    <Droppable droppableId="left">
                      {(provided) => (
                        <Box
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          pt="4"
                          bg="gray.700"
                          borderRadius="md"
                          minHeight="400px"
                          fontSize="sm"
                        >
                          <Input
                            placeholder="ค้นหาเกมที่ยังไม่แนะนำ..."
                            mb="4"
                            value={notSelectedGameSearchTerm}
                            onChange={handleNotSelectedGameSearch}
                            fontSize="sm"
                          />
                          <Card bg="#1E252C" borderRadius="10px" height="650px">
                            <Card
                              p={0}
                              bg="transparent"
                              overflowY="auto"
                              borderRadius="none"
                            >
                              {filteredLeftItems.map((item, index) => (
                                <Draggable
                                  key={item.gameCode}
                                  draggableId={item.gameCode}
                                  index={index}
                                >
                                  {(provided) => (
                                    <Flex
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      mb="2"
                                      p="4"
                                      borderRadius="10px"
                                      border="1px dashed rgba(55, 63, 72, 1)"
                                      h="55px"
                                    >
                                      <ListIcon
                                        h="18px"
                                        w="10px"
                                        color="#373F48"
                                      />
                                      <Text ml="12px" fontSize="16px">
                                        {item.gameName}
                                      </Text>
                                    </Flex>
                                  )}
                                </Draggable>
                              ))}
                            </Card>
                          </Card>
                          {provided.placeholder}
                        </Box>
                      )}
                    </Droppable>
                  </Box>

                  <Box>
                    <Flex alignItems="center">
                      <Text
                        fontSize="18px"
                        fontWeight="bold"
                        mr={1}
                        color="#fff"
                      >
                        รายชื่อเกมส์
                      </Text>
                      <Box as="span" color="green.500" fontWeight="normal">
                        (แนะนำ)
                      </Box>
                    </Flex>
                    <Droppable droppableId="right">
                      {(provided) => (
                        <Box
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          pt="4"
                          bg="gray.700"
                          borderRadius="10px"
                          minHeight="400px"
                        >
                          <Input
                            placeholder="ค้นหาเกมที่แนะนำ..."
                            mb="4"
                            value={selectedGameSearchTerm}
                            onChange={handleSelectedGameSearch}
                            fontSize="sm"
                          />
                          <Card bg="#1E252C" borderRadius="10px" height="650px">
                            <Card
                              p={0}
                              bg="transparent"
                              overflowY="auto"
                              borderRadius="none"
                            >
                              {filteredRightItems.map((item, index) => (
                                <Draggable
                                  key={item.gameCode}
                                  draggableId={item.gameCode}
                                  index={index}
                                >
                                  {(provided) => (
                                    <Flex
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      mb="2"
                                      p="4"
                                      borderRadius="10px"
                                      border="1px"
                                      borderColor="green.500"
                                      alignItems="center"
                                      justifyContent="space-between"
                                      h="55px"
                                    >
                                      <Flex>
                                        <ListIcon
                                          h="18px"
                                          w="10px"
                                          color="#373F48"
                                        />
                                        <Text
                                          ml="12px"
                                          fontSize="16px"
                                          color="green.500"
                                        >
                                          {item.gameName}
                                        </Text>
                                      </Flex>
                                      <IconButton
                                        icon={<DeleteIcon />}
                                        w={"36px"}
                                        h={"26px"}
                                        cursor="pointer"
                                        color="#fff"
                                        bg="red.500"
                                        variant="solid"
                                        onClick={() =>
                                          handleDelete(item.gameCode)
                                        }
                                      />
                                    </Flex>
                                  )}
                                </Draggable>
                              ))}
                            </Card>
                          </Card>
                          {provided.placeholder}
                        </Box>
                      )}
                    </Droppable>
                  </Box>
                </Grid>
              </DragDropContext>
            </CardBody>
          </Card>
        </Card>
      </Stack>
    </Flex>
  );
};

export default GameRecommendSetting;
