import {
  Badge,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Switch,
  Text,
} from "@chakra-ui/react";
import React from "react";

import { Field, Form, Formik } from "formik";
import { getJackpotBackground } from "utils/CommonUtils";
import { transformJackpotArrayToJson } from "../../../../utils/JackpotUtils";

const JackpotBotSettingModal = ({
  isOpen,
  onClose,
  jackpot,
  isActive,
  jackpotData,
  handleSubmit,
}) => {
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const handleSetJackpotSetting = async (values, actions) => {
    const transformedData = transformJackpotArrayToJson(jackpotData);
    const combinedJackpot = {
      ...transformedData,
      ...values,
      active: isActive,
    };
    handleSubmit(combinedJackpot, actions, onClose);
  };

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Box textAlign="center">
            <Flex alignItems="center" justifyContent="left">
              ตั้งค่าแจก AUTO
            </Flex>
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text
            fontSize="16px"
            fontWeight="bold"
            pt={4}
            pb={6}
            color="green.500"
          >
            ตั้งค่ารางวัลแจ็คพอต
          </Text>
          <Formik
            initialValues={{
              [`auto${jackpot?.type}`]: jackpot
                ? jackpot[`auto${jackpot.type}`] || false
                : false,
              [`autoHour${jackpot?.type}`]: jackpot
                ? jackpot[`autoHour${jackpot.type}`] || ""
                : "",
              [`autoProfit${jackpot?.type}`]: jackpot
                ? jackpot[`autoProfit${jackpot.type}`] || ""
                : "",
              [`autoTurn${jackpot?.type}`]: jackpot
                ? jackpot[`autoTurn${jackpot.type}`] || ""
                : "",
            }}
            enableReinitialize
            onSubmit={(values, actions) => {
              handleSetJackpotSetting(values, actions);
              onClose();
            }}
          >
            {(props) => {
              return (
                <Form>
                  <Field name={`auto${jackpot?.type}`}>
                    {({ field, form }) => (
                      <FormControl>
                        <Flex justify="space-between">
                          <Stack>
                            <FormLabel>ประเภท</FormLabel>
                            <Badge
                              bg={getJackpotBackground(jackpot?.type)}
                              w="77px"
                              h="28px"
                              borderRadius="15px"
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              fontWeight="normal"
                            >
                              {jackpot?.type.toUpperCase()}
                            </Badge>
                          </Stack>
                          <Flex>
                            <Switch
                              id="is-no-bonus"
                              {...field}
                              isChecked={form.values[`auto${jackpot?.type}`]}
                              onChange={(e) =>
                                form.setFieldValue(
                                  `auto${jackpot?.type}`,
                                  e.target.checked,
                                )
                              }
                              alignContent="flex-end"
                            />
                            <Text
                              htmlFor="is-no-bonus"
                              alignContent="end"
                              ml={3}
                            >
                              เปิด/ปิด การใช้งาน
                            </Text>
                          </Flex>
                        </Flex>
                      </FormControl>
                    )}
                  </Field>
                  <Field name={`autoHour${jackpot?.type}`}>
                    {({ field, form }) => (
                      <FormControl mt={4}>
                        <FormLabel>ทุกๆกี่ชั่วโมง</FormLabel>
                        <NumberInput
                          value={form.values[`autoHour${jackpot?.type}`]}
                          onChange={(valueString) =>
                            form.setFieldValue(
                              `autoHour${jackpot?.type}`,
                              parseFloat(valueString),
                            )
                          }
                        >
                          <NumberInputField
                            ref={initialRef}
                            {...field}
                            fontSize="sm"
                          />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                      </FormControl>
                    )}
                  </Field>
                  <Field name={`autoProfit${jackpot.type}`}>
                    {({ field, form }) => (
                      <FormControl mt={4}>
                        <FormLabel>เมื่อยอดกำไรถึง</FormLabel>
                        <NumberInput
                          value={form.values[`autoProfit${jackpot.type}`]}
                          onChange={(valueString) =>
                            props.setFieldValue(
                              `autoProfit${jackpot.type}`,
                              valueString,
                            )
                          }
                        >
                          <NumberInputField
                            ref={initialRef}
                            {...field}
                            fontSize="sm"
                          />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                      </FormControl>
                    )}
                  </Field>
                  <Field name={`autoTurn${jackpot.type}`}>
                    {({ field, form }) => (
                      <FormControl mt={4}>
                        <FormLabel>ยอดเทรินโอเวอร์ (จำนวนเท่า)</FormLabel>
                        <NumberInput
                          value={form.values[`autoTurn${jackpot.type}`]}
                          onChange={(valueString) =>
                            props.setFieldValue(
                              `autoTurn${jackpot.type}`,
                              valueString,
                            )
                          }
                        >
                          <NumberInputField
                            ref={initialRef}
                            {...field}
                            fontSize="sm"
                          />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                      </FormControl>
                    )}
                  </Field>
                  <ModalFooter pl={0} pr={0} mt={4}>
                    <Button
                      background="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
                      w="100%"
                      isLoading={props.isSubmitting}
                      type="submit"
                      fontWeight="normal"
                      fontSize="14px"
                    >
                      ทำรายการ
                    </Button>
                  </ModalFooter>
                </Form>
              );
            }}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default JackpotBotSettingModal;
