import { useHistory } from "react-router-dom";
import { useErrorHandler } from "../../common/useErrorHandler";
import { useMutation } from "react-query";
import { loginAuth } from "../../../utils/APIUtils";
import {
  APP_PROFILE,
  APP_ROLES,
  APP_TOKEN,
  ERROR_MESSAGE,
  PREFIX,
} from "../../../variables/system";

export const useLogin = () => {
  const history = useHistory();
  const handleError = useErrorHandler();

  const mutation = useMutation(
    async ({ username, password, prefix }) => {
      const credentials = btoa(`${username}:${password}`);
      return await loginAuth(credentials, prefix);
    },
    {
      onSuccess: (response) => {
        const { token, profile, roleMenuList } = response.data;
        localStorage.setItem(APP_TOKEN, token);
        localStorage.setItem(APP_PROFILE, JSON.stringify(profile));
        localStorage.setItem(PREFIX, profile.prefix);

        const roles = roleMenuList.menuList || [];
        localStorage.setItem(APP_ROLES, JSON.stringify(roles));

        history.push("/admin/summary");
      },
      onError: (error) => {
        if (error.response?.data?.message) {
          handleError(ERROR_MESSAGE, error.response.data.message);
        }
      },
    },
  );

  const handleLogin = (username, password, prefix) => {
    mutation.mutate({ username, password, prefix });
  };

  return {
    handleLogin,
    isLoading: mutation.isLoading,
  };
};
