import { useSuccessHandler } from "../../common/useSuccessHandler";
import { useErrorHandler } from "../../common/useErrorHandler";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  fetchCommissionSetting,
  updateCommissionSetting,
} from "../../../services/system-setting/commissionSettingService";
import { ERROR_MESSAGE } from "../../../variables/system";
import { buildCommissionSettingPayload } from "../../../utils/PayloadUtils";
import { QueryKeys } from "../../../variables/queryKeys";

export const useCommissionSetting = () => {
  const handleSuccess = useSuccessHandler();
  const handleError = useErrorHandler();
  const queryClient = useQueryClient();

  const { data: commissionData, isLoading: isCommissionLoading } = useQuery(
    QueryKeys.COMMISSION_SETTING,
    fetchCommissionSetting,
    {
      onError: (error) => handleError(error, ERROR_MESSAGE),
      select: (data) => data.data.data,
    },
  );

  const mutation = useMutation(updateCommissionSetting, {
    onSuccess: (response) => {
      handleSuccess(response.data.message);
      queryClient.invalidateQueries(QueryKeys.COMMISSION_SETTING);
    },
    onError: (error) => handleError(error, ERROR_MESSAGE),
  });

  const handleCommissionSubmit = (type, values) => {
    const preparedData = buildCommissionSettingPayload(
      type,
      values,
      commissionData?.commissionAffiliate,
      commissionData?.commissionWinlose,
      commissionData?.commissionTurnOver,
      commissionData?.id,
    );
    mutation.mutate(preparedData);
  };
  return {
    commissionData,
    isCommissionLoading,
    isSaving: mutation.isLoading,
    handleCommissionSubmit,
  };
};
