import { Box, Flex, Spacer, Stack, Text } from "@chakra-ui/react";
import React from "react";

import AnimatedFlex from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import { memberTransactionColumnsData } from "variables/columns/member-report/memberTransactionColumnsData";
import CustomRangePicker from "components/CustomRangePicker/CustomRangePicker";
import { QueryClientProvider, useQueryClient } from "react-query";
import SearchTableRemote from "components/Tables/SearchTableRemote";
import { useMemberTransactionReport } from "hooks/business/member-report/useMemberTransactionReport";
import { useSearch } from "hooks/common/useSearch";
import { QueryKeys } from "variables/queryKeys";
// fix pagination
const MemberTransactionReport = ({ pageName }) => {
  const queryClient = useQueryClient();

  const dateFieldsNames = {
    from: "listDateFrom",
    to: "listDateTo",
  };
  const dateSearchFormat = "yyyy-MM-DD HH:mm:ss";

  const { fetchData, downloadableData } = useMemberTransactionReport();
  const { search, setSearch, selectedDates, handleDateChange } = useSearch(
    dateFieldsNames,
    dateSearchFormat,
  );

  return (
    <Flex direction="column" overflow="hidden">
      <Stack direction="column" spacing="24px" w="100%">
        <AnimatedFlex justify="space-between" duration={0.4} delay={0.4}>
          <Text fontWeight="bold" fontSize="24px" color="green.500">
            {pageName}
          </Text>
        </AnimatedFlex>
        <AnimatedFlex duration={0.4} delay={0.6}>
          <Card px="22px" pb="38px">
            <CardHeader pb="12px" pt="12px">
              <Flex direction="column">
                <Text fontSize="18px" fontWeight="bold" mb="6px" color="#fff">
                  รายงานฝากถอนสมาชิก
                </Text>
              </Flex>
              <Spacer />
              <Box>
                <CustomRangePicker
                  selectedDates={selectedDates}
                  onDateChange={handleDateChange}
                />
              </Box>
            </CardHeader>
            <CardBody>
              <Flex direction="column" align="flex-end" mb="24px" w="100%">
                <Box width="100%">
                  <QueryClientProvider client={queryClient}>
                    <SearchTableRemote
                      query={QueryKeys.MEMBER_TRANSACTION_REPORT}
                      columnsData={memberTransactionColumnsData()}
                      fetchData={fetchData}
                      search={search}
                      setSearch={setSearch}
                      showExport={true}
                      downloadableData={downloadableData}
                      filename={pageName}
                    />
                  </QueryClientProvider>
                </Box>
              </Flex>
            </CardBody>
          </Card>
        </AnimatedFlex>
      </Stack>
    </Flex>
  );
};

export default MemberTransactionReport;
