import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  addBankSetting,
  deleteBankSetting,
  fetchBankSetting,
  updateBankSetting,
} from "../../../services/finance-setting/bankSettingService";
import { ERROR_MESSAGE } from "../../../variables/system";
import { useErrorHandler } from "../../common/useErrorHandler";
import { QueryKeys } from "../../../variables/queryKeys";
import { useSuccessHandler } from "../../common/useSuccessHandler";

export const useBankSetting = () => {
  const handleError = useErrorHandler();
  const handleSuccess = useSuccessHandler();
  const queryClient = useQueryClient();

  const { data: bankData, isLoading } = useQuery(
    QueryKeys.BANK_SETTING,
    async () => {
      const response = await fetchBankSetting();
      return response.data.data;
    },
    {
      onError: (error) => handleError(error, ERROR_MESSAGE),
    },
  );

  const saveMutation = useMutation(
    async ({ values, bank }) => {
      const payload = bank ? { ...bank, ...values } : values;
      return bank
        ? updateBankSetting(bank.id, payload)
        : addBankSetting(payload);
    },
    {
      onSuccess: (response) => {
        handleSuccess(response.data.message);
        queryClient.invalidateQueries([QueryKeys.BANK_SETTING]);
      },
      onError: (error) => handleError(error, ERROR_MESSAGE),
    },
  );

  const deleteMutation = useMutation(
    async (id) => await deleteBankSetting(id),
    {
      onSuccess: (response) => {
        handleSuccess(response.data.message);
        queryClient.invalidateQueries([QueryKeys.BANK_SETTING]);
      },
      onError: (error) => handleError(error, ERROR_MESSAGE),
    },
  );

  const handleSubmit = (values, bank, actions, onClose) => {
    saveMutation.mutate(
      { values, bank },
      {
        onSettled: () => {
          actions.setSubmitting(false);
          onClose();
        },
      },
    );
  };

  const handleDelete = (id) => {
    deleteMutation.mutate(id);
  };

  return {
    bankData,
    isLoading,
    handleSubmit,
    handleDelete,
    isDeleting: deleteMutation.isLoading,
  };
};
