import { Field, Form, Formik } from "formik";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Stack,
  Switch,
  Text,
  Textarea,
  useBreakpointValue,
} from "@chakra-ui/react";
import AnimatedFlex from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import { Element } from "react-scroll";
import CardBody from "components/Card/CardBody";
import React from "react";
import { useLiveChatSetting } from "hooks/business/website-setting/useLiveChatSetting";

const LiveChatSetting = ({ pageName }) => {
  const gridWidth = useBreakpointValue({ base: "100%", md: "50%" });
  const { liveChatSettingData, handleSave, isSaving } = useLiveChatSetting();
  const handleSubmit = (payload) => handleSave(payload);

  return (
    <Flex direction="column">
      <Stack direction="column" spacing="24px" w="100%">
        <AnimatedFlex justify="space-between" duration={0.4} delay={0.4}>
          <Text
            fontSize="24px"
            fontWeight="bold"
            color="green.500"
            mb="12px"
            mt="12px"
          >
            {pageName}
          </Text>
        </AnimatedFlex>
        <Formik
          initialValues={{
            chatscript: liveChatSettingData?.chatscript || "",
            status: liveChatSettingData?.status || false,
          }}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ values, setFieldValue }) => (
            <Form>
              <Flex direction="column">
                <AnimatedFlex duration={0.4} delay={0.6}>
                  <Card
                    w={{ sm: "100%" }}
                    alignSelf="flex-end"
                    justifySelf="flex-end"
                  >
                    <Text fontSize="18px" fontWeight="bold" pb={6} color="#fff">
                      จัดการตั้งค่า LiveChat
                    </Text>
                    <Element>
                      <CardBody>
                        <Grid
                          templateColumns={{
                            base: "1fr",
                            md: "repeat(1, 1fr)",
                          }}
                          gap="24px"
                          w={gridWidth}
                        >
                          <GridItem>
                            <FormControl>
                              <FormLabel>Script</FormLabel>
                              <Field
                                as={Textarea}
                                name="chatscript"
                                minHeight="315px"
                                fontSize="sm"
                              />
                            </FormControl>
                          </GridItem>
                          <GridItem>
                            <Flex>
                              <Switch
                                isChecked={Boolean(values.status)}
                                onChange={(e) => {
                                  setFieldValue("status", e.target.checked);
                                }}
                                mr={4}
                              />
                              <FormLabel>เปิด/ปิด LiveChat</FormLabel>
                            </Flex>
                          </GridItem>
                        </Grid>
                      </CardBody>
                    </Element>
                    <AnimatedFlex
                      justify="flex-start"
                      pt="15px"
                      duration={0.4}
                      delay={0.8}
                      pb={6}
                    >
                      <Button
                        background="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
                        width={{ base: "100%", md: "auto" }}
                        minW={{ base: "none", md: "195px" }}
                        borderRadius="33px"
                        type="submit"
                        fontWeight="normal"
                        fontSize="14px"
                        isLoading={isSaving}
                      >
                        บันทึกข้อมูล
                      </Button>
                    </AnimatedFlex>
                  </Card>
                </AnimatedFlex>
              </Flex>
            </Form>
          )}
        </Formik>
      </Stack>
    </Flex>
  );
};

export default LiveChatSetting;
