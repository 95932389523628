import { useEffect, useState } from "react";
import { getReferenceDates } from "../../utils/DateformatUtils";
import useDateRange from "./useDateRange";
import moment from "moment/moment";

export const useSearch = (
  dateFieldsNames,
  dateSearchFormat,
  includeTypeField = false,
  initialType = "ALL",
  searchFromToday = true,
) => {
  const { dateNow, dateDayCheck } = getReferenceDates();
  const [search, setSearch] = useState({
    [dateFieldsNames.from]: moment(dateDayCheck).format(dateSearchFormat),
    [dateFieldsNames.to]: moment(dateNow).format(dateSearchFormat),
  });

  const { selectedDates, handleDateChange } = useDateRange(
    [dateDayCheck, dateNow],
    search,
    setSearch,
    dateFieldsNames,
    dateSearchFormat,
  );

  const initialSearch = {
    [dateFieldsNames.from]: searchFromToday
      ? moment(dateDayCheck).format(dateSearchFormat)
      : "",
    [dateFieldsNames.to]: searchFromToday
      ? moment(dateNow).format(dateSearchFormat)
      : "",
    ...(includeTypeField && { type: initialType }),
  };

  useEffect(() => {
    setSearch(initialSearch);
  }, []);

  return {
    search,
    setSearch,
    selectedDates,
    handleDateChange,
  };
};
