import React from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Text,
} from "@chakra-ui/react";
import { Field, Form, Formik } from "formik";
import { validateField } from "utils/CommonUtils";

const RejectWithdrawTransactionModal = ({
  isOpen,
  onClose,
  transaction,
  handleSubmit,
}) => {
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Box textAlign="left">
            <Text fontSize="20px" color="#fff">
              ยกเลิกรายการถอน
            </Text>
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Formik
            initialValues={{
              withdrawId: transaction?.id,
              status: "REJECT",
              reason: "",
            }}
            onSubmit={(values, actions) => {
              handleSubmit(values, actions, onClose);
            }}
          >
            {(props) => (
              <Form>
                <Field name="status">
                  {({ form }) => (
                    <FormControl display="flex" alignItems="left" mt={4}>
                      <Switch
                        id="status-toggle"
                        onChange={(e) => {
                          const newStatus = e.target.checked
                            ? "REJECT_N_REFUND"
                            : "REJECT";
                          form.setFieldValue("status", newStatus);
                        }}
                      />
                      <FormLabel
                        htmlFor="status-toggle"
                        mb="2"
                        textAlign="left"
                        ml={3}
                      >
                        คืนเครดิต
                      </FormLabel>
                    </FormControl>
                  )}
                </Field>

                <Field name="reason" validate={validateField}>
                  {({ field, form }) => (
                    <FormControl
                      mt={4}
                      isInvalid={form.errors.reason && form.touched.reason}
                      isRequired
                    >
                      <FormLabel>หมายเหตุ</FormLabel>
                      <Input {...field} placeholder="หมายเหตุ" fontSize="sm" />
                    </FormControl>
                  )}
                </Field>
                <ModalFooter pl={0} pr={0} mt={4}>
                  <Button
                    background="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
                    w="100%"
                    fontWeight="normal"
                    type="submit"
                    isLoading={props.isSubmitting}
                    fontSize="14px"
                  >
                    ทำรายการ
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default RejectWithdrawTransactionModal;
