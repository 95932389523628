import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Stack,
  Tag,
  TagCloseButton,
  TagLabel,
  Text,
  Textarea,
} from "@chakra-ui/react";
import AnimatedFlex from "components/AnimatedFlex/MotionFlex";
import React from "react";
import { Formik, Form, Field } from "formik";

import { Element } from "react-scroll";
import CardBody from "components/Card/CardBody";
import Card from "components/Card/Card";
import ImageUploader from "components/ImageUploader/ImageUploader";
import { useSeoSetting } from "hooks/business/website-setting/useSeoSetting";

const SeoSetting = ({ pageName }) => {
  const {
    seoDetail,
    handleSubmit,
    metaKeyword,
    setMetaKeyword,
    keyPress,
    isSubmitting,
  } = useSeoSetting();
  return (
    <Flex direction="column" overflow="hidden">
      <Stack direction="column" spacing="24px" w="100%">
        <AnimatedFlex justify="space-between" duration={0.4} delay={0.4}>
          <Text
            fontSize="24px"
            fontWeight="bold"
            color="green.500"
            mb="12px"
            mt="12px"
          >
            {pageName}
          </Text>
        </AnimatedFlex>
        <Formik
          initialValues={{
            ...seoDetail,
            pageTitle: seoDetail?.pageTitle || "",
            pageUrl: seoDetail?.pageUrl || "",
            metaTitle: seoDetail?.metaTitle || "",
            googleAnalytic: seoDetail?.googleAnalytic || "",
            metaDescription: seoDetail?.metaDescription || "",
            metaKeyword: seoDetail?.metaKeyword || [],
          }}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ setFieldValue, values }) => (
            <Form>
              <Flex direction="column">
                <AnimatedFlex duration={0.4} delay={0.6}>
                  <Card
                    w={{ sm: "100%" }}
                    alignSelf="flex-end"
                    justifySelf="flex-end"
                  >
                    <Text fontSize="18px" fontWeight="bold" pb={6} color="#fff">
                      {pageName}
                    </Text>
                    <Element id="info" name="info">
                      <CardBody>
                        <Grid
                          templateColumns={{
                            base: "1fr",
                            md: "repeat(2, 1fr)",
                          }}
                          gap="24px"
                          w="100%"
                        >
                          <GridItem>
                            <FormControl>
                              <FormLabel>Page Title</FormLabel>
                              <Field
                                as={Input}
                                name="pageTitle"
                                placeholder="Demolive99 คาสิโนออนไลน์"
                                fontSize="sm"
                              />
                            </FormControl>
                          </GridItem>
                          <GridItem>
                            <FormControl>
                              <FormLabel>Page Url</FormLabel>
                              <Field
                                as={Input}
                                name="pageUrl"
                                placeholder="http://www.demolive99.com"
                                fontSize="sm"
                              />
                            </FormControl>
                          </GridItem>
                          <GridItem>
                            <FormControl>
                              <FormLabel>Meta Title</FormLabel>
                              <Field
                                as={Input}
                                name="metaTitle"
                                placeholder="Demolive99 คาสิโนออนไลน์"
                                fontSize="sm"
                              />
                            </FormControl>
                          </GridItem>
                          <GridItem>
                            <FormControl>
                              <FormLabel>Google Analytics</FormLabel>
                              <Field
                                as={Input}
                                name="googleAnalytic"
                                placeholder="G-asdfasdf1212dfdf"
                                fontSize="sm"
                              />
                            </FormControl>
                          </GridItem>
                          <GridItem>
                            <FormControl>
                              <FormLabel>Description</FormLabel>
                              <Field
                                name="metaDescription"
                                as={Textarea}
                                placeholder="Demolive99 คาสิโนออนไลน์ เว็บตรงอันดับของไทย"
                                fontSize="sm"
                                minHeight="150px"
                                bg="#1E252C"
                                borderRadius="10px"
                              />
                            </FormControl>
                          </GridItem>
                          <GridItem>
                            <FormControl>
                              <FormLabel>Keywords</FormLabel>
                              <Flex
                                direction="row"
                                p="12px"
                                wrap="wrap"
                                borderRadius="10px"
                                border="1px solid rgba(55, 63, 72, 1)"
                                _focus={{ borderColor: "teal.300" }}
                                minH="150px"
                                cursor="text"
                                bg="#1E252C"
                              >
                                {metaKeyword.map((keyword) => {
                                  return (
                                    <Tag
                                      fontSize="sm"
                                      h="25px"
                                      mb="6px"
                                      me="6px"
                                      key={keyword.id}
                                      borderRadius="12px"
                                      // variant="outline"
                                      border="1px"
                                      backgroundColor={"#1D2229"}
                                      borderColor={"#373F48"}
                                      color={"#8C9592"}
                                    >
                                      <TagLabel w="100%">
                                        {keyword.name}
                                      </TagLabel>
                                      <TagCloseButton
                                        color="green.500"
                                        justifySelf="flex-end"
                                        onClick={() => {
                                          const newKeywords =
                                            metaKeyword.filter(
                                              (element) =>
                                                element.id !== keyword.id,
                                            );
                                          setMetaKeyword(newKeywords);
                                          setFieldValue(
                                            "metaKeyword",
                                            newKeywords,
                                          );
                                        }}
                                      />
                                    </Tag>
                                  );
                                })}
                                <Input
                                  border="none"
                                  _focus="none"
                                  p="0px"
                                  onKeyDown={(e) => keyPress(e, setFieldValue)}
                                  fontSize="sm"
                                  borderRadius="none"
                                />
                              </Flex>
                            </FormControl>
                          </GridItem>
                          <GridItem>
                            <FormControl>
                              <FormLabel>
                                Favicon{" "}
                                <Box
                                  as="span"
                                  color="green.500"
                                  fontWeight="normal"
                                >
                                  (ขนาด 48 x 48 px)
                                </Box>
                              </FormLabel>
                              <ImageUploader
                                initialImage={values.favicon}
                                onUpload={(uri) =>
                                  setFieldValue("favicon", uri)
                                }
                              />
                            </FormControl>
                          </GridItem>
                        </Grid>
                      </CardBody>
                    </Element>
                    <Flex
                      justify="flex-start"
                      pt="15px"
                      duration={0.4}
                      delay={0.8}
                      pb={6}
                    >
                      <Button
                        type="submit"
                        borderRadius="33px"
                        background="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
                        width={{ base: "100%", md: "auto" }}
                        minW={{ base: "none", md: "235px" }}
                        fontWeight="normal"
                        fontSize="14px"
                        isLoading={isSubmitting}
                        mt={4}
                      >
                        บันทึกข้อมูล
                      </Button>
                    </Flex>
                  </Card>
                </AnimatedFlex>
              </Flex>
            </Form>
          )}
        </Formik>
      </Stack>
    </Flex>
  );
};

export default SeoSetting;
