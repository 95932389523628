import { Flex, Stack, Text } from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import React, { useState } from "react";
import AnimatedFlex from "components/AnimatedFlex/MotionFlex";
import SearchTable1 from "components/Tables/SearchTable1";
import ManageMarketingModal from "./components/ManageMarketingModal";

import { marketingSettingColumnsData } from "variables/columns/system-setting/marketingSettingColumnsData";
import { useMarketingSetting } from "hooks/business/system-setting/useMarketingSetting";

const MarketingSetting = ({ pageName }) => {
  const { marketingStaffData, isMarketingStaffDataLoading, marketingLinkData } =
    useMarketingSetting();

  const [modalState, setModalState] = useState({
    manageMarketingModalOpen: false,
  });
  const [selectedMarketing, setSelectedMarketing] = useState(null);

  const handleModalOpen = (modal, marketing = null) => {
    setSelectedMarketing(marketing);
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: true,
    }));
  };

  const handleModalClose = (modal) => {
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: false,
    }));
    setSelectedMarketing(null);
  };

  return (
    <Flex direction="column">
      <Stack direction="column" spacing="15px" w="100%">
        <AnimatedFlex justify="space-between" duration={0.4} delay={0.4}>
          <Text
            fontSize="24px"
            fontWeight="bold"
            color="green.500"
            mb="12px"
            mt="12px"
          >
            {pageName}
          </Text>
        </AnimatedFlex>
        <AnimatedFlex duration={0.4} delay={0.8}>
          <Card px="22px" mb="24px">
            <CardHeader pb="12px" pt="12px">
              <Flex direction="column">
                <Text color="white" fontSize="18px" fontWeight="bold" mb="6px">
                  ตั้งค่าการตลาด
                </Text>
              </Flex>
            </CardHeader>
            <CardBody>
              {marketingLinkData && (
                <SearchTable1
                  isLoading={isMarketingStaffDataLoading}
                  tableData={marketingStaffData}
                  showAction={true}
                  handleModalOpen={handleModalOpen}
                  actionButtonText={"เพิ่มการตลาด"}
                  actionButtonIcon={null}
                  modalName={"manageMarketingModal"}
                  columnsData={marketingSettingColumnsData(
                    handleModalOpen,
                    marketingLinkData,
                  )}
                />
              )}
            </CardBody>
          </Card>
        </AnimatedFlex>
        <ManageMarketingModal
          isOpen={modalState.manageMarketingModalOpen}
          onClose={() =>
            handleModalClose("manageMarketingModal", marketingStaffData)
          }
          marketing={selectedMarketing}
        />
      </Stack>
    </Flex>
  );
};

export default MarketingSetting;
