import { Box, Flex, Spacer, Stack, Text } from "@chakra-ui/react";
import AnimatedFlex from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CustomRangePicker from "components/CustomRangePicker/CustomRangePicker";
import CardBody from "components/Card/CardBody";
import { QueryClientProvider, useQueryClient } from "react-query";
import SearchTableRemote from "components/Tables/SearchTableRemote";
import React, { useState } from "react";

import { invalidTransactionReportColumnsData } from "variables/columns/transaction-report/invalidTransactionReportColumnsData";
import RejectInvalidTransactionModal from "./components/RejectInvalidTransactionModal";
import ApproveInvalidTransactionModal from "./components/ApproveInvalidTransactionModal";
import { APP_PROFILE, DepositTransactionTypes } from "variables/system";
import { useDepositTransaction } from "hooks/business/transaction-report/useDepositTransaction";
import { QueryKeys } from "variables/queryKeys";
import { useSearch } from "hooks/common/useSearch";

const InvalidTransactionReport = ({ pageName }) => {
  const queryClient = useQueryClient();
  const [selectedTransaction, setSelectedTransaction] = useState(null);

  const dateFieldsNames = {
    from: "createdDateFrom",
    to: "createdDateTo",
  };
  const dateSearchFormat = "DD/MM/yyyy HH:mm";

  const [modalState, setModalState] = useState({
    approveInvalidTransactionModalOpen: false,
    rejectInvalidTransactionModalOpen: false,
  });

  const handleModalOpen = (modal, transaction = null) => {
    setSelectedTransaction(transaction);
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: true,
    }));
  };

  const handleModalClose = (modal) => {
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: false,
    }));
    setSelectedTransaction(null);
  };

  const role = JSON.parse(localStorage.getItem(APP_PROFILE))?.role;

  const { fetchData, downloadableData, handleInvalidTransaction } =
    useDepositTransaction(
      role,
      QueryKeys.INVALID_TRANSACTION_REPORT,
      invalidTransactionReportColumnsData,
      DepositTransactionTypes.NOT_SURE,
    );

  const { search, setSearch, selectedDates, handleDateChange } = useSearch(
    dateFieldsNames,
    dateSearchFormat,
  );

  return (
    <Flex direction="column">
      <Stack direction="column" spacing="24px" w="100%">
        <AnimatedFlex justify="space-between" duration={0.4} delay={0.4}>
          <Text
            fontSize="24px"
            fontWeight="bold"
            color="green.500"
            mb="12px"
            mt="12px"
          >
            {pageName}
          </Text>
        </AnimatedFlex>
        <AnimatedFlex duration={0.4} delay={0.6}>
          <Card px="22px" mb="24px">
            <CardHeader pb="12px" pt="12px">
              <Flex direction="column" justifyContent="center">
                <Text fontSize="18px" fontWeight="bold" mb="6px" color="#fff">
                  เช็ครายการไม่พบบัญชี
                </Text>
              </Flex>
              <Spacer />
              <Box>
                <CustomRangePicker
                  selectedDates={selectedDates}
                  onDateChange={handleDateChange}
                />
              </Box>
            </CardHeader>
            <CardBody>
              <QueryClientProvider client={queryClient}>
                <SearchTableRemote
                  query={QueryKeys.INVALID_TRANSACTION_REPORT}
                  columnsData={invalidTransactionReportColumnsData(
                    handleModalOpen,
                  )}
                  fetchData={fetchData}
                  search={search}
                  setSearch={setSearch}
                  showExport={true}
                  downloadableData={downloadableData}
                  filename={pageName}
                />
              </QueryClientProvider>
            </CardBody>
          </Card>
        </AnimatedFlex>
        <ApproveInvalidTransactionModal
          isOpen={modalState.approveInvalidTransactionModalOpen}
          onClose={() => handleModalClose("approveInvalidTransactionModal")}
          transaction={selectedTransaction}
          handleSubmit={handleInvalidTransaction}
        />
        <RejectInvalidTransactionModal
          isOpen={modalState.rejectInvalidTransactionModalOpen}
          onClose={() => handleModalClose("rejectInvalidTransactionModal")}
          transaction={selectedTransaction}
          handleSubmit={handleInvalidTransaction}
        />
      </Stack>
    </Flex>
  );
};

export default InvalidTransactionReport;
