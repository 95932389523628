import { Box, Text } from "@chakra-ui/react";
import React from "react";

export const NotificationToast = ({ title, username, amount, date }) => {
  return (
    <Box p={3} bg="green.500" borderRadius="12px">
      <Text color="#fff" fontWeight="bold">
        {title}
      </Text>
      <Text color="#fff">รหัสผู้ใช้งาน: {username}</Text>
      <Text color="#fff">จำนวนเงิน: {amount}</Text>
      <Text color="#fff">เวลา: {date}</Text>
    </Box>
  );
};
