import { useQuery } from "react-query";
import {
  fetchMarketingTopTransaction,
  fetchTopTransaction,
} from "../../../services/dashboard/dashboardService";
import { ERROR_MESSAGE, MARKETING_ROLE } from "../../../variables/system";
import { useErrorHandler } from "../../common/useErrorHandler";
import { QueryKeys } from "../../../variables/queryKeys";

export const useTopWithdraw = (startDate, endDate, role, mid = null) => {
  const handleError = useErrorHandler();
  const fetchTopWithdraw = async () => {
    const req = {
      listDateFrom: startDate,
      listDateTo: endDate,
      orderBy: "withdraw",
      mid: mid,
    };

    const response =
      role !== MARKETING_ROLE
        ? await fetchTopTransaction(req)
        : await fetchMarketingTopTransaction(req);

    return response.data.data;
  };

  const { data: topWithdraw, isLoading: isTopWithdrawLoading } = useQuery(
    QueryKeys.TOP_TRANSACTION.WITHDRAW(startDate, endDate),
    fetchTopWithdraw,
    {
      onError: (error) => handleError(error, ERROR_MESSAGE),
    },
  );

  return {
    topWithdraw,
    isTopWithdrawLoading,
  };
};
