import {
  Box,
  Button,
  Flex,
  Icon,
  SimpleGrid,
  Spacer,
  Spinner,
  Stack,
  Text,
} from "@chakra-ui/react";
import AnimatedFlex from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import React, { useState } from "react";

import ManageTrueWalletModal from "./components/ManageTrueWalletModal";

import DeleteTrueWalletModal from "./components/DeleteTrueWalletModal";
import { CreateIcon } from "components/Icons/Icons";
import TrueWalletCard from "./components/TrueWalletCard";
import { useTrueWalletSetting } from "hooks/business/finance-setting/useTrueWalletSetting";

const TrueWalletSetting = ({ pageName }) => {
  const { trueWalletData, isLoading, handleSubmit, handleDelete, isDeleting } =
    useTrueWalletSetting();

  const [modalState, setModalState] = useState({
    manageTrueWalletModalOpen: false,
    deleteTrueWalletModalOpen: false,
  });
  const [selectedTrueWallet, setSelectedTrueWallet] = useState(null);

  const handleModalOpen = (modal, user = null) => {
    setSelectedTrueWallet(user);
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: true,
    }));
  };

  const handleModalClose = (modal) => {
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: false,
    }));
    setSelectedTrueWallet(null);
  };

  return (
    <Flex direction="column">
      <Stack direction="column" spacing="15px" w="100%">
        <AnimatedFlex justify="space-between" duration={0.4} delay={0.4}>
          <Text
            fontSize="24px"
            fontWeight="bold"
            color="green.500"
            mb="12px"
            mt="12px"
          >
            {pageName}
          </Text>
        </AnimatedFlex>
        <AnimatedFlex duration={0.4} delay={0.8}>
          <Card px="22px" pb="64px">
            <CardHeader pb="32px" pt="6px">
              <Flex direction="column">
                <Text color="white" fontSize="18px" fontWeight="bold" mb="6px">
                  ตั้งค่า ทรูวอลเล็ต
                </Text>
              </Flex>
              <Spacer />
              <Button
                width={{ base: "100%", md: "auto" }}
                minW={{ base: "none", md: "150px" }}
                background="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
                variant="solid"
                borderRadius="33px"
                fontSize="14px"
                fontWeight="normal"
                onClick={() => handleModalOpen("manageTrueWalletModal")}
              >
                <Icon as={CreateIcon} mr="5px" />
                เพิ่ม ทรูวอลเล็ต
              </Button>
            </CardHeader>
            <AnimatedFlex justify="flex-end" duration={0.4} delay={1.0}>
              {isLoading ? (
                <Flex
                  alignItems="center"
                  justifyContent="center"
                  height="400px"
                  w="100%"
                >
                  <Spinner color="green.500" size="xl" />
                </Flex>
              ) : (
                <SimpleGrid
                  columns={{ base: 1, md: 2, lg: 3 }}
                  spacing={6}
                  w="100%"
                >
                  {trueWalletData
                    .slice()
                    .sort((a, b) => a.id - b.id)
                    .map((tw) => (
                      <Box key={tw.id}>
                        <TrueWalletCard
                          trueWallet={tw}
                          onEdit={() =>
                            handleModalOpen("manageTrueWalletModal", tw)
                          }
                          onDelete={() =>
                            handleModalOpen("deleteTrueWalletModal", tw)
                          }
                        />
                      </Box>
                    ))}
                </SimpleGrid>
              )}
            </AnimatedFlex>
          </Card>
        </AnimatedFlex>
        <ManageTrueWalletModal
          isOpen={modalState.manageTrueWalletModalOpen}
          onClose={() => handleModalClose("manageTrueWalletModal")}
          trueWallet={selectedTrueWallet}
          handleSubmit={handleSubmit}
        />
        <DeleteTrueWalletModal
          isOpen={modalState.deleteTrueWalletModalOpen}
          onClose={() => handleModalClose("deleteTrueWalletModal")}
          trueWallet={selectedTrueWallet}
          handleDelete={handleDelete}
          isDeleting={isDeleting}
        />
      </Stack>
    </Flex>
  );
};

export default TrueWalletSetting;
