import React from "react";
import { Flex, Spinner, Text } from "@chakra-ui/react";
import SimpleTable from "components/Tables/SimpleTable";

import { gameSettingColumnsData } from "variables/columns/system-setting/gameSettingColumnsData";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";

const ProviderCard = ({ title, providerData, isLoading, handleToggle }) => {
  return (
    <Card p={0} boxShadow="none">
      <CardHeader pt="12px">
        <Flex direction="column">
          <Text color="white" fontSize="18px" fontWeight="bold" mb="6px">
            รายชื่อค่าย - {title}
          </Text>
        </Flex>
      </CardHeader>
      <CardBody h="375px">
        {isLoading ? (
          <Flex
            alignItems="center"
            justifyContent="center"
            height="400px"
            w="100%"
          >
            <Spinner color="green.500" size="xl" />
          </Flex>
        ) : (
          <SimpleTable
            tableData={providerData}
            columnsData={gameSettingColumnsData(handleToggle)}
            rowSize={providerData?.length}
          />
        )}
      </CardBody>
    </Card>
  );
};

export default ProviderCard;
