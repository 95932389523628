import { Badge, Flex, Text } from "@chakra-ui/react";
import { formatDate } from "../../../utils/DateformatUtils";
import { getJackpotBackground } from "../../../utils/CommonUtils";

export const jackpotReportColumnsData = [
  {
    Header: "ประเภท",
    accessor: "type",
    textAlign: "left",
    justifyContent: "flex-start",
    Cell: ({ cell }) => (
      <Flex align="center">
        <Badge
          bg={getJackpotBackground(cell.value)}
          w="65px"
          h="28px"
          borderRadius="15px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {cell.value.toUpperCase()}
        </Badge>
      </Flex>
    ),
  },
  {
    Header: "ยูสเซอร์เนม",
    accessor: "username",
    disableSortBy: true,
    textAlign: "left",
    justifyContent: "flex-start",
  },
  {
    Header: "มูลค่า",
    accessor: "amount",
    textAlign: "right",
    justifyContent: "flex-end",
    Cell: ({ value }) => (
      <Text color="#fff" fontWeight="bold">
        {value}
      </Text>
    ),
  },
  {
    Header: "เครดิตก่อนได้รับ",
    accessor: "beforeAmount",
    textAlign: "right",
    justifyContent: "flex-end",
  },
  {
    Header: "เครดิตหลังได้รับ",
    accessor: "afterAmount",
    textAlign: "right",
    justifyContent: "flex-end",
    Cell: ({ value }) => (
      <Text color="#fff" fontWeight="bold">
        {value}
      </Text>
    ),
  },
  {
    Header: "ยอดเทินโอเวอร์",
    accessor: "turnOver",
    textAlign: "right",
    justifyContent: "flex-end",
    Cell: ({ value }) => (
      <Text color="#fff" fontWeight="bold">
        {value}
      </Text>
    ),
  },
  {
    Header: "แจกโดย",
    accessor: "admin",
    textAlign: "left",
    justifyContent: "flex-start",
  },
  {
    Header: "วันที่ทำรายการ",
    accessor: "createdDate",
    textAlign: "left",
    justifyContent: "flex-start",
    Cell: ({ value }) => formatDate(value),
  },
];
