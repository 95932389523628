import { useMutation, useQuery, useQueryClient } from "react-query";
import { QueryKeys } from "../../../variables/queryKeys";
import { ERROR_MESSAGE } from "../../../variables/system";
import {
  addFooterIcon,
  deleteFooterIcon,
  fetchFooterIcons,
  fetchFooterSetting,
  updateFooterIcon,
  updateFooterSetting,
} from "../../../services/website-setting/footerService";
import { useErrorHandler } from "../../common/useErrorHandler";
import { useSuccessHandler } from "../../common/useSuccessHandler";

export const useFooterSetting = () => {
  const handleError = useErrorHandler();
  const handleSuccess = useSuccessHandler();
  const queryClient = useQueryClient();

  const { data: footerSettingData, isLoading: isFooterSettingLoading } =
    useQuery(
      [QueryKeys.FOOTER_SETTING],
      async () => {
        const response = await fetchFooterSetting();
        return response.data.data;
      },
      {
        onError: (error) => handleError(error, ERROR_MESSAGE),
      },
    );

  const { data: footerIconsData, isLoading: isFooterIconsLoading } = useQuery(
    [QueryKeys.FOOTER_ICONS],
    async () => {
      const response = await fetchFooterIcons();
      return response.data.data;
    },
    {
      onError: (error) => handleError(error, ERROR_MESSAGE),
    },
  );

  const mutation = useMutation((payload) => updateFooterSetting(payload), {
    onSuccess: (response) => {
      handleSuccess(response.data.message);
      queryClient.invalidateQueries(QueryKeys.FOOTER_SETTING);
    },
    onError: (error) => {
      handleError(error, ERROR_MESSAGE);
    },
  });

  const saveFooterIconMutation = useMutation(
    async ({ values, icon }) => {
      const payload = icon ? { ...icon, ...values } : values;
      return icon ? updateFooterIcon(payload) : addFooterIcon(payload);
    },
    {
      onSuccess: (response) => {
        handleSuccess(response.data.message);
        queryClient.invalidateQueries([QueryKeys.FOOTER_ICONS]);
      },
      onError: (error) => handleError(error, ERROR_MESSAGE),
    },
  );

  const deleteFooterIconMutation = useMutation(
    async (id) => await deleteFooterIcon(id),
    {
      onSuccess: (response) => {
        handleSuccess(response.data.message);
        queryClient.invalidateQueries([QueryKeys.FOOTER_ICONS]);
      },
      onError: (error) => handleError(error, ERROR_MESSAGE),
    },
  );

  const handleSave = (payload) => {
    mutation.mutate(payload);
  };

  const handleSaveFooterIcon = (values, icon, actions, onClose) => {
    saveFooterIconMutation.mutate(
      { values, icon },
      {
        onSettled: () => {
          actions.setSubmitting(false);
          onClose();
        },
      },
    );
  };

  const handleDeleteFooterIcon = (id) => {
    deleteFooterIconMutation.mutate(id);
  };

  return {
    footerSettingData,
    footerIconsData,
    isFooterIconsLoading,
    isFooterSettingLoading,
    handleSave,
    handleSaveFooterIcon,
    handleDeleteFooterIcon,
    isSaving: mutation.isLoading,
    isFooterIconSaving: saveFooterIconMutation.isLoading,
    isFooterIconDeleting: deleteFooterIconMutation.isLoading,
  };
};
