import { Box, Flex, Spacer, Stack, Text } from "@chakra-ui/react";
import AnimatedFlex from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CustomRangePicker from "components/CustomRangePicker/CustomRangePicker";
import CardBody from "components/Card/CardBody";
import React from "react";
import { affiliateReportColumnsData } from "variables/columns/member-report/affiliateReportColumnsData";
import SearchTable1 from "components/Tables/SearchTable1";
import { useAffiliateReport } from "hooks/business/member-report/useAffiliateReport";
import { useSearch } from "hooks/common/useSearch";

const AffiliateReport = ({ pageName }) => {
  const dateFieldsNames = {
    from: "listDateFrom",
    to: "listDateTo",
  };
  const dateSearchFormat = "DD/MM/yyyy HH:mm:ss";

  const { search, selectedDates, handleDateChange } = useSearch(
    dateFieldsNames,
    dateSearchFormat,
  );
  const { data: affiliateReportData, isLoading } = useAffiliateReport(search);

  return (
    <Flex direction="column">
      <Stack direction="column" spacing="24px" w="100%">
        <AnimatedFlex justify="space-between" duration={0.4} delay={0.4}>
          <Text
            fontSize="24px"
            fontWeight="bold"
            color="green.500"
            mb="12px"
            mt="12px"
          >
            {pageName}
          </Text>
        </AnimatedFlex>
        <AnimatedFlex duration={0.4} delay={0.6}>
          <Card px="22px" mb="24px">
            <CardHeader pb="12px" pt="12px">
              <Flex direction="column">
                <Text fontSize="18px" fontWeight="bold" mb="6px" color="#fff">
                  รายงานแนะนำเพื่อน
                </Text>
              </Flex>
              <Spacer />
              <Box>
                <CustomRangePicker
                  selectedDates={selectedDates}
                  onDateChange={handleDateChange}
                />
              </Box>
            </CardHeader>
            <CardBody>
              <SearchTable1
                isLoading={isLoading}
                tableData={affiliateReportData}
                columnsData={affiliateReportColumnsData()}
                hideSearch={false}
              />
            </CardBody>
          </Card>
        </AnimatedFlex>
      </Stack>
    </Flex>
  );
};

export default AffiliateReport;
