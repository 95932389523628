import { Box, Flex, Spacer, Stack, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";

import "assets/css/styles.css";
import AnimatedFlex from "components/AnimatedFlex/MotionFlex";
import { QueryClientProvider, useQueryClient } from "react-query";
import CustomRangePicker from "components/CustomRangePicker/CustomRangePicker";

import SearchTableRemote from "components/Tables/SearchTableRemote";

import { withdrawTransactionColumnsData } from "variables/columns/transaction-report/withdrawTransactionReportColumnsData";
import RejectWithdrawTransactionModal from "./components/RejectWithdrawTransactionModal";
import ManualWithdrawTransactionModal from "./components/ManualWithdrawTransactionModal";
import UpdateWithdrawTransactionStatusModal from "./components/UpdateWithdrawTransactionStatusModal";
import ApproveWithdrawBlockAutoTransactionModal from "./components/ApproveWithdrawBlockAutoTransactionModal";
import RefreshWithdrawErrorTransactionModal from "./components/RefreshWithdrawErrorTransactionModal";
import { APP_PROFILE } from "variables/system";
import { useSearch } from "hooks/common/useSearch";
import { useWithdrawTransaction } from "hooks/business/transaction-report/useWithdrawTransaction";
import { QueryKeys } from "../../../variables/queryKeys";

const WithdrawTransactionReport = ({ pageName }) => {
  const queryClient = useQueryClient();

  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const role = JSON.parse(localStorage.getItem(APP_PROFILE))?.role;

  const dateFieldsNames = {
    from: "createdDateFrom",
    to: "createdDateTo",
  };
  const dateSearchFormat = "DD/MM/yyyy HH:mm";

  const searchOptions = [
    {
      label: "all",
      value: "",
    },
    {
      label: "success",
      value: "SUCCESS",
    },
    {
      label: "inprogress",
      value: "PENDING",
    },
    {
      label: "error",
      value: "ERROR",
    },
    {
      label: "reject",
      value: "REJECT",
    },
    {
      label: "rejectAndRefund",
      value: "REJECT_N_REFUND",
    },
    {
      label: "blockAuto",
      value: "BLOCK_AUTO",
    },
  ];

  const { search, setSearch, selectedDates, handleDateChange } = useSearch(
    dateFieldsNames,
    dateSearchFormat,
  );

  const {
    fetchData,
    downloadableData,
    handleSaveTransaction,
    handleBlockAutoTransaction,
    handleRefreshErrorTransaction,
  } = useWithdrawTransaction(role);

  const handleOptionChange = (selectedOption) => {
    setSearch({
      ...search,
      status: selectedOption ? selectedOption.value : null,
    });
  };

  const [modalState, setModalState] = useState({
    approveWithdrawBlockAutoTransactionModalOpen: false,
    refreshWithdrawErrorTransactionModalOpen: false,
    rejectWithdrawTransactionModalOpen: false,
    manualWithdrawTransactionModalOpen: false,
    updateWithdrawTransactionStatusModalOpen: false,
  });

  const handleModalOpen = (modal, transaction = null) => {
    setSelectedTransaction(transaction);
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: true,
    }));
  };

  const handleModalClose = (modal) => {
    setModalState((prevState) => ({
      ...prevState,
      [`${modal}Open`]: false,
    }));
    setSelectedTransaction(null);
  };

  return (
    <Flex direction="column">
      <Stack direction="column" spacing="24px" w="100%">
        <AnimatedFlex justify="space-between" duration={0.4} delay={0.4}>
          <Text
            fontSize="24px"
            fontWeight="bold"
            color="green.500"
            mb="12px"
            mt="12px"
          >
            {pageName}
          </Text>
        </AnimatedFlex>
        <AnimatedFlex duration={0.4} delay={0.6}>
          <Card px="22px" mb="24px">
            <CardHeader pb="12px" pt="12px">
              <Flex direction="column" justifyContent="center">
                <Text fontSize="18px" fontWeight="bold" mb="6px" color="#fff">
                  เช็คข้อมูลถอน
                </Text>
              </Flex>
              <Spacer />
              <Box>
                <CustomRangePicker
                  selectedDates={selectedDates}
                  onDateChange={handleDateChange}
                />
              </Box>
            </CardHeader>
            <CardBody>
              <QueryClientProvider client={queryClient}>
                <SearchTableRemote
                  query={QueryKeys.WITHDRAW_TRANSACTION_REPORT}
                  columnsData={withdrawTransactionColumnsData(handleModalOpen)}
                  fetchData={fetchData}
                  search={search}
                  setSearch={setSearch}
                  showExport={true}
                  downloadableData={downloadableData}
                  filename={pageName}
                  showSearchableDropdown={true}
                  onChange={handleOptionChange}
                  searchOptions={searchOptions}
                />
              </QueryClientProvider>
            </CardBody>
          </Card>
        </AnimatedFlex>
        <ApproveWithdrawBlockAutoTransactionModal
          isOpen={modalState.approveWithdrawBlockAutoTransactionModalOpen}
          onClose={() =>
            handleModalClose("approveWithdrawBlockAutoTransactionModal")
          }
          transaction={selectedTransaction}
          handleSubmit={handleBlockAutoTransaction}
        />
        <RefreshWithdrawErrorTransactionModal
          isOpen={modalState.refreshWithdrawErrorTransactionModalOpen}
          onClose={() =>
            handleModalClose("refreshWithdrawErrorTransactionModal")
          }
          transaction={selectedTransaction}
          handleSubmit={handleRefreshErrorTransaction}
        />
        <RejectWithdrawTransactionModal
          isOpen={modalState.rejectWithdrawTransactionModalOpen}
          onClose={() => handleModalClose("rejectWithdrawTransactionModal")}
          transaction={selectedTransaction}
          handleSubmit={handleBlockAutoTransaction}
        />
        <ManualWithdrawTransactionModal
          isOpen={modalState.manualWithdrawTransactionModalOpen}
          onClose={() => handleModalClose("manualWithdrawTransactionModal")}
          transaction={selectedTransaction}
          handleSubmit={handleBlockAutoTransaction}
        />
        <UpdateWithdrawTransactionStatusModal
          isOpen={modalState.updateWithdrawTransactionStatusModalOpen}
          onClose={() =>
            handleModalClose("updateWithdrawTransactionStatusModal")
          }
          transaction={selectedTransaction}
          handleSubmit={handleSaveTransaction}
        />
      </Stack>
    </Flex>
  );
};

export default WithdrawTransactionReport;
