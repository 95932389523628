import { Flex, Stack, Text } from "@chakra-ui/react";
import React from "react";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";

import AnimatedFlex from "components/AnimatedFlex/MotionFlex";

import { QueryClientProvider, useQueryClient } from "react-query";
import SearchTableRemote from "components/Tables/SearchTableRemote";
import { newMemberTodayColumnsData } from "variables/columns/member-report/newMemberTodayColumnsData";

import { APP_PROFILE, MembersSearchTypes } from "variables/system";
import { useSearch } from "hooks/common/useSearch";
import { QueryKeys } from "variables/queryKeys";
import { useMemberReport } from "hooks/business/member-report/useMemberReport";

const NewMemberTodayReport = ({ pageName }) => {
  const queryClient = useQueryClient();

  const dateFieldsNames = {
    from: "createdDateFrom",
    to: "createdDateTo",
  };
  const dateSearchFormat = "DD/MM/yyyy HH:mm";

  const role = JSON.parse(localStorage.getItem(APP_PROFILE))?.role;
  const { fetchData, downloadableData } = useMemberReport(
    role,
    MembersSearchTypes.ALL,
    newMemberTodayColumnsData,
  );
  const { search, setSearch } = useSearch(dateFieldsNames, dateSearchFormat);

  return (
    <Flex direction="column" overflow="hidden">
      <Stack direction="column" spacing="15px" w="100%">
        <AnimatedFlex justify="space-between" duration={0.4} delay={0.4}>
          <Text
            fontSize="24px"
            fontWeight="bold"
            color="green.500"
            mb="12px"
            mt="12px"
          >
            {pageName}
          </Text>
        </AnimatedFlex>
        <AnimatedFlex duration={0.4} delay={0.8}>
          <Card px="22px" pb="38px">
            <CardHeader pb="12px" pt="12px">
              <Flex direction="column" justifyContent="center">
                <Text color="white" fontSize="18px" fontWeight="bold" mb="6px">
                  สมาชิกใหม่ทั้งหมด
                </Text>
              </Flex>
            </CardHeader>
            <CardBody>
              <QueryClientProvider client={queryClient}>
                <SearchTableRemote
                  query={QueryKeys.NEW_MEMBER_TODAY_REPORT}
                  columnsData={newMemberTodayColumnsData()}
                  fetchData={fetchData}
                  search={search}
                  setSearch={setSearch}
                  showExport={true}
                  downloadableData={downloadableData}
                  filename={pageName}
                />
              </QueryClientProvider>
            </CardBody>
          </Card>
        </AnimatedFlex>
      </Stack>
    </Flex>
  );
};

export default NewMemberTodayReport;
