import React from "react";
import {
  Button,
  Flex,
  FormControl,
  Grid,
  GridItem,
  Stack,
  Text,
  Select,
  NumberInput,
  NumberInputField,
  Switch,
  IconButton,
  NumberIncrementStepper,
  NumberDecrementStepper,
  NumberInputStepper,
  Box,
  FormLabel,
} from "@chakra-ui/react";
import AnimatedFlex from "components/AnimatedFlex/MotionFlex";
import { Element } from "react-scroll";
import CardBody from "components/Card/CardBody";
import Card from "components/Card/Card";
import { Formik, Form, FieldArray } from "formik";

import { TYPEAFFILIATE, BONUS_OPTIONS } from "variables/system";
import CardHeader from "components/Card/CardHeader";
import { DeleteIcon } from "components/Icons/Icons";
import { useAffiliateSetting } from "hooks/business/system-setting/useAffiliateSetting";

const AffiliateSetting = ({ pageName }) => {
  const { affiliateData, handleSubmit, isSaving } = useAffiliateSetting();
  return (
    <Flex direction="column">
      <Stack direction="column" spacing="24px" w="100%">
        <AnimatedFlex justify="space-between" duration={0.4} delay={0.4}>
          <Text
            fontSize="24px"
            fontWeight="bold"
            color="green.500"
            mb="12px"
            mt="12px"
          >
            {pageName}
          </Text>
        </AnimatedFlex>
        <Card pb="64px">
          <CardHeader pb="32px" pt="6px">
            <Text fontSize="18px" fontWeight="bold" color="#fff">
              จัดการตั้งค่าแนะนำเพื่อน
            </Text>
          </CardHeader>
          <Formik
            initialValues={{
              id: affiliateData?.id || "",
              typeAffiliate: affiliateData?.typeAffiliate || "",
              typeBonus: affiliateData?.typeBonus || "",
              maxBonus: affiliateData?.maxBonus || "",
              maxWithdraw: affiliateData?.maxWithdraw || "",
              maxWallet: affiliateData?.maxWallet || "",
              img: affiliateData?.img || "",
              active: affiliateData?.active || false,
              forever: affiliateData?.forever || false,
              conditions: affiliateData?.conditions || [
                { minTopup: "", maxTopup: "", bonus: "" },
              ],
            }}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ values, handleChange, setFieldValue }) => (
              <Form>
                <Flex direction="column">
                  <Stack direction="column" spacing="24px" w="100%">
                    <AnimatedFlex duration={0.4} delay={0.6}>
                      <Card
                        w={{ sm: "100%" }}
                        alignSelf="flex-end"
                        justifySelf="flex-end"
                        p={0}
                        borderBottom="1px solid #373F48"
                        boxShadow="none"
                        pb="48px"
                        borderRadius="0px"
                      >
                        <Element id="info" name="info">
                          <CardBody>
                            <Grid
                              templateColumns={{
                                base: "1fr",
                                sm: "repeat(2, 1fr)",
                                md: "repeat(3, 1fr)",
                              }}
                              gap="24px"
                              w="100%"
                            >
                              <GridItem>
                                <Flex>
                                  <Switch
                                    name="active"
                                    isChecked={values.active}
                                    onChange={handleChange}
                                  />
                                  <FormLabel mb="10px" ml="36px">
                                    สถานะ
                                  </FormLabel>
                                </Flex>
                              </GridItem>
                              <GridItem>
                                <Flex>
                                  <Switch
                                    name="forever"
                                    isChecked={values.forever}
                                    onChange={handleChange}
                                  />
                                  <FormLabel mb="10px" ml="36px">
                                    ให้ทุกครั้ง
                                  </FormLabel>
                                </Flex>
                              </GridItem>
                              <GridItem />
                              <GridItem>
                                <FormControl>
                                  <FormLabel>ประเภท แนะนำเพื่อน</FormLabel>
                                  <Select
                                    name="typeAffiliate"
                                    value={values.typeAffiliate}
                                    onChange={handleChange}
                                    fontSize="sm"
                                    borderRadius="33px"
                                  >
                                    {TYPEAFFILIATE.map((item, index) => (
                                      <option key={index} value={item.typeCode}>
                                        {item.typeName}
                                      </option>
                                    ))}
                                  </Select>
                                </FormControl>
                              </GridItem>
                              <GridItem>
                                <FormControl>
                                  <FormLabel>ประเภทโบนัส</FormLabel>
                                  <Select
                                    name="typeBonus"
                                    value={values.typeBonus}
                                    onChange={handleChange}
                                    fontSize="sm"
                                    borderRadius="33px"
                                  >
                                    {BONUS_OPTIONS.map((item, index) => (
                                      <option key={index} value={item.typeCode}>
                                        {item.typeName}
                                      </option>
                                    ))}
                                  </Select>
                                </FormControl>
                              </GridItem>
                              <GridItem>
                                <FormControl>
                                  <FormLabel>โบนัสสูงสุด</FormLabel>
                                  <NumberInput
                                    value={values.maxBonus}
                                    onChange={(valueString) =>
                                      setFieldValue("maxBonus", valueString)
                                    }
                                  >
                                    <NumberInputField
                                      name="maxBonus"
                                      placeholder="eg. 1000"
                                      fontSize="sm"
                                    />
                                    <NumberInputStepper>
                                      <NumberIncrementStepper />
                                      <NumberDecrementStepper />
                                    </NumberInputStepper>
                                  </NumberInput>
                                </FormControl>
                              </GridItem>
                              <GridItem>
                                <FormControl>
                                  <FormLabel>
                                    จำนวนยอดถอน{" "}
                                    <Box
                                      as="span"
                                      color="green.500"
                                      fontWeight="normal"
                                    >
                                      (สูงสุดกี่เท่า)
                                    </Box>
                                  </FormLabel>
                                  <NumberInput
                                    value={values.maxWithdraw}
                                    onChange={(valueString) =>
                                      setFieldValue("maxWithdraw", valueString)
                                    }
                                  >
                                    <NumberInputField
                                      name="maxWithdraw"
                                      placeholder="eg. 5"
                                      fontSize="sm"
                                    />
                                    <NumberInputStepper>
                                      <NumberIncrementStepper />
                                      <NumberDecrementStepper />
                                    </NumberInputStepper>
                                  </NumberInput>
                                </FormControl>
                              </GridItem>
                              <GridItem>
                                <FormControl>
                                  <FormLabel>
                                    จำนวนเงิน point สะสมสูงสุด
                                  </FormLabel>
                                  <NumberInput
                                    value={values.maxWallet}
                                    onChange={(valueString) =>
                                      setFieldValue("maxWallet", valueString)
                                    }
                                  >
                                    <NumberInputField
                                      name="maxWallet"
                                      placeholder="eg. 20000"
                                      fontSize="sm"
                                    />
                                    <NumberInputStepper>
                                      <NumberIncrementStepper />
                                      <NumberDecrementStepper />
                                    </NumberInputStepper>
                                  </NumberInput>
                                </FormControl>
                              </GridItem>
                            </Grid>
                          </CardBody>
                        </Element>
                      </Card>
                    </AnimatedFlex>
                    <FieldArray name="conditions">
                      {({ push, remove }) => (
                        <>
                          <AnimatedFlex
                            justify="space-between"
                            align="center"
                            w="100%"
                            duration={0.4}
                            delay={0.8}
                          >
                            <FormLabel
                              fontSize="lg"
                              fontWeight="bold"
                              color="#fff"
                            >
                              เงื่อนไข
                            </FormLabel>
                            <Button
                              variant="outline"
                              borderColor={"green.500"}
                              color={"#fff"}
                              bg={"#1E252C"}
                              size="md"
                              borderRadius="33px"
                              fontWeight="normal"
                              onClick={() =>
                                push({
                                  minTopup: "",
                                  maxTopup: "",
                                  bonus: "",
                                })
                              }
                            >
                              เพิ่มเงื่อนไข
                            </Button>
                          </AnimatedFlex>
                          <AnimatedFlex duration={0.4} delay={1.0}>
                            <Card
                              w={{ sm: "100%" }}
                              alignSelf="flex-end"
                              justifySelf="flex-end"
                              p={0}
                              boxShadow="none"
                            >
                              <Element id="info" name="info">
                                <CardBody>
                                  <Grid
                                    templateColumns={{
                                      base: "1fr",
                                      sm: "repeat(2, 1fr) auto",
                                      md: "repeat(3, 1fr) auto",
                                    }}
                                    gap="10px"
                                    w="100%"
                                  >
                                    <GridItem
                                      colSpan={{ base: 1, sm: 3, md: 4 }}
                                    >
                                      <Grid
                                        templateColumns={{
                                          base: "1fr",
                                          sm: "repeat(2, 1fr) auto",
                                          md: "repeat(3, 1fr) auto",
                                        }}
                                        pr={8}
                                      >
                                        <GridItem
                                          display="flex"
                                          alignItems="center"
                                        >
                                          <Text>ต่ำสุด</Text>
                                        </GridItem>
                                        <GridItem
                                          display="flex"
                                          alignItems="center"
                                        >
                                          <Text>สูงสุด</Text>
                                        </GridItem>
                                        <GridItem
                                          display="flex"
                                          alignItems="center"
                                        >
                                          <Text>โบนัส</Text>
                                        </GridItem>
                                        <GridItem />
                                      </Grid>
                                    </GridItem>
                                    {values.conditions.map(
                                      (condition, index) => (
                                        <React.Fragment key={index}>
                                          <GridItem
                                            colSpan={{ base: 1, sm: 1, md: 1 }}
                                          >
                                            <FormControl>
                                              <NumberInput
                                                value={condition.minTopup}
                                                onChange={(valueString) =>
                                                  setFieldValue(
                                                    `conditions.${index}.minTopup`,
                                                    valueString,
                                                  )
                                                }
                                              >
                                                <NumberInputField
                                                  name={`conditions.${index}.minTopup`}
                                                  placeholder="eg. 10000"
                                                  fontSize="sm"
                                                />
                                                <NumberInputStepper>
                                                  <NumberIncrementStepper />
                                                  <NumberDecrementStepper />
                                                </NumberInputStepper>
                                              </NumberInput>
                                            </FormControl>
                                          </GridItem>
                                          <GridItem
                                            colSpan={{ base: 1, sm: 1, md: 1 }}
                                          >
                                            <FormControl>
                                              <NumberInput
                                                value={condition.maxTopup}
                                                onChange={(valueString) =>
                                                  setFieldValue(
                                                    `conditions.${index}.maxTopup`,
                                                    valueString,
                                                  )
                                                }
                                              >
                                                <NumberInputField
                                                  name={`conditions.${index}.maxTopup`}
                                                  placeholder="eg. 1000000"
                                                  fontSize="sm"
                                                />
                                                <NumberInputStepper>
                                                  <NumberIncrementStepper />
                                                  <NumberDecrementStepper />
                                                </NumberInputStepper>
                                              </NumberInput>
                                            </FormControl>
                                          </GridItem>
                                          <GridItem
                                            colSpan={{ base: 1, sm: 1, md: 1 }}
                                          >
                                            <FormControl>
                                              <NumberInput
                                                value={condition.bonus}
                                                onChange={(valueString) =>
                                                  setFieldValue(
                                                    `conditions.${index}.bonus`,
                                                    valueString,
                                                  )
                                                }
                                              >
                                                <NumberInputField
                                                  name={`conditions.${index}.bonus`}
                                                  placeholder="eg. 1"
                                                  fontSize="sm"
                                                />
                                                <NumberInputStepper>
                                                  <NumberIncrementStepper />
                                                  <NumberDecrementStepper />
                                                </NumberInputStepper>
                                              </NumberInput>
                                            </FormControl>
                                          </GridItem>
                                          <GridItem
                                            colSpan={1}
                                            display="flex"
                                            justifyContent="center"
                                            alignItems="end"
                                          >
                                            <IconButton
                                              variant="outline"
                                              borderColor={"red.500"}
                                              color={"red.500"}
                                              bg="#1E252C"
                                              aria-label="delete"
                                              size="md"
                                              icon={<DeleteIcon />}
                                              onClick={() => remove(index)}
                                            />
                                          </GridItem>
                                        </React.Fragment>
                                      ),
                                    )}
                                  </Grid>
                                </CardBody>
                              </Element>
                            </Card>
                          </AnimatedFlex>
                        </>
                      )}
                    </FieldArray>
                    <AnimatedFlex
                      justify="flex-start"
                      pt="15px"
                      duration={0.4}
                      delay={1.0}
                    >
                      <Button
                        type="submit"
                        width={{ base: "100%", md: "auto" }}
                        minW={{ base: "none", md: "150px" }}
                        background="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
                        borderRadius="33px"
                        fontSize="14px"
                        fontWeight="normal"
                        isLoading={isSaving}
                      >
                        บันทึกข้อมูล
                      </Button>
                    </AnimatedFlex>
                  </Stack>
                </Flex>
              </Form>
            )}
          </Formik>
        </Card>
      </Stack>
    </Flex>
  );
};
export default AffiliateSetting;
