import { Button, Flex, FormControl, Input, Text } from "@chakra-ui/react";

import React, { useEffect, useState } from "react";
import { logout } from "../../utils/APIUtils";

import Card from "../../components/Card/Card";
import { useLogin } from "hooks/business/auth/useLogin";

function SignIn() {
  const [prefix, setPrefix] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const { handleLogin, isLoading } = useLogin();

  const onSubmit = () => {
    handleLogin(username, password, prefix);
  };

  useEffect(() => {
    logout();
  }, []);

  return (
    <Flex
      direction="column"
      alignSelf="center"
      justifySelf="center"
      overflow="hidden"
      w="100vw"
      h="100vh"
      alignItems="center"
      justifyContent="center"
    >
      <Flex alignItems="center" justifyContent="center" mb="60px" mt="20px">
        <Card
          direction="column"
          w="540px"
          background="rgba(6, 9, 13, 0.8)"
          borderRadius="16px"
          p="50px 75px"
          mx={{ base: "100px" }}
          bg="gray.700"
          boxShadow="0 20px 27px 0 rgb(0 0 0 / 5%)"
        >
          <Text
            fontSize="18px"
            color="#fff"
            fontWeight="bold"
            textAlign="center"
            mb="8px"
          >
            LOG IN TO DASHBOARD
          </Text>
          <Text
            fontSize="14px"
            color="rgba(140, 149, 146, 1)"
            textAlign="center"
            mb="32px"
          >
            Please enter your username / password for sign in
          </Text>
          <FormControl>
            <Input
              fontSize="sm"
              ms="4px"
              type="text"
              placeholder="Prefix"
              mb="12px"
              size="lg"
              onChange={(e) => setPrefix(e.target.value)}
              value={prefix}
              borderRadius="33px"
            />
            <Input
              fontSize="sm"
              ms="4px"
              type="text"
              placeholder="Username"
              mb="12px"
              size="lg"
              onChange={(e) => setUsername(e.target.value)}
              value={username}
              borderRadius="33px"
            />
            <Input
              fontSize="sm"
              ms="4px"
              type="password"
              placeholder="Password"
              mb="24px"
              size="lg"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              borderRadius="33px"
            />
            <Button
              type="submit"
              bg="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
              boxShadow="0px 4px 8px 0px rgba(49, 170, 147, 0.2)"
              fontSize="sm"
              color="#fff"
              fontWeight="bold"
              w="100%"
              h="45"
              mb="24px"
              onClick={onSubmit}
              isLoading={isLoading}
            >
              Log in
            </Button>
          </FormControl>
          <Flex
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            maxW="100%"
            mt="0px"
          >
            <Text
              color="#fff"
              fontWeight="medium"
              textAlign="center"
              fontSize="sm"
            >
              2024 ©️ ASGBET All Right Reserved.
            </Text>
            <Text
              color="#fff"
              fontWeight="medium"
              textAlign="center"
              fontSize="sm"
            >
              Version (1.0.0)
            </Text>
          </Flex>
        </Card>
      </Flex>
    </Flex>
  );
}

export default SignIn;
