import { Badge, Flex, IconButton, Text, useClipboard } from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import { formatDate } from "utils/DateformatUtils";
import { getCommissionTypeNiceName } from "utils/CommonUtils";
import { CopyIcon } from "../../../components/Icons/Icons";

export const commissionReportColumnsData = () => {
  return [
    {
      Header: "สถานะ",
      accessor: "status",
      textAlign: "left",
      justifyContent: "flex-start",
      Cell: ({ cell }) => (
        <Flex align="center" justify="left">
          <Badge
            bg={cell.value === "SUCCESS" ? "green.500" : "red.500"}
            color="#fff"
            w="65px"
            h="28px"
            borderRadius="15px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            fontWeight="normal"
            fontSize="12px"
          >
            {cell.value === "SUCCESS" ? "สำเร็จ" : "ยกเลิก"}
          </Badge>
        </Flex>
      ),
    },
    {
      Header: "ยูสเซอร์",
      accessor: "username",
      textAlign: "left",
      justifyContent: "flex-start",
      Cell: ({ row }) => {
        const history = useHistory();
        const { onCopy } = useClipboard(row.original.username);

        return (
          <Flex align="center">
            <IconButton
              icon={<CopyIcon color="green.500" />}
              _hover={{ boxShadow: "none", bg: "none" }}
              // _selected={{ boxShadow: "none", bg: "none" }}
              onClick={onCopy}
              aria-label="Copy link"
              variant="outline"
              size="sm"
              border="none"
              mr={2}
            />
            <Text
              color={row.original.memberTagsColor}
              cursor="pointer"
              _hover={{ textDecoration: "underline" }}
              onClick={() =>
                history.push(`/admin/member/${row.original.username}`)
              }
            >
              {row.original.username}
            </Text>
          </Flex>
        );
      },
    },
    {
      Header: "ยอด",
      accessor: "amount",
      textAlign: "right",
      justifyContent: "flex-end",
      Cell: ({ value }) => (
        <Text color="#fff" fontWeight="bold">
          {value}
        </Text>
      ),
    },
    {
      Header: "ประเภท",
      accessor: "type",
      textAlign: "right",
      justifyContent: "flex-end",
      Cell: ({ value }) => <Text>{getCommissionTypeNiceName(value)}</Text>,
    },
    {
      Header: "หมายเหตุ",
      accessor: "reason",
      textAlign: "left",
      justifyContent: "flex-start",
    },
    {
      Header: "วันที่ทำรายการ",
      accessor: "createdDate",
      textAlign: "left",
      justifyContent: "flex-start",
      Cell: ({ value }) => formatDate(value),
    },
  ];
};
