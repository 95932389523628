import { Box, Flex, Spacer, Stack, Text } from "@chakra-ui/react";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import React from "react";
import AnimatedFlex from "components/AnimatedFlex/MotionFlex";

import { staffLoginHistoryColumnsData } from "variables/columns/website-report/staffLoginHistoryColumnsData";
import CustomRangePicker from "components/CustomRangePicker/CustomRangePicker";
import { QueryClient, QueryClientProvider } from "react-query";
import SearchTableRemote from "components/Tables/SearchTableRemote";
import { useSearch } from "hooks/common/useSearch";
import { useStaffLoginReport } from "hooks/business/website-report/useStaffLoginReport";
import { QueryKeys } from "variables/queryKeys";

const StaffLoginReport = ({ pageName }) => {
  const queryClient = new QueryClient();
  const dateFieldsNames = {
    from: "listDateFrom",
    to: "listDateTo",
  };

  const dateSearchFormat = "DD/MM/yyyy";
  const { search, setSearch, selectedDates, handleDateChange } = useSearch(
    dateFieldsNames,
    dateSearchFormat,
  );

  const { fetchData } = useStaffLoginReport();

  return (
    <Flex direction="column">
      <Stack direction="column" spacing="24px" w="100%">
        <AnimatedFlex justify="space-between" duration={0.4} delay={0.4}>
          <Text
            fontSize="24px"
            fontWeight="bold"
            color="green.500"
            mb="12px"
            mt="12px"
          >
            {pageName}
          </Text>
        </AnimatedFlex>
        <AnimatedFlex duration={0.4} delay={0.6}>
          <Card px="22px" mb="24px">
            <CardHeader pb="12px" pt="12px">
              <Flex direction="column">
                <Text fontSize="18px" fontWeight="bold" mb="6px" color="#fff">
                  ประวัติการพนักงานเข้าสู่ระบบ
                </Text>
              </Flex>
              <Spacer />
              <Box>
                <CustomRangePicker
                  selectedDates={selectedDates}
                  onDateChange={handleDateChange}
                />
              </Box>
            </CardHeader>
            <CardBody>
              <QueryClientProvider client={queryClient}>
                <SearchTableRemote
                  query={QueryKeys.STAFF_LOGIN_REPORT}
                  columnsData={staffLoginHistoryColumnsData()}
                  fetchData={fetchData}
                  search={search}
                  setSearch={setSearch}
                />
              </QueryClientProvider>
            </CardBody>
          </Card>
        </AnimatedFlex>
      </Stack>
    </Flex>
  );
};

export default StaffLoginReport;
