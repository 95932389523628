import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import AnimatedFlex from "components/AnimatedFlex/MotionFlex";
import { Element } from "react-scroll";
import ImageUploader from "components/ImageUploader/ImageUploader";
import { Formik, Form } from "formik";
import CardHeader from "../../../components/Card/CardHeader";
import DeviceIframe from "./components/DeviceIframe";
import { DesktopIcon, MobileIcon, TabletIcon } from "components/Icons/Icons";
import { useWebSetting } from "hooks/business/website-setting/useWebSetting";

const WebSetting = ({ pageName }) => {
  const {
    webSettingData,
    webSiteUrl,
    iframeKey,
    view,
    setView,
    handleSubmit,
    isUpdating,
  } = useWebSetting();

  return (
    <Flex direction="column" overflow="hidden">
      <Stack direction="column" spacing="24px" w="100%">
        <AnimatedFlex justify="space-between" duration={0.4} delay={0.4}>
          <Text
            fontSize="24px"
            fontWeight="bold"
            color="green.500"
            mb="12px"
            mt="12px"
          >
            {pageName}
          </Text>
        </AnimatedFlex>
        <HStack direction="column" spacing="24px" w="100%">
          <Formik
            initialValues={{
              backgroundwalletImg: webSettingData?.backgroundwalletImg || "",
              backgroundMoneyImg: webSettingData?.backgroundMoneyImg || "",
              walletColorCode: webSettingData?.walletColorCode || "#ffffff",
              walletColor2Code: webSettingData?.walletColor2Code || "#ffffff",
              logo: webSettingData?.logo || "",
            }}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ values, setFieldValue }) => (
              <Form>
                <AnimatedFlex duration={0.4} delay={0.6}>
                  <Card
                    w={{ sm: "100%" }}
                    alignSelf="flex-end"
                    justifySelf="flex-end"
                  >
                    <Element id="decoration" name="decoration">
                      <CardBody>
                        <Grid
                          templateColumns={{
                            base: "1fr",
                            sm: "repeat(3, 1fr)",
                            md: "repeat(3, 1fr)",
                          }}
                          gap="24px"
                          w="100%"
                        >
                          <GridItem>
                            <FormControl>
                              <FormLabel
                                fontWeight="normal"
                                fontSize="14px"
                                mb="10px"
                              >
                                โลโก้{" "}
                                <Box
                                  as="span"
                                  color="green.500"
                                  fontWeight="normal"
                                >
                                  (ขนาด 100 x 100 px)
                                </Box>
                              </FormLabel>
                              <ImageUploader
                                initialImage={values.logo}
                                onUpload={(uri) => setFieldValue("logo", uri)}
                              />
                            </FormControl>
                          </GridItem>
                          <GridItem>
                            <FormControl>
                              <FormLabel
                                fontWeight="normal"
                                fontSize="14px"
                                mb="10px"
                              >
                                ภาพพื้นหลัง{" "}
                                <Box
                                  as="span"
                                  color="green.500"
                                  fontWeight="normal"
                                >
                                  (ขนาด 1920x1080 px)
                                </Box>
                              </FormLabel>
                              <ImageUploader
                                initialImage={values.backgroundwalletImg}
                                onUpload={(uri) =>
                                  setFieldValue("backgroundwalletImg", uri)
                                }
                              />
                            </FormControl>
                          </GridItem>
                          <GridItem>
                            <FormControl>
                              <FormLabel
                                fontWeight="normal"
                                fontSize="14px"
                                mb="10px"
                              >
                                ภาพพื้นหลังโทรศัพท์{" "}
                                <Box
                                  as="span"
                                  color="green.500"
                                  fontWeight="normal"
                                >
                                  (ขนาด 381 x 768 px)
                                </Box>
                              </FormLabel>
                              <ImageUploader
                                initialImage={values.backgroundMoneyImg}
                                onUpload={(uri) =>
                                  setFieldValue("backgroundMoneyImg", uri)
                                }
                              />
                            </FormControl>
                          </GridItem>
                        </Grid>
                      </CardBody>
                    </Element>
                    <Flex justify="flex-start" mt={4}>
                      <Button
                        type="submit"
                        borderRadius="33px"
                        background="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
                        width={{ base: "100%", md: "auto" }}
                        minW={{ base: "none", md: "195px" }}
                        fontWeight="normal"
                        fontSize="14px"
                        isLoading={isUpdating}
                        mt={4}
                      >
                        บันทึกข้อมูล
                      </Button>
                    </Flex>
                  </Card>
                </AnimatedFlex>
              </Form>
            )}
          </Formik>
        </HStack>
        <AnimatedFlex duration={0.4} delay={0.8}>
          <Card pb="48px">
            <CardHeader pb="12px" pt="12px">
              <Flex direction="column" justifyContent="center">
                <Text fontSize="18px" fontWeight="bold" mb="6px" color="#fff">
                  ตัวอย่างแสดงผล
                </Text>
              </Flex>
            </CardHeader>
            <Flex justifyContent="center">
              <Grid templateColumns="repeat(3, auto)" gap="12px">
                <GridItem>
                  <Button
                    bg={view === "desktop" ? "green.500" : "transparent"}
                    border={view === "desktop" ? "none" : "1px solid #373F48"}
                    onClick={() => setView("desktop")}
                    fontSize="14px"
                    color={view === "desktop" ? "#fff" : "rgb(103,108,114)"}
                    leftIcon={
                      <DesktopIcon
                        h="27px"
                        w="22px"
                        color={view === "desktop" ? "#fff" : "rgb(103,108,114)"}
                      />
                    }
                    fontWeight="normal"
                  >
                    Desktop View
                  </Button>
                </GridItem>
                <GridItem>
                  <Button
                    bg={view === "mobile" ? "green.500" : "transparent"}
                    border={view === "mobile" ? "none" : "1px solid #373F48"}
                    onClick={() => setView("mobile")}
                    fontSize="14px"
                    color={view === "mobile" ? "#fff" : "rgb(103,108,114)"}
                    leftIcon={
                      <MobileIcon
                        h="22px"
                        w="12px"
                        color={view === "mobile" ? "#fff" : "rgb(103,108,114)"}
                      />
                    }
                    fontWeight="normal"
                  >
                    Mobile View
                  </Button>
                </GridItem>
                <GridItem>
                  <Button
                    bg={view === "tablet" ? "green.500" : "transparent"}
                    border={view === "tablet" ? "none" : "1px solid #373F48"}
                    onClick={() => setView("tablet")}
                    fontSize="14px"
                    color={view === "tablet" ? "#fff" : "rgb(103,108,114)"}
                    leftIcon={
                      <TabletIcon
                        h="22px"
                        w="18px"
                        color={view === "tablet" ? "#fff" : "rgb(103,108,114)"}
                      />
                    }
                    fontWeight="normal"
                  >
                    Tablet View
                  </Button>
                </GridItem>
              </Grid>
            </Flex>
            <Flex justifyContent="center" alignItems="center" mt="18px">
              <DeviceIframe
                view={view}
                iframeKey={iframeKey}
                webSiteUrl={webSiteUrl}
              />
            </Flex>
          </Card>
        </AnimatedFlex>
      </Stack>
    </Flex>
  );
};

export default WebSetting;
