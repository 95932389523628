import { Flex, Stack, Text } from "@chakra-ui/react";
import AnimatedFlex from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";

import { memberLoginHistoryColumnsData } from "variables/columns/website-report/memberLoginHistoryColumnsData";
import React from "react";

import SearchTable1 from "components/Tables/SearchTable1";
import { useMemberLoginReport } from "hooks/business/website-report/useMemberLoginReport";

const MemberLoginReport = ({ pageName }) => {
  const { data: memberLoginHistoryData, isLoading } = useMemberLoginReport();
  return (
    <Flex direction="column" overflow="hidden">
      <Stack direction="column" spacing="24px" w="100%">
        <AnimatedFlex justify="space-between" duration={0.4} delay={0.4}>
          <Text
            fontSize="24px"
            fontWeight="bold"
            color="green.500"
            mb="12px"
            mt="12px"
          >
            {pageName}
          </Text>
        </AnimatedFlex>
        <AnimatedFlex duration={0.4} delay={0.6}>
          <Card px="22px" pb="38px">
            <CardHeader pb="12px" pt="12px">
              <Flex direction="column">
                <Text fontSize="18px" fontWeight="bold" mb="6px" color="#fff">
                  ประวัติการเข้าสู่ระบบ
                </Text>
              </Flex>
            </CardHeader>
            <CardBody>
              <SearchTable1
                tableData={memberLoginHistoryData}
                columnsData={memberLoginHistoryColumnsData}
                isLoading={isLoading}
              />
            </CardBody>
          </Card>
        </AnimatedFlex>
      </Stack>
    </Flex>
  );
};

export default MemberLoginReport;
