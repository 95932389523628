import {
  buildBasePayload,
  buildFetchSpinnerHistoryPayload,
} from "../../utils/PayloadUtils";
import { callListApi } from "../../utils/APIUtils";

export const fetchSpinnerHistory = async (
  page,
  pageSize,
  search,
  sort = "D",
  sortField = "id",
) => {
  const req = buildBasePayload(
    page,
    pageSize,
    search,
    sort,
    sortField,
    buildFetchSpinnerHistoryPayload,
  );
  const response = await callListApi(
    "/api/admin/luckywheel/history",
    "POST",
    req,
  )
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw new Error(`API error:${e?.message}`);
    });

  const data2 = await response;

  const data = {
    count: data2.page.totalElements,
    results: data2.datas,
  };

  // console.log(data);

  return data;
};
