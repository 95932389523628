import { Badge, Flex, IconButton } from "@chakra-ui/react";
import { formatDate } from "../../../utils/DateformatUtils";
import { EditIcon } from "../../../components/Icons/Icons";

export const staffSettingColumnsData = (handleModalOpen) => [
  {
    Header: "สถานะ",
    accessor: "active",
    textAlign: "left",
    justifyContent: "flex-start",
    Cell: ({ cell }) => (
      <Flex align="center">
        <Badge
          bg={cell.value === 1 ? "green.500" : "red.500"}
          w="65px"
          h="28px"
          borderRadius="15px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          fontWeight="normal"
        >
          {cell.value === 1 ? "Active" : "Inactive"}
        </Badge>
      </Flex>
    ),
  },
  {
    Header: "ชื่อ-นามสกุล",
    accessor: "fullName",
    textAlign: "left",
    justifyContent: "flex-start",
  },
  {
    Header: "ยูสเซอร์เนม",
    accessor: "username",
    textAlign: "left",
    justifyContent: "flex-start",
  },
  {
    Header: "เบอร์โทรศัพท์",
    accessor: "tel",
    textAlign: "left",
    justifyContent: "flex-start",
  },
  {
    Header: "ตำแหน่ง",
    accessor: "role",
    textAlign: "left",
    justifyContent: "flex-start",
  },
  {
    Header: "วันที่เริ่มทำงาน",
    accessor: "createdDate",
    Cell: ({ value }) => formatDate(value),
    textAlign: "left",
    justifyContent: "flex-start",
  },
  {
    Header: "",
    accessor: "edit",
    textAlign: "right",
    justifyContent: "flex-end",
    disableSortBy: true,
    Cell: ({ row }) => (
      <IconButton
        variant="solid"
        aria-label="edit"
        size="sm"
        borderRadius="28px"
        w="40px"
        h="30px"
        bg="green.500"
        icon={<EditIcon h="18px" w="18px" color="#fff" />}
        onClick={() => handleModalOpen("manageStaffModal", row.original)}
      />
    ),
  },
];
