import {
  addMember,
  updateMember,
} from "services/member-management/memberService";
import { ERROR_MESSAGE } from "variables/system";
import { useMutation, useQueryClient } from "react-query";
import { useErrorHandler } from "../../common/useErrorHandler";
import { useSuccessHandler } from "../../common/useSuccessHandler";
import { QueryKeys } from "../../../variables/queryKeys";

export const useMember = () => {
  const queryClient = useQueryClient();

  const handleError = useErrorHandler();
  const handleSuccess = useSuccessHandler();

  const mutation = useMutation(
    async ({ values, member }) => {
      const payload = member ? { ...member, ...values } : values;
      return member ? updateMember(member.id, payload) : addMember(payload);
    },
    {
      onSuccess: (response) => {
        handleSuccess(response.data.message);
        queryClient.invalidateQueries(QueryKeys.MEMBERS);
      },
      onError: (error) => {
        handleError(error, ERROR_MESSAGE);
      },
    },
  );

  const handleSaveMember = (values, member, actions, onClose) => {
    mutation.mutate(
      { values, member },
      {
        onSettled: () => {
          actions.setSubmitting(false);
          onClose();
        },
      },
    );
  };

  return {
    handleSaveMember,
    isSaving: mutation.isLoading,
  };
};
