import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Box,
  Text,
  Input,
  Grid,
  GridItem,
  InputRightElement,
  InputGroup,
  ModalHeader,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";
import { getProviderNiceName } from "utils/CommonUtils";

const CommissionSettingModal = ({
  isOpen,
  onClose,
  modalData,
  handleSubmit,
}) => {
  const [localData, setLocalData] = useState(modalData);

  useEffect(() => {
    setLocalData(modalData);
  }, [modalData]);

  const handleInputChange = (event, provider) => {
    const { value } = event.target;
    const updatedData = localData.map((item) =>
      item.provider === provider ? { ...item, percent: value } : item,
    );
    setLocalData(updatedData);
  };

  const handleSave = () => {
    handleSubmit(localData);
    onClose();
  };

  const renderProviders = (type, label) => (
    <>
      <Text fontSize="18px" fontWeight="bold" mt={2} pb={6} color="green.500">
        รายชื่อค่าย - {label}
      </Text>
      <Grid templateColumns="repeat(6, 1fr)" gap={3}>
        {localData
          .filter((provider) => provider[type] && provider.provider !== "UFA")
          .map((provider) => (
            <GridItem key={provider.provider}>
              <Box mb={4}>
                <Text fontSize="xs" color="#fff">
                  {getProviderNiceName(provider.provider)}
                </Text>
                <InputGroup>
                  <Input
                    name={provider.provider}
                    value={provider.percent}
                    onChange={(e) => handleInputChange(e, provider.provider)}
                  />
                  <InputRightElement width="3rem">
                    <Text>%</Text>
                  </InputRightElement>
                </InputGroup>
              </Box>
            </GridItem>
          ))}
      </Grid>
    </>
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size={"4xl"}
      scrollBehavior={"inside"}
      isCentered
      overflowY={true}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Box textAlign="left">
            <Text fontSize="20px" color="#fff">
              ตั้งค่าคอมมิชชั่น
            </Text>
          </Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Tabs isFitted variant="enclosed" mt={4}>
            <TabList>
              <Tab
                _selected={{
                  color: "#fff",
                  background:
                    "linear-gradient(90deg, #28AE95 0%, #0E7461 100%)",
                  border: "none",
                  fontWeight: "bold",
                }}
                backgroundColor="#373F48"
                marginLeft="55px"
                marginRight="5px"
                color="#8C9592"
              >
                Slot
              </Tab>
              <Tab
                _selected={{
                  color: "#fff",
                  background:
                    "linear-gradient(90deg, #28AE95 0%, #0E7461 100%)",
                  border: "none",
                  fontWeight: "bold",
                }}
                backgroundColor="#373F48"
                marginRight="5px"
                color="#8C9592"
              >
                Casino
              </Tab>
              <Tab
                _selected={{
                  color: "#fff",
                  background:
                    "linear-gradient(90deg, #28AE95 0%, #0E7461 100%)",
                  border: "none",
                  fontWeight: "bold",
                }}
                backgroundColor="#373F48"
                marginRight="5px"
                color="#8C9592"
              >
                Card
              </Tab>
              <Tab
                _selected={{
                  color: "#fff",
                  background:
                    "linear-gradient(90deg, #28AE95 0%, #0E7461 100%)",
                  border: "none",
                  fontWeight: "bold",
                }}
                backgroundColor="#373F48"
                marginRight="5px"
                color="#8C9592"
              >
                Fishing
              </Tab>
              <Tab
                _selected={{
                  color: "#fff",
                  background:
                    "linear-gradient(90deg, #28AE95 0%, #0E7461 100%)",
                  border: "none",
                  fontWeight: "bold",
                }}
                marginRight="55px"
                backgroundColor="#373F48"
                color="#8C9592"
              >
                Sport
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel pl={0} pr={0}>
                {renderProviders("isSlot", "Slot")}
              </TabPanel>
              <TabPanel pl={0} pr={0}>
                {renderProviders("isLive", "Casino")}
              </TabPanel>
              <TabPanel pl={0} pr={0}>
                {renderProviders("isCard", "Card")}
              </TabPanel>
              <TabPanel pl={0} pr={0}>
                {renderProviders("isFishing", "Fish")}
              </TabPanel>
              <TabPanel pl={0} pr={0}>
                {renderProviders("isSport", "Sport")}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </ModalBody>
        <ModalFooter pt={0} pb="48px">
          <Button
            bg="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
            onClick={handleSave}
            w="100%"
            pt={0}
            fontWeight="normal"
            fontSize="14px"
          >
            บันทึกข้อมูล
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CommissionSettingModal;
