import React from "react";
import { Box, Flex, Grid, Icon, Spacer, Stack, Text } from "@chakra-ui/react";
import AnimatedFlex from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import SearchTable1 from "components/Tables/SearchTable1";
import { staffCreditHistoryColumnsData } from "variables/columns/website-report/staffCreditHistoryColumnsData";
import CustomRangePicker from "components/CustomRangePicker/CustomRangePicker";

import StaffCreditReportCard from "../staff-credit/components/StaffCreditReportCard";

import {
  StaffAddCreditIcon,
  StaffRemoveCreditIcon,
  StaffSlipNotFoundIcon,
} from "components/Icons/Icons";
import { useSearch } from "hooks/common/useSearch";
import { useStaffCreditHistory } from "hooks/business/website-report/useStaffCreditHistory";

const StaffCreditReport = ({ pageName }) => {
  const dateSearchFormat = "DD/MM/yyyy HH:mm:ss";

  const dateFieldsNames = {
    from: "startDate",
    to: "endDate",
  };

  const { search, setSearch, selectedDates, handleDateChange } = useSearch(
    dateFieldsNames,
    dateSearchFormat,
    true,
  );

  const { data: staffCreditData, isLoading } = useStaffCreditHistory(search);

  const summaryData = staffCreditData?.summary || {
    noSlip: 0,
    cutCredit: 0,
    addCredit: 0,
  };
  const listData = staffCreditData?.list || [];

  const searchOptions = [
    {
      label: "ทั้งหมด",
      value: "ALL",
    },
    {
      label: "ลบเครดิต",
      value: "CUT_CREDIT",
    },
    {
      label: "สลิปไม่แสดง",
      value: "NO_SLIP",
    },
    {
      label: "เพิ่มเครดิต",
      value: "ADD_CREDIT",
    },
  ];

  const handleOptionChange = (selectedOption) => {
    setSearch({
      ...search,
      type: selectedOption ? selectedOption.value : null,
    });
  };

  return (
    <Flex direction="column">
      <Stack direction="column" spacing="15px" w="100%">
        <AnimatedFlex justify="space-between" duration={0.4} delay={0.4}>
          <Text
            fontSize="24px"
            fontWeight="bold"
            color="green.500"
            mb="12px"
            mt="12px"
          >
            {pageName}
          </Text>
        </AnimatedFlex>
        <Grid
          templateColumns={{ base: "repeat(2, 1fr)", lg: "repeat(3, 1fr)" }}
          gap="24px"
          mb="24px"
          pb="24px"
        >
          <StaffCreditReportCard
            title={"เพิ่มเครดิต"}
            icon={
              <Icon
                as={StaffAddCreditIcon}
                w={{ base: "20px", md: "30px" }}
                h={{ base: "20px", md: "30px" }}
                color="#8C9592"
              />
            }
            number={summaryData.addCredit.toString()}
            isInteger={false}
          />
          <StaffCreditReportCard
            title={"สลิปไม่แสดง"}
            number={summaryData.noSlip.toString()}
            icon={
              <Icon
                as={StaffSlipNotFoundIcon}
                w={{ base: "20px", md: "30px" }}
                h={{ base: "20px", md: "30px" }}
                color="#8C9592"
              />
            }
            isInteger={false}
          />
          <StaffCreditReportCard
            title={"ลบเครดิต"}
            number={summaryData.cutCredit.toString()}
            icon={
              <Icon
                as={StaffRemoveCreditIcon}
                w={{ base: "20px", md: "30px" }}
                h={{ base: "20px", md: "30px" }}
                color="#8C9592"
              />
            }
            isInteger={false}
          />
        </Grid>
        <AnimatedFlex duration={0.4} delay={0.6}>
          <Card px="22px" mb="24px">
            <CardHeader pb="12px" pt="12px">
              <Flex direction="column">
                <Text color="white" fontSize="18px" fontWeight="bold" mb="6px">
                  {pageName}
                </Text>
              </Flex>
              <Spacer />
              <Box>
                <CustomRangePicker
                  selectedDates={selectedDates}
                  onDateChange={handleDateChange}
                />
              </Box>
            </CardHeader>
            <CardBody>
              <SearchTable1
                isLoading={isLoading}
                tableData={listData}
                showSearchableDropdown={true}
                searchOptions={searchOptions}
                onChange={handleOptionChange}
                columnsData={staffCreditHistoryColumnsData}
                hideSearch={false}
              />
            </CardBody>
          </Card>
        </AnimatedFlex>
      </Stack>
    </Flex>
  );
};

export default StaffCreditReport;
