import {
  Box,
  Flex,
  Grid,
  Text,
  Icon,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  GridItem,
  Spinner,
} from "@chakra-ui/react";

import React, { useState } from "react";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";

import moment from "moment";
import NormalReportsCard from "./components/NormalReportsCard";

import CustomRangePicker from "components/CustomRangePicker/CustomRangePicker";
import LineChart from "components/Charts/LineChart";
import TopTransactionCard from "./components/TopTransactionCard";
import { getReferenceDates } from "../../utils/DateformatUtils";
import { APP_PROFILE, MARKETING_ROLE } from "../../variables/system";
import {
  DepositIcon,
  WithdrawIcon,
  DepositBonusIcon,
  ProfitIcon,
  NewMemberIcon,
  MemberNotDepositIcon,
  MemberDepositIcon,
  MemberWithdrawIcon,
  NewMemberDeposit,
  OldMemberDeposit,
  AdminDeposit,
} from "../../components/Icons/Icons";
import SimpleTable from "../../components/Tables/SimpleTable";
import {
  dashboardLastDeposit,
  dashboardLastWithdraw,
} from "../../variables/columns/dashboard/dashboardColumnsData";
import BigReportCard from "./components/BigReportCard";
import AbnormalReportCard from "./components/AbnormalReportCard";

import { useLast20Deposit } from "hooks/business/dashboard/useLast20Deposit";
import { useDashboardSummary } from "hooks/business/dashboard/useDashboardSummary";
import { useLast20Withdraw } from "hooks/business/dashboard/useLast20Withdraw";
import { useProfitGraph } from "hooks/business/dashboard/useProfitGraph";
import { useTopDeposit } from "hooks/business/dashboard/useTopDeposit";
import { useTopWithdraw } from "hooks/business/dashboard/useTopWithdraw";
import ReportsCard from "../../components/ReportCards/ReportsCard";

const Dashboard = ({ pageName }) => {
  const { dateNow, dateDayCheck } = getReferenceDates();
  const [selectedDates, setSelectedDates] = useState([dateDayCheck, dateNow]);

  const formattedStartDate = moment(selectedDates[0])
    .startOf("day")
    .format("yyyy-MM-DD HH:mm:ss");
  const formattedEndDate = moment(selectedDates[1])
    .endOf("day")
    .format("yyyy-MM-DD HH:mm:ss");

  const role = JSON.parse(localStorage.getItem(APP_PROFILE))?.role;

  const { summary, isSummaryLoading } = useDashboardSummary(
    formattedStartDate,
    formattedEndDate,
    role,
  );

  const { lineChartData, isMemberLineDataLoading } = useProfitGraph(
    selectedDates,
    role,
  );

  const { lastDeposit, isLastDepositLoading } = useLast20Deposit(role);
  const { lastWithdraw, isLastWithdrawLoading } = useLast20Withdraw(role);

  const { topDeposit, isTopDepositLoading } = useTopDeposit(
    formattedStartDate,
    formattedEndDate,
    role,
  );
  const { topWithdraw, isTopWithdrawLoading } = useTopWithdraw(
    formattedStartDate,
    formattedEndDate,
    role,
  );

  return (
    <Flex flexDirection="column">
      <Flex justifyContent="space-between" alignItems="center">
        <Text
          fontSize="24px"
          fontWeight="bold"
          color="green.500"
          mb="12px"
          mt="12px"
        >
          {pageName}
        </Text>
        <Box>
          <CustomRangePicker
            selectedDates={selectedDates}
            onDateChange={(dates) => {
              setSelectedDates(dates);
            }}
          />
        </Box>
      </Flex>
      {/* Normal Reports */}
      <Grid
        templateColumns={{
          base: "repeat(1, 1fr)",
          lg: "repeat(2, 1fr)",
        }}
        gap="24px"
        mb="24px"
        pt={{ base: "20px", md: "25px" }}
        display={role !== MARKETING_ROLE ? "grid" : "none"}
      >
        <BigReportCard
          isDeposit={true}
          mainIcon={
            <Icon
              as={DepositIcon}
              w={{ base: "20px", md: "38px" }}
              h={{ base: "20px", md: "38px" }}
              color="#fff"
            />
          }
          mainAmount={summary?.deposit?.amount}
          mainCount={summary?.deposit?.count}
          firstAmount={summary?.depositMistake?.amount}
          firstCount={summary?.depositMistake?.count}
          secondAmount={summary?.depositWaitToApprove?.amount}
          secondCount={summary?.depositWaitToApprove?.count}
          isLoading={isSummaryLoading}
          isInteger={false}
        />
        <BigReportCard
          isDeposit={false}
          mainIcon={
            <Icon
              as={WithdrawIcon}
              w={{ base: "20px", md: "38px" }}
              h={{ base: "20px", md: "38px" }}
              color="#fff"
            />
          }
          mainAmount={summary?.withdraw?.amount}
          mainCount={summary?.withdraw?.count}
          firstAmount={summary?.withdrawMistake?.amount}
          firstCount={summary?.withdrawMistake?.count}
          secondAmount={summary?.withdrawWaitToApprove?.amount}
          secondCount={summary?.withdrawWaitToApprove?.count}
          isLoading={isSummaryLoading}
          isInteger={false}
        />
      </Grid>
      <Grid
        templateColumns={{
          base: "repeat(1, 1fr)",
          lg: "repeat(5, 1fr)",
        }}
        gap="24px"
        mb="24px"
        display={role !== MARKETING_ROLE ? "grid" : "none"}
      >
        <GridItem rowSpan={{ base: 1, lg: 2 }}>
          <AbnormalReportCard
            mainText={"สมัครใหม่"}
            mainAmount={summary?.newRegister?.count}
            mainIcon={
              <Icon as={NewMemberIcon} w="135px" h="90px" color="#373F48" />
            }
            mainDescription={"สมาชิกสมัครใหม่"}
            isInteger={false}
            isLoading={isSummaryLoading}
          />
        </GridItem>
        <NormalReportsCard
          title={"กำไร"}
          icon={
            <Icon
              as={ProfitIcon}
              w={{ base: "20px", md: "26px" }}
              h={{ base: "20px", md: "26px" }}
              color="#8C9592"
            />
          }
          number={summary?.profit?.amount}
          description={"ยอดฝาก - ยอดถอน"}
          isInteger={false}
          isLoading={isSummaryLoading}
        />
        <NormalReportsCard
          title={"สมัครไม่ฝาก"}
          icon={
            <Icon
              as={MemberNotDepositIcon}
              w={{ base: "20px", md: "26px" }}
              h={{ base: "20px", md: "26px" }}
              color="#8C9592"
            />
          }
          number={summary?.newRegisterNoDeposit?.count}
          description={"สมาชิกที่สมัครใหม่แต่ไม่ฝาก"}
          isInteger={true}
          isLoading={isSummaryLoading}
        />
        <NormalReportsCard
          isLoading={isSummaryLoading}
          title={"จำนวนคนฝาก"}
          number={summary?.countDeposit?.count}
          icon={
            <Icon
              as={MemberDepositIcon}
              w={{ base: "20px", md: "26px" }}
              h={{ base: "20px", md: "26px" }}
              color="#8C9592"
            />
          }
          description={"ไม่นับกรณีคนฝากรอบสอง"}
          isInteger={true}
        />

        <NormalReportsCard
          title={"จำนวนคนถอน"}
          icon={
            <Icon
              as={MemberWithdrawIcon}
              w={{ base: "20px", md: "26px" }}
              h={{ base: "20px", md: "26px" }}
              color="#8C9592"
            />
          }
          number={summary?.countWithdraw?.count}
          description={"ไม่นับกรณีคนถอนรอบสอง"}
          isInteger={true}
          isLoading={isSummaryLoading}
        />
        <NormalReportsCard
          title={"ยอดฝากโบนัส"}
          icon={
            <Icon
              as={DepositBonusIcon}
              w={{ base: "20px", md: "26px" }}
              h={{ base: "20px", md: "26px" }}
              color="#8C9592"
            />
          }
          number={summary?.depositBonus?.amount}
          description={summary?.depositBonus?.count + " รายการ"}
          isInteger={false}
          isLoading={isSummaryLoading}
        />
        <NormalReportsCard
          title={"ยอดฝากสมาชิกใหม่"}
          icon={
            <Icon
              as={NewMemberDeposit}
              w={{ base: "20px", md: "26px" }}
              h={{ base: "20px", md: "26px" }}
              color="#8C9592"
            />
          }
          number={summary?.newRegisterDeposit?.amount}
          description={summary?.newRegisterDeposit?.count + " รายการ"}
          isInteger={true}
          isLoading={isSummaryLoading}
        />
        <NormalReportsCard
          title={"ยอดฝากสมัครชิกเก่า"}
          icon={
            <Icon
              as={OldMemberDeposit}
              w={{ base: "20px", md: "26px" }}
              h={{ base: "20px", md: "26px" }}
              color="#8C9592"
            />
          }
          number={summary?.oldUserDeposit?.amount}
          description={summary?.oldUserDeposit?.count + " รายการ"}
          isInteger={true}
          isLoading={isSummaryLoading}
        />
        <NormalReportsCard
          title={"ยอดแอดมินเสกเครดิต"}
          icon={
            <Icon
              as={AdminDeposit}
              w={{ base: "20px", md: "26px" }}
              h={{ base: "20px", md: "26px" }}
              color="#8C9592"
            />
          }
          number={summary?.adminCreditDeposit?.amount}
          description={summary?.adminCreditDeposit?.count + " รายการ"}
          isInteger={true}
          isLoading={isSummaryLoading}
        />
      </Grid>
      {/* Marketing Reports */}
      <Grid
        templateColumns={{
          base: "repeat(1, 1fr)",
          lg: "repeat(5, 1fr)",
        }}
        gap="24px"
        mb="24px"
        display={role !== MARKETING_ROLE ? "none" : "grid"}
        pt={{ base: "20px", md: "25px" }}
      >
        <GridItem colSpan={2}>
          <ReportsCard
            title={"ยอดฝาก"}
            number={summary?.deposit?.amount}
            icon={
              <Icon
                as={DepositIcon}
                w={{ base: "20px", md: "26px" }}
                h={{ base: "20px", md: "26px" }}
                color="white"
              />
            }
            description={summary?.deposit?.count + " รายการ"}
            isInteger={false}
            isLoading={isSummaryLoading}
            mode={"Deposit"}
          />
        </GridItem>
        <GridItem colSpan={2}>
          <ReportsCard
            title={"ยอดถอน"}
            icon={
              <Icon
                as={WithdrawIcon}
                w={{ base: "20px", md: "26px" }}
                h={{ base: "20px", md: "26px" }}
                color="white"
              />
            }
            number={summary?.withdraw?.amount}
            description={summary?.withdraw?.count + " รายการ"}
            isInteger={false}
            isLoading={isSummaryLoading}
            mode={"Withdraw"}
          />
        </GridItem>
        <ReportsCard
          title={"ยอดฝากโบนัส"}
          icon={
            <Icon
              as={DepositBonusIcon}
              w={{ base: "20px", md: "30px" }}
              h={{ base: "20px", md: "30px" }}
              color="#8C9592"
            />
          }
          number={summary?.depositBonus?.amount}
          description={summary?.depositBonus?.count + " รายการ"}
          isInteger={false}
          isLoading={isSummaryLoading}
        />
        <ReportsCard
          title={"กำไร"}
          icon={
            <Icon
              as={ProfitIcon}
              w={{ base: "20px", md: "30px" }}
              h={{ base: "20px", md: "30px" }}
              color="#8C9592"
            />
          }
          number={summary?.profit?.amount}
          description={"ยอดฝาก - ยอดถอน"}
          isInteger={false}
          isLoading={isSummaryLoading}
        />
        <ReportsCard
          title={"สมัครใหม่"}
          number={summary?.newRegister.count}
          icon={
            <Icon
              as={NewMemberIcon}
              w={{ base: "20px", md: "30px" }}
              h={{ base: "20px", md: "30px" }}
              color="#8C9592"
            />
          }
          description={"สมาชิกสมัครใหม่"}
          isInteger={true}
          isLoading={isSummaryLoading}
        />
        <ReportsCard
          title={"สมัครไม่ฝาก"}
          icon={
            <Icon
              as={MemberNotDepositIcon}
              w={{ base: "20px", md: "30px" }}
              h={{ base: "20px", md: "30px" }}
              color="#8C9592"
            />
          }
          number={summary?.newRegisterNoDeposit?.count}
          description={"สมาชิกที่สมัครใหม่แต่ไม่ฝาก"}
          isInteger={true}
          isLoading={isSummaryLoading}
        />
        <ReportsCard
          title={"จำนวนคนฝาก"}
          number={summary?.countDeposit?.count}
          icon={
            <Icon
              as={MemberDepositIcon}
              w={{ base: "20px", md: "30px" }}
              h={{ base: "20px", md: "30px" }}
              color="#8C9592"
            />
          }
          description={"ไม่นับกรณีคนฝากรอบสอง"}
          isInteger={true}
          isLoading={isSummaryLoading}
        />
        <ReportsCard
          title={"จำนวนคนถอน"}
          icon={
            <Icon
              as={MemberWithdrawIcon}
              w={{ base: "20px", md: "30px" }}
              h={{ base: "20px", md: "30px" }}
              color="#8C9592"
            />
          }
          number={summary?.countWithdraw?.count}
          description={"ไม่นับกรณีคนถอนรอบสอง"}
          isInteger={true}
          isLoading={isSummaryLoading}
        />
      </Grid>
      <Grid
        templateColumns={{ sm: "1fr", lg: "1.8fr 1.2fr" }}
        gap="24px"
        mb="26px"
        minH="475px"
        background="#262D35"
        p="25px"
        borderRadius="16px"
      >
        {isMemberLineDataLoading ? (
          <Flex
            alignItems="center"
            justifyContent="center"
            height="400px"
            w="100%"
          >
            <Spinner color="green.500" size="xl" />
          </Flex>
        ) : (
          <Card px="0px" pb="0px" h="100%" background="none" boxShadow="none">
            <CardHeader
              px="22px"
              pb="12px"
              pt="0px"
              display="flex"
              flexDirection="column"
            >
              <Text fontSize="lg" fontWeight="bold" color="#fff">
                รายงาน ฝาก/ถอน
              </Text>
              <Text fontSize="10px" color="#8C9592" mt="4px">
                {selectedDates[0]?.toLocaleDateString("en-GB")}
                {" - "}
                {selectedDates[1]?.toLocaleDateString("en-GB")}
              </Text>
            </CardHeader>
            <CardBody h="100%">
              <Box w="100%" h="100%">
                <LineChart
                  key={JSON.stringify(lineChartData.memberLineData)}
                  chartData={lineChartData.memberLineData}
                  chartOptions={lineChartData.chartOptions}
                />
              </Box>
            </CardBody>
          </Card>
        )}
        <Tabs isFitted variant="enclosed">
          <TabList>
            <Tab
              _selected={{
                color: "#fff",
                background: "linear-gradient(90deg, #28AE95 0%, #0E7461 100%)",
                border: "none",
              }}
              backgroundColor="#373F48"
              fontSize={{ base: "sm", md: "md" }}
              _focus={{ boxShadow: "none" }}
              marginLeft="95px"
              marginRight="5px"
              borderTopRadius="8px"
            >
              Top 5 ยอดฝากสูงสุด
            </Tab>
            <Tab
              _selected={{
                color: "#fff",
                background: "linear-gradient(90deg, #28AE95 0%, #0E7461 100%)",
                border: "none",
              }}
              backgroundColor="#373F48"
              fontSize={{ base: "sm", md: "md" }}
              _focus={{ boxShadow: "none" }}
              marginRight="95px"
              marginLeft="5px"
              borderTopRadius="8px"
            >
              Top 5 ยอดถอนสูงสุด
            </Tab>
          </TabList>
          {isTopWithdrawLoading && isTopDepositLoading ? (
            <Flex
              alignItems="center"
              justifyContent="center"
              height="400px"
              w="100%"
            >
              <Spinner color="green.500" size="xl" />
            </Flex>
          ) : (
            <TabPanels>
              <TabPanel px={0}>
                {topDeposit?.slice(0, 5).map((data, index) => (
                  <Box key={index} mt={index === 0 ? 0 : 2}>
                    <TopTransactionCard
                      index={index + 1}
                      username={data.username}
                      number={data.deposit}
                      isDeposit={true}
                    />
                  </Box>
                ))}
              </TabPanel>
              <TabPanel px={0}>
                {topWithdraw?.slice(0, 5).map((data, index) => (
                  <Box key={index} mt={index === 0 ? 0 : 2}>
                    <TopTransactionCard
                      index={index + 1}
                      username={data.username}
                      number={data.withdraw}
                      isDeposit={false}
                    />
                  </Box>
                ))}
              </TabPanel>
            </TabPanels>
          )}
        </Tabs>
      </Grid>
      <Grid templateColumns={{ md: "repeat(2, 1fr)" }} gap="24px">
        <Card px="0px">
          <CardHeader px="22px" pb="12px" pt="12px">
            <Flex direction="column">
              <Text fontSize="18px" fontWeight="bold" mb="6px" color="#fff">
                รายการฝากล่าสุด
              </Text>
            </Flex>
          </CardHeader>
          <CardBody h="100%">
            <Box w="100%" h="100%" pl="22px" pr="22px">
              <SimpleTable
                tableData={lastDeposit}
                columnsData={dashboardLastDeposit}
                rowSize={10}
                isLoading={isLastDepositLoading}
              />
            </Box>
          </CardBody>
        </Card>

        <Card px="0px">
          <CardHeader px="22px" pb="12px" pt="12px">
            <Flex direction="column">
              <Text fontSize="18px" fontWeight="bold" mb="6px" color="#fff">
                รายการถอนล่าสุด
              </Text>
            </Flex>
          </CardHeader>
          <CardBody h="100%">
            <Box w="100%" h="100%" pl="22px" pr="22px">
              <SimpleTable
                tableData={lastWithdraw}
                columnsData={dashboardLastWithdraw}
                rowSize={10}
                isLoading={isLastWithdrawLoading}
              />
            </Box>
          </CardBody>
        </Card>
      </Grid>
    </Flex>
  );
};

export default Dashboard;
