import { Flex, Stack, Text } from "@chakra-ui/react";
import AnimatedFlex from "components/AnimatedFlex/MotionFlex";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";

import { thiefColumnsData } from "variables/columns/theif/thiefColumnsData";
import { QueryClientProvider, QueryClient } from "react-query";

import React from "react";
import { fetchThieve } from "services/thief/thiefService";
import SearchTableRemote from "components/Tables/SearchTableRemote";

const Thief = ({ pageName }) => {
  const queryClient = new QueryClient();

  return (
    <Flex direction="column" overflow="hidden">
      <Stack direction="column" spacing="24px" w="100%">
        <AnimatedFlex justify="space-between" duration={0.4} delay={0.4}>
          <Text
            fontSize="24px"
            fontWeight="bold"
            color="green.500"
            mb="12px"
            mt="12px"
          >
            {pageName}
          </Text>
        </AnimatedFlex>
        <AnimatedFlex duration={0.4} delay={0.6}>
          <Card px="0px" mb="24px">
            <CardHeader
              px="22px"
              pb="12px"
              pt="12px"
              className="card-header-decorator card-header-bg"
            >
              <Flex direction="column">
                <Text color="white" fontSize="18px" fontWeight="bold" mb="6px">
                  รายงานมิจฉาชีพ
                </Text>
              </Flex>
            </CardHeader>
            <CardBody pl="22px" pr="22px">
              <QueryClientProvider client={queryClient}>
                <SearchTableRemote
                  columnsData={thiefColumnsData}
                  query={"thieve"}
                  fetchData={fetchThieve}
                />
              </QueryClientProvider>
            </CardBody>
          </Card>
        </AnimatedFlex>
      </Stack>
    </Flex>
  );
};

export default Thief;
