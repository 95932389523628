import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";

const DeleteTrueWalletModal = ({
  isOpen,
  onClose,
  trueWallet,
  handleDelete,
  isDeleting,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Box textAlign="center">ยืนยันทำรายการ</Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>คุณแน่ใจที่จะลบ ทรูวอลเล็ต ?</ModalBody>
        <ModalFooter>
          <Button
            isLoading={isDeleting}
            color={"#000000"}
            backgroundColor="green.500"
            onClick={() => {
              handleDelete(trueWallet?.id);
              onClose();
            }}
            w="100%"
          >
            ทำรายการ
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteTrueWalletModal;
