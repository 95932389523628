import { fetchSpinnerHistory } from "services/system-setting/spinnerReportService";
export const useStaffLoginReport = () => {
  const fetchData = async (pageIndex, pageSize, search) => {
    return await fetchSpinnerHistory(pageIndex, pageSize, search);
  };

  return {
    fetchData,
  };
};
