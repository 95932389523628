import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  fetchPaymentGatewaySetting,
  updatePaymentGatewaySetting,
} from "../../../services/finance-setting/paymentGatewayService";
import { ERROR_MESSAGE } from "../../../variables/system";
import { useErrorHandler } from "../../common/useErrorHandler";
import { QueryKeys } from "../../../variables/queryKeys";
import { useSuccessHandler } from "../../common/useSuccessHandler";

export const usePaymentGatewaySetting = () => {
  const handleError = useErrorHandler();
  const handleSuccess = useSuccessHandler();
  const queryClient = useQueryClient();

  const { data: paymentGatewayData, isLoading } = useQuery(
    QueryKeys.PAYMENT_GATEWAY_SETTING,
    async () => {
      const response = await fetchPaymentGatewaySetting();
      return response.data.data;
    },
    {
      onError: (error) => handleError(error, ERROR_MESSAGE),
    },
  );

  const mutation = useMutation(
    async ({ values, paymentGateway }) => {
      const { active, ...configValues } = values;
      const updatedConfig = JSON.stringify(configValues);

      const req = {
        ...paymentGateway,
        config: updatedConfig,
        active,
      };

      return await updatePaymentGatewaySetting(req);
    },
    {
      onSuccess: (response) => {
        handleSuccess(response.data.message);
        queryClient.invalidateQueries(QueryKeys.PAYMENT_GATEWAY_SETTING);
      },
      onError: (error) => {
        handleError(error, ERROR_MESSAGE);
      },
    },
  );

  const handleUpdate = (values, paymentGateway, actions, onClose) => {
    mutation.mutate(
      { values, paymentGateway },
      {
        onSettled: () => {
          actions.setSubmitting(false);
          onClose();
        },
      },
    );
  };

  return {
    paymentGatewayData,
    isLoading,
    handleUpdate,
  };
};
