import {
  Text,
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";

const DeleteBannerModal = ({
  isOpen,
  onClose,
  banner,
  handleSubmit,
  isSubmitting,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Box textAlign="center">ยืนยันทำรายการ</Box>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text>{`คุณแน่ใจจะลบ แบนเนอร์: ${banner?.name} ?`}</Text>
        </ModalBody>
        <ModalFooter>
          <Button
            backgroundColor="green.500"
            color={"#000000"}
            onClick={() => {
              handleSubmit(banner?.id);
              onClose();
            }}
            isLoading={isSubmitting}
            w="100%"
          >
            ทำรายการ
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DeleteBannerModal;
