import { useQuery } from "react-query";
import { fetchMemberLoginReport } from "../../../services/website-report/memberLoginReportService";
import { ERROR_MESSAGE } from "../../../variables/system";
import { useErrorHandler } from "../../common/useErrorHandler";
import { QueryKeys } from "../../../variables/queryKeys";

export const useMemberLoginReport = () => {
  const handleError = useErrorHandler();

  return useQuery(
    QueryKeys.MEMBER_LOGIN_REPORT,
    async () => {
      const response = await fetchMemberLoginReport();
      return response.data.data;
    },
    {
      onError: (error) => handleError(error, ERROR_MESSAGE),
    },
  );
};
