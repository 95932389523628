import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Switch,
  Grid,
  GridItem,
  HStack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { Formik, Field, Form } from "formik";

import {
  BANK_MAP,
  BankServiceTypes,
  BankTypes,
  ERROR_MESSAGE,
} from "variables/system";
import { validateField } from "utils/CommonUtils";

import { useMemberLevel } from "hooks/business/member-management/useMemberLevel";
import { fetchBanks } from "services/member-management/memberService";
import { useErrorHandler } from "hooks/common/useErrorHandler";

const ManageBankModal = ({ isOpen, onClose, bank = null, handleSubmit }) => {
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const [banksMasterData, setBanksMasterData] = useState([]);

  const modalSize = useBreakpointValue({ base: "full", md: "2xl" });
  const { memberLevelData } = useMemberLevel();
  const handleError = useErrorHandler();

  const BANK_OPTIONS = [
    { value: "DEPOSIT", label: "ฝาก" },
    { value: "WITHDRAW", label: "ถอน" },
  ];

  const BANK_SERVICE_OPTIONS = [
    { value: "MANUAL", label: "Manual" },
    { value: "API", label: "API" },
    { value: "SCBCONNECT", label: "SCB Connect" },
  ];

  const fetchBanksMasterData = (type, nationality) => {
    const fetchType =
      type === BankTypes.DEPOSIT ? "bankDeposit" : "bankWithdraw";

    fetchBanks(fetchType, nationality)
      .then((response) => {
        setBanksMasterData(response.data.data);
      })
      .catch((error) => {
        handleError(error, ERROR_MESSAGE);
      });
  };

  useEffect(() => {
    if (bank) {
      fetchBanksMasterData(bank?.bankType, bank?.nationality);
    }
  }, [bank]);

  return (
    <Modal
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size={modalSize}
    >
      <ModalOverlay />
      <ModalContent maxH="95vh">
        <ModalHeader>เพิ่ม / แก้ไข ธนาคาร</ModalHeader>
        <ModalCloseButton />
        <ModalBody overflowY="auto">
          <Formik
            initialValues={{
              id: bank ? bank.id : "",
              bankCode: bank ? bank.bankCode : "",
              bankType: bank ? bank.bankType : "",
              bankName: bank ? bank.bankName : "",
              bankAccountName: bank ? bank.bankAccountName : "",
              bankAccountNo: bank ? bank.bankAccountNo : "",
              username: bank ? bank.username : "",
              password: bank ? bank.password : "",
              bankOrder: bank ? bank.bankOrder : "",
              bankGroup: bank ? bank.bankGroup : "",
              botIp: bank ? bank.botIp : "",
              newUserFlag: bank ? bank.newUserFlag : true,
              active: bank ? bank.active : true,
              version: bank ? bank.version : "",
              createdDate: bank ? bank.createdDate : "",
              updatedDate: bank ? bank.updatedDate : "",
              createdBy: bank ? bank.createdBy : "",
              updatedBy: bank ? bank.updatedBy : "",
              nationality: bank ? bank.nationality : "",
              currency: bank ? bank.currency : "",
              rate: bank ? bank.rate : "",
              descriptionImg: bank ? bank.descriptionImg : "",
              adminSubsidi: bank ? bank.adminSubsidi : "",
              adminFee: bank ? bank.adminFee : "",
              adminSubsidiPercentage: bank ? bank.adminSubsidiPercentage : "",
              adminFeePercentage: bank ? bank.adminFeePercentage : "",
              memberLevelId: bank ? bank.memberLevelId : null,
              minAmount: bank ? bank.minAmount : "",
              maxAmount: bank ? bank.maxAmount : "",
              serviceType: bank ? bank.serviceType : "",
              deviceId: bank ? bank.deviceId : "",
              pin: bank ? bank.pin : "",
              proxyIp: bank ? bank.proxyIp : "",
              // hash: bank ? bank?.hash : "",
            }}
            onSubmit={(values, actions) => {
              handleSubmit(values, bank, actions, onClose);
            }}
          >
            {(props) => (
              <Form>
                <Grid
                  templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }}
                  gap={4}
                >
                  <GridItem colSpan={2}>
                    <Text
                      fontSize="16px"
                      fontWeight="bold"
                      pt={4}
                      pb={2}
                      color="#31AA93"
                    >
                      ข้อมูลบัญชี
                    </Text>
                  </GridItem>
                  <GridItem>
                    <Field name="nationality" validate={validateField}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.nationality && form.touched.nationality
                          }
                          isRequired
                        >
                          <FormLabel>สัญชาติ</FormLabel>
                          <Select
                            {...field}
                            borderRadius="33px"
                            placeholder="เลือกสัญชาติ"
                            ref={initialRef}
                            fontSize="sm"
                          >
                            <option value="thai">ไทย</option>
                            {/*<option value="laos">ลาว</option>*/}
                          </Select>
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field name="bankType" validate={validateField}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.bankType && form.touched.bankType
                          }
                          isRequired
                        >
                          <FormLabel>ประเภทธนาคาร</FormLabel>
                          <Select
                            {...field}
                            borderRadius="33px"
                            placeholder="ประเภทธนาคาร"
                            fontSize="sm"
                            onChange={(e) => {
                              const value = e.target.value;
                              props.setFieldValue("bankType", value);
                              fetchBanksMasterData(
                                value,
                                props.values?.nationality,
                              );
                            }}
                          >
                            {BANK_OPTIONS.map((bank) => (
                              <option key={bank.id} value={bank.value}>
                                {bank.label}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={{ base: 2, md: 1 }}>
                    <Field name="bankCode">
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.bankCode && form.touched.bankCode
                          }
                          isRequired
                        >
                          <FormLabel>ธนาคาร</FormLabel>
                          <Select
                            {...field}
                            borderRadius="33px"
                            placeholder="เลือกธนาคาร"
                            fontSize="sm"
                            onChange={(e) => {
                              const value = e.target.value;
                              props.setFieldValue("bankCode", value);
                              props.setFieldValue("bankName", BANK_MAP[value]);
                            }}
                          >
                            {banksMasterData?.map((bank) => (
                              <option key={bank.id} value={bank.bankCode}>
                                {bank.bankName}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </Field>
                    <GridItem colSpan={{ base: 2, md: 2 }}>
                      <Field name="bankName">
                        {({ field }) => (
                          <input {...field} style={{ display: "none" }} />
                        )}
                      </Field>
                    </GridItem>
                  </GridItem>
                  <GridItem colSpan={{ base: 2, md: 1 }}>
                    <Field name="serviceType" validate={validateField}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.serviceType && form.touched.serviceType
                          }
                          isRequired
                        >
                          <FormLabel>ประเภทบริการ</FormLabel>
                          <Select
                            {...field}
                            borderRadius="33px"
                            placeholder="ประเภทบริการ"
                            fontSize="sm"
                            onChange={(e) => {
                              const value = e.target.value;
                              props.setFieldValue("serviceType", value);
                              fetchBanksMasterData(
                                value,
                                props.values?.nationality,
                              );
                            }}
                          >
                            {BANK_SERVICE_OPTIONS.filter((bank) => {
                              if (bank.value === BankServiceTypes.MANUAL)
                                return true;
                              if (
                                bank.value === BankServiceTypes.API &&
                                props.values.bankCode === "014"
                              )
                                return true;
                              if (
                                bank.value === BankServiceTypes.SCB_CONNECT &&
                                props.values.bankType === BankTypes.DEPOSIT &&
                                props.values.bankCode === "014"
                              )
                                return true;

                              return false;
                            }).map((bank) => (
                              <option key={bank.value} value={bank.value}>
                                {bank.label}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  {props.values.serviceType === BankServiceTypes.API && (
                    <>
                      <GridItem colSpan={{ base: 2, md: 2 }}>
                        <Field name="deviceId" validate={validateField}>
                          {({ field, form }) => (
                            <FormControl
                              isInvalid={
                                form.errors.deviceId && form.touched.deviceId
                              }
                              isRequired={!bank}
                            >
                              <FormLabel>Device ID</FormLabel>
                              <Input
                                {...field}
                                fontSize="sm"
                                placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxxx"
                              />
                            </FormControl>
                          )}
                        </Field>
                      </GridItem>
                      <GridItem colSpan={{ base: 2, md: 1 }}>
                        <Field name="pin" validate={validateField}>
                          {({ field, form }) => (
                            <FormControl
                              isInvalid={form.errors.pin && form.touched.pin}
                              isRequired={!bank}
                            >
                              <FormLabel>PIN</FormLabel>
                              <Input
                                {...field}
                                fontSize="sm"
                                placeholder="xxxxxx"
                              />
                            </FormControl>
                          )}
                        </Field>
                      </GridItem>
                      <GridItem colSpan={{ base: 2, md: 1 }}>
                        <Field name="proxyIp" validate={validateField}>
                          {({ field, form }) => (
                            <FormControl
                              isInvalid={
                                form.errors.proxyIp && form.touched.proxyIp
                              }
                              isRequired={!bank}
                            >
                              <FormLabel>ไอพี Proxy</FormLabel>
                              <Input
                                {...field}
                                fontSize="sm"
                                placeholder="127.0.0.1"
                              />
                            </FormControl>
                          )}
                        </Field>
                      </GridItem>
                    </>
                  )}
                  <GridItem colSpan={2}>
                    <Field name="bankAccountName" validate={validateField}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.bankAccountName &&
                            form.touched.bankAccountName
                          }
                          isRequired
                        >
                          <FormLabel>ชื่อบัญชี</FormLabel>
                          <Input
                            {...field}
                            placeholder="ชื่อบัญชีตามสมุดบัญชีธนาคาร"
                          />
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={2}>
                    <Field name="bankAccountNo" validate={validateField}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.bankAccountNo &&
                            form.touched.bankAccountNo
                          }
                          isRequired
                        >
                          <FormLabel>เลขที่บัญชี</FormLabel>
                          <Input {...field} placeholder="เลขที่บัญชี" />
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={2}>
                    <Text
                      fontSize="16px"
                      fontWeight="bold"
                      pt={4}
                      pb={2}
                      color="#31AA93"
                    >
                      ข้อมูลอื่นๆ
                    </Text>
                  </GridItem>
                  <GridItem colSpan={{ base: 2, md: 1 }}>
                    <Field name="bankOrder" validate={validateField}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.bankOrder && form.touched.bankOrder
                          }
                          isRequired
                        >
                          <FormLabel>ลำดับ</FormLabel>
                          <Input {...field} placeholder="ลำดับ" />
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>

                  <GridItem colSpan={{ base: 2, md: 1 }}>
                    <Field name="bankGroup" validate={validateField}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.bankGroup && form.touched.bankGroup
                          }
                          isRequired
                        >
                          <FormLabel>กลุ่ม</FormLabel>
                          <Input {...field} placeholder="กลุ่ม" />
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem>
                    <Field name="currency" validate={validateField}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.currency && form.touched.currency
                          }
                          isRequired
                        >
                          <FormLabel>สกุลเงิน</FormLabel>
                          <Select
                            {...field}
                            borderRadius="33px"
                            placeholder="เลือกสกุลเงิน"
                            fontSize="sm"
                          >
                            <option value="THB">THB</option>
                          </Select>
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={{ base: 2, md: 1 }}>
                    <Field name="rate" validate={validateField}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={form.errors.rate && form.touched.rate}
                          isRequired
                        >
                          <FormLabel>เรท</FormLabel>
                          <Input {...field} placeholder="เรท" />
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={2}>
                    <Field name="memberLevelId">
                      {({ field }) => (
                        <FormControl>
                          <FormLabel>ระดับสมาชิก</FormLabel>
                          <Select
                            {...field}
                            borderRadius="33px"
                            placeholder="ทั้งหมด"
                            fontSize="sm"
                          >
                            {memberLevelData?.map((obj) => (
                              <option key={obj.id} value={obj.id}>
                                {obj.levelName}
                              </option>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={{ base: 2, md: 1 }}>
                    <Field name="minAmount" validate={validateField}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.minAmount && form.touched.minAmount
                          }
                          isRequired
                        >
                          <FormLabel>ยอดขั้นต่ำ</FormLabel>
                          <Input {...field} placeholder="ยอดขั้นต่ำ" />
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={{ base: 2, md: 1 }}>
                    <Field name="maxAmount" validate={validateField}>
                      {({ field, form }) => (
                        <FormControl
                          isInvalid={
                            form.errors.maxAmount && form.touched.maxAmount
                          }
                          isRequired
                        >
                          <FormLabel>ยอดสูงสุง</FormLabel>
                          <Input {...field} placeholder="ยอดสูงสุง" />
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>
                  <GridItem colSpan={2}>
                    <Box mt={4}>
                      <HStack spacing={4}>
                        <Field name="newUserFlag">
                          {({ field }) => (
                            <FormControl display="flex" alignItems="center">
                              <Switch
                                id="is-bonus-enabled"
                                {...field}
                                isChecked={field.value}
                              />
                              <FormLabel
                                htmlFor="is-bonus-enabled"
                                fontSize="16px"
                                ml={4}
                                mb={0}
                              >
                                สำหรับยูสเซอร์ใหม่
                              </FormLabel>
                            </FormControl>
                          )}
                        </Field>
                        <Field name="active">
                          {({ field }) => (
                            <FormControl display="flex" alignItems="center">
                              <Switch
                                id="is-no-bonus"
                                {...field}
                                isChecked={field.value}
                              />
                              <FormLabel
                                htmlFor="is-no-bonus"
                                fontSize="16px"
                                ml={4}
                                mb={0}
                              >
                                สถานะเปิดใช้งาน
                              </FormLabel>
                            </FormControl>
                          )}
                        </Field>
                      </HStack>
                    </Box>
                  </GridItem>
                </Grid>
                <ModalFooter pl={0} pr={0} mt={4}>
                  <Button
                    background="linear-gradient(90deg, #28AE95 0%, #0E7461 100%)"
                    fontWeight="normal"
                    fontSize="14px"
                    w="100%"
                    type="submit"
                    isLoading={props.isSubmitting}
                  >
                    ทำรายการ
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ManageBankModal;
