import baac from "assets/svg/bank/th/baac.svg";
import bay from "assets/svg/bank/th/bay.svg";
import bbl from "assets/svg/bank/th/bbl.svg";
import bnp from "assets/svg/bank/th/bnp.svg";
import boa from "assets/svg/bank/th/boa.svg";
import cacib from "assets/svg/bank/th/cacib.svg";
import cimb from "assets/svg/bank/th/cimb.svg";
import citi from "assets/svg/bank/th/citi.svg";
import db from "assets/svg/bank/th/db.svg";
import ghb from "assets/svg/bank/th/ghb.svg";
import gsb from "assets/svg/bank/th/gsb.svg";
import hsbc from "assets/svg/bank/th/hsbc.svg";
import ibank from "assets/svg/bank/th/ibank.svg";
import icbc from "assets/svg/bank/th/icbc.svg";
import jpm from "assets/svg/bank/th/jpm.svg";
import kbank from "assets/svg/bank/th/kbank.svg";
import kk from "assets/svg/bank/th/kk.svg";
import ktb from "assets/svg/bank/th/ktb.svg";
import lhb from "assets/svg/bank/th/lhb.svg";
import mb from "assets/svg/bank/th/mb.svg";
import mega from "assets/svg/bank/th/mega.svg";
import mufg from "assets/svg/bank/th/mufg.svg";
import rbs from "assets/svg/bank/th/rbs.svg";
import sc from "assets/svg/bank/th/sc.svg";
import scb from "assets/svg/bank/th/scb.svg";
import smbc from "assets/svg/bank/th/smbc.svg";

import tcrb from "assets/svg/bank/th/tcrb.svg";
import tisco from "assets/svg/bank/th/tisco.svg";
import ttb from "assets/svg/bank/th/ttb.svg";
import uob from "assets/svg/bank/th/uob.svg";

import la_bcel from "assets/svg/bank/laos/bcel.svg";
import la_ldb from "assets/svg/bank/laos/ldb.svg";
import la_stb from "assets/svg/bank/laos/stb.svg";
import la_bfl from "assets/svg/bank/laos/bfl.svg";
import la_lvb from "assets/svg/bank/laos/lvb.svg";
import la_jdb from "assets/svg/bank/laos/jdb.svg";
import la_kbank from "assets/svg/bank/laos/kbank.svg";
import la_ldb2 from "assets/svg/bank/laos/ldb2.svg";
import la_mjbl from "assets/svg/bank/laos/mjbl.svg";
import la_apb from "assets/svg/bank/laos/apb.svg";
import la_psvb from "assets/svg/bank/laos/psvb.svg";
import la_bic from "assets/svg/bank/laos/bic.svg";

export const banks = {
  "002": {
    logo: bbl,
    code: "002",
    bank_code: "bbl",
    color: "#1e4598",
    official_name: "BANGKOK BANK PUBLIC COMPANY LTD.",
    nice_name: "Bangkok Bank",
    name_th: "ธนาคารกรุงเทพ (BBL)",
  },
  "004": {
    logo: kbank,
    code: "004",
    bank_code: "kbank",
    color: "#138f2d",
    official_name: "KASIKORNBANK PUBLIC COMPANY LTD.",
    nice_name: "Kasikornbank",
    name_th: "ธนาคารกสิกรไทย (KBANK)",
  },
  "005": {
    logo: rbs,
    code: "005",
    bank_code: "rbs",
    color: "#032952",
    official_name: "THE ROYAL BANK OF SCOTLAND PLC",
    nice_name: "Royal Bank of Scotland",
    name_th: "รอยัล ธนาคารแห่งสกอตแลนด์ (RBS)",
  },
  "006": {
    logo: ktb,
    code: "006",
    bank_code: "ktb",
    color: "#1ba5e1",
    official_name: "KRUNG THAI BANK PUBLIC COMPANY LTD.",
    nice_name: "Krungthai Bank",
    name_th: "ธนาคารกรุงไทย (KTB)",
  },
  "008": {
    logo: jpm,
    code: "008",
    bank_code: "jpm",
    color: "#321c10",
    official_name: "JPMORGAN CHASE BANK, NATIONAL ASSOCIATION",
    nice_name: "J.P. Morgan",
    name_th: "ธนาคารเจพีมอร์แกนเชส (JPM)",
  },
  "010": {
    logo: mufg,
    code: "010",
    bank_code: "mufg",
    color: "#d61323",
    official_name: "THE BANK OF TOKYO-MITSUBISHI UFJ, LTD.",
    nice_name: "Bank of Tokyo-Mitsubishi UFJ",
    name_th: "ธนาคารโตเกียว-มิตซูบิชิ ยูเอฟเจ (MUFG)",
  },
  "011": {
    logo: ttb,
    code: "011",
    bank_code: "ttb",
    color: "#fff",
    official_name: "TMBThanachart BANK PUBLIC COMPANY LIMITED.",
    nice_name: "TTB",
    name_th: "ธนาคารทหารไทยธนชาต (TTB)",
  },
  "014": {
    logo: scb,
    code: "014",
    bank_code: "scb",
    color: "#4e2e7f",
    official_name: "SIAM COMMERCIAL BANK PUBLIC COMPANY LTD.",
    nice_name: "Siam Commercial Bank",
    name_th: "ธนาคารไทยพาณิชย์ (SCB)",
  },
  "017": {
    logo: citi,
    code: "017",
    bank_code: "citi",
    color: "#1583c7",
    official_name: "CITIBANK, N.A.",
    nice_name: "Citibank",
    name_th: "ธนาคารซิตี้แบงก์ (CITI)",
  },
  "018": {
    logo: smbc,
    code: "018",
    bank_code: "smbc",
    color: "#a0d235",
    official_name: "SUMITOMO MITSUI BANKING CORPORATION",
    nice_name: "Sumitomo Mitsui Banking Corporation",
    name_th: "ธนาคารซูมิโตโม มิตซุย ทรัสต์ (SMBC)",
  },
  "020": {
    logo: sc,
    code: "020",
    bank_code: "sc",
    color: "#0f6ea1",
    official_name: "STANDARD CHARTERED BANK (THAI) PUBLIC COMPANY LIMITED",
    nice_name: "Standard Chartered (Thai)",
    name_th: "ธนาคารสแตนดาร์ดชาร์เตอร์ด (ไทย) (SC)",
  },
  "022": {
    logo: cimb,
    code: "022",
    bank_code: "cimb",
    color: "#7e2f36",
    official_name: "CIMB THAI BANK PUPBLIC COMPANY LTD.",
    nice_name: "CIMB Thai Bank",
    name_th: "ธนาคารซีไอเอ็มบีไทย (CIMB)",
  },
  "024": {
    logo: uob,
    code: "024",
    bank_code: "uob",
    color: "#0b3979",
    official_name: "UNITED OVERSEAS BANK (THAI) PUBLIC COMPANY LIMITED",
    nice_name: "United Overseas Bank (Thai)",
    name_th: "ธนาคารยูโอบี (UOB)",
  },
  "025": {
    logo: bay,
    code: "025",
    bank_code: "bay",
    color: "#fec43b",
    official_name: "BANK OF AYUDHYA PUBLIC COMPANY LTD.",
    nice_name: "Bank of Ayudhya (Krungsri)",
    name_th: "ธนาคารกรุงศรีอยุธยา (BAY)",
  },
  "026": {
    logo: mega,
    code: "026",
    bank_code: "mega",
    color: "#815e3b",
    official_name: "MEGA INTERNATIONAL COMMERCIAL BANK PUBLIC COMPANY LIMITED",
    nice_name: "Mega International Commercial Bank",
    name_th: "ธนาคารเมกะ สากลพาณิชย์ (MEGA)",
  },
  "027": {
    logo: boa,
    code: "027",
    bank_code: "boa",
    color: "#e11e3c",
    official_name: "BANK OF AMERICA, NATIONAL ASSOCIATION",
    nice_name: "Bank of America",
    name_th: "ธนาคารอเมริกา (BOA)",
  },
  "028": {
    logo: cacib,
    code: "028",
    bank_code: "cacib",
    color: "#0e765b",
    official_name: "CREDIT AGRICOLE CORPORATE AND INVESTMENT BANK",
    nice_name: "Crédit Agricole",
    name_th: "ธนาคารคาลิยง (CACIB)",
  },
  "030": {
    logo: gsb,
    code: "030",
    bank_code: "gsb",
    color: "#eb198d",
    official_name: "THE GOVERNMENT SAVINGS BANK",
    nice_name: "Government Savings Bank",
    name_th: "ธนาคารออมสิน (GSB)",
  },
  "031": {
    logo: hsbc,
    code: "031",
    bank_code: "hsbc",
    color: "#fd0d1b",
    official_name: "THE HONGKONG AND SHANGHAI BANKING CORPORATION LTD.",
    nice_name: "Hongkong and Shanghai Banking Corporation",
    name_th: "ธนาคารเอชเอสบีซี ประเทศไทย (HSBC)",
  },
  "032": {
    logo: db,
    code: "032",
    bank_code: "db",
    color: "#0522a5",
    official_name: "DEUTSCHE BANK AG.",
    nice_name: "Deutsche Bank",
    name_th: "ธนาคารดอยซ์ (DB)",
  },
  "033": {
    logo: ghb,
    code: "033",
    bank_code: "ghb",
    color: "#f57d23",
    official_name: "THE GOVERNMENT HOUSING BANK",
    nice_name: "Government Housing Bank",
    name_th: "ธนาคารอาคารสงเคราะห์ (GHB)",
  },
  "034": {
    logo: baac,
    code: "034",
    bank_code: "baac",
    color: "#4b9b1d",
    official_name: "BANK FOR AGRICULTURE AND AGRICULTURAL COOPERATIVES",
    nice_name: "Bank for Agriculture and Agricultural Cooperatives",
    name_th: "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร (BAAC)",
  },
  "039": {
    logo: mb,
    code: "039",
    bank_code: "mb",
    color: "#150b78",
    official_name: "MIZUHO BANK, LTD.",
    nice_name: "Mizuho Bank",
    name_th: "ธนาคารมิซูโฮ คอร์ปอเรต (MB)",
  },
  "045": {
    logo: bnp,
    code: "045",
    bank_code: "bnp",
    color: "#14925e",
    official_name: "BNP PARIBAS",
    nice_name: "BNP Paribas",
    name_th: "ธนาคารบีเอ็นพี พารีบาส์ (BNP)",
  },
  "066": {
    logo: ibank,
    code: "066",
    bank_code: "ibank",
    color: "#184615",
    official_name: "ISLAMIC BANK OF THAILAND",
    nice_name: "Islamic Bank of Thailand",
    name_th: "ธนาคารอิสลามแห่งประเทศไทย (IBANK)",
  },
  "067": {
    logo: tisco,
    code: "067",
    bank_code: "tisco",
    color: "#12549f",
    official_name: "TISCO BANK PUBLIC COMPANY LIMITED",
    nice_name: "Tisco Bank",
    name_th: "ธนาคารทิสโก้ (TISCO)",
  },
  "069": {
    logo: kk,
    code: "069",
    bank_code: "kk",
    color: "#199cc5",
    official_name: "KIATNAKIN BANK PUBLIC COMPANY LIMITED",
    nice_name: "Kiatnakin Bank",
    name_th: "ธนาคารเกียรตินาคิน (KK)",
  },
  "070": {
    logo: icbc,
    code: "070",
    bank_code: "icbc",
    color: "#c50f1c",
    official_name:
      "INDUSTRIAL AND COMMERCIAL BANK OF CHINA (THAI) PUBLIC COMPANY LIMITED",
    nice_name: "Industrial and Commercial Bank of China (Thai)",
    name_th: "ธนาคารไอซีบีซี (ไทย) (ICBC)",
  },
  "071": {
    logo: tcrb,
    code: "071",
    bank_code: "tcrb",
    color: "#0a4ab3",
    official_name: "THE THAI CREDIT RETAIL BANK PUBLIC COMPANY LIMITED",
    nice_name: "Thai Credit Retail Bank",
    name_th: "ธนาคารไทยเครดิตเพื่อรายย่อย (TCRB)",
  },
  "073": {
    logo: lhb,
    code: "073",
    bank_code: "lhb",
    color: "#6d6e71",
    official_name: "LAND AND HOUSES BANK PUBLIC COMPANY LIMITED",
    nice_name: "Land and Houses Bank",
    name_th: "ธนาคารแลนด์ แอนด์ เฮ้าส์ (LHB)",
  },
  1001: {
    logo: la_bcel,
    code: "1001",
    bank_code: "bcel",
    color: "#199cc5",
    official_name: "ธนาคารการค้าต่างประเทศลาวมหาชน",
    nice_name: "ธนาคารการค้าต่างประเทศลาวมหาชน",
    name_th: "ธนาคารการค้าต่างประเทศลาวมหาชน",
  },
  1002: {
    logo: la_ldb,
    code: "1002",
    bank_code: "ldb",
    color: "#199cc5",
    official_name: "ธนาคารพัฒนาลาว",
    nice_name: "ธนาคารพัฒนาลาว",
    name_th: "ธนาคารพัฒนาลาว",
  },
  1003: {
    logo: la_stb,
    code: "1003",
    bank_code: "stb",
    color: "#199cc5",
    official_name: "ธนาคาร เอสที จำกัด",
    nice_name: "ธนาคาร เอสที จำกัด",
    name_th: "ธนาคาร เอสที จำกัด",
  },
  1004: {
    logo: la_bfl,
    code: "1004",
    bank_code: "bfl",
    color: "#199cc5",
    official_name: "ธนาคาร ลาว-ฝรั่ง",
    nice_name: "ธนาคาร ลาว-ฝรั่ง",
    name_th: "ธนาคาร ลาว-ฝรั่ง",
  },
  1005: {
    logo: la_lvb,
    code: "1005",
    bank_code: "lvb",
    color: "#199cc5",
    official_name: "ธนาคาร ร่วมธุรกิจ ลาว-เวียด",
    nice_name: "ธนาคาร ร่วมธุรกิจ ลาว-เวียด",
    name_th: "ธนาคาร ร่วมธุรกิจ ลาว-เวียด",
  },
  1006: {
    logo: la_jdb,
    code: "1006",
    bank_code: "jdb",
    color: "#199cc5",
    official_name: "ธนาคาร ร่วมพัฒนา",
    nice_name: "ธนาคาร ร่วมพัฒนา",
    name_th: "ธนาคาร ร่วมพัฒนา",
  },

  1007: {
    logo: la_kbank,
    code: "1007",
    bank_code: "kbank",
    color: "#199cc5",
    official_name: "ธนาคาร กสิกรไทย จำกัด",
    nice_name: "ธนาคาร กสิกรไทย จำกัด",
    name_th: "ธนาคาร กสิกรไทย จำกัด",
  },
  1008: {
    logo: la_ldb2,
    code: "1008",
    bank_code: "ldb2",
    color: "#199cc5",
    official_name: "ธนาคาร ลาวพัฒนา",
    nice_name: "ธนาคาร ลาวพัฒนา",
    name_th: "ธนาคาร ลาวพัฒนา",
  },
  1009: {
    logo: la_mjbl,
    code: "1009",
    bank_code: "mjbl",
    color: "#199cc5",
    official_name: "ธนาคาร มาลู มารูฮันเจแปนลาว",
    nice_name: "ธนาคาร มาลู มารูฮันเจแปนลาว",
    name_th: "ธนาคาร มาลู มารูฮันเจแปนลาว",
  },
  1010: {
    logo: la_apb,
    code: "1010",
    bank_code: "apb",
    color: "#199cc5",
    official_name: "ธนาคาร ส่งเสริมกสิกรรม",
    nice_name: "ธนาคาร ส่งเสริมกสิกรรม",
    name_th: "ธนาคาร ส่งเสริมกสิกรรม",
  },
  1011: {
    logo: la_psvb,
    code: "1011",
    bank_code: "psvb",
    color: "#199cc5",
    official_name: "ธนาคาร พงศ์สวรรค์ จำกัด",
    nice_name: "ธนาคาร พงศ์สวรรค์ จำกัด",
    name_th: "ธนาคาร พงศ์สวรรค์ จำกัด",
  },
  1012: {
    logo: la_bic,
    code: "1012",
    bank_code: "bic",
    color: "#199cc5",
    official_name: "ธนาคาร บีไอซี ลาว จำกัด",
    nice_name: "ธนาคาร บีไอซี ลาว จำกัด",
    name_th: "ธนาคาร บีไอซี ลาว จำกัด",
  },
};
