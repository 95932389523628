export const modalStyles = {
  components: {
    Modal: {
      baseStyle: {
        dialog: {
          borderRadius: "12px",
          border: "1px solid",
          borderColor: "rgba(55, 63, 72, 1)",
        },
        header: {
          backgroundColor: "gray.800",
          borderBottom: "1px solid rgba(55, 63, 72, 1)",
          borderTopRadius: "12px",
        },
      },
    },
  },
};
