import { Badge, Flex, Icon, IconButton, Text } from "@chakra-ui/react";
import {
  DeleteIcon,
  DesktopIcon,
  EditIcon,
  MobileIcon,
} from "../../../components/Icons/Icons";

export const footerSettingColumnsData = (handleModalOpen) => [
  {
    Header: "ไอคอน",
    accessor: "imageUrl",
    textAlign: "left",
    justifyContent: "flex-start",
    Cell: ({ value }) => (
      <Flex alignItems="center" height="100%">
        <img
          src={value}
          alt="icon"
          style={{ width: "24px", height: "24px", objectFit: "contain" }}
        />
      </Flex>
    ),
  },
  {
    Header: "สถานะ",
    accessor: "status",
    Cell: ({ cell }) => (
      <Flex align="center" justify="center">
        <Badge
          bg={cell.value === "true" ? "green.500" : "red.500"}
          w="65px"
          h="28px"
          color="#fff"
          fontSize="12px"
          fontWeight="normal"
          borderRadius="15px"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {cell.value === "true" ? "แสดง" : "ไม่แสดง"}
        </Badge>
      </Flex>
    ),
  },
  {
    Header: "ชื่อ",
    accessor: "name",
    textAlign: "left",
    justifyContent: "flex-start",
  },
  {
    Header: "ประเภท",
    accessor: "type",
  },
  {
    Header: "ลำดับ",
    accessor: "sort",
  },
  {
    Header: "อุปกรณ์",
    accessor: "device",
    textAlign: "left",
    justifyContent: "flex-start",
    Cell: ({ value }) => (
      <div style={{ textAlign: "left" }}>
        {value === "1" ? (
          <Flex>
            <Icon as={MobileIcon} w={5} h={5} color="#8C9592" />
            <Text pl={2}>Mobile</Text>
          </Flex>
        ) : (
          <Flex>
            <Icon as={DesktopIcon} w={5} h={5} color="#8C9592" />
            <Text pl={2}>Desktop</Text>
          </Flex>
        )}
      </div>
    ),
  },
  {
    Header: "เครื่องมือ",
    textAlign: "left",
    justifyContent: "flex-start",
    Cell: ({ row }) => (
      <>
        <IconButton
          variant="solid"
          color="#fff"
          bg="green.500"
          aria-label="edit"
          size="sm"
          w={"36px"}
          h={"26px"}
          icon={<EditIcon />}
          onClick={() => handleModalOpen("manageFooterIconModal", row.original)}
          mr={2}
        />
        <IconButton
          variant="solid"
          color="#fff"
          bg="red.500"
          aria-label="delete"
          size="sm"
          w={"36px"}
          h={"26px"}
          icon={<Icon as={DeleteIcon} />}
          onClick={() => handleModalOpen("deleteFooterIconModal", row.original)}
        />
      </>
    ),
  },
];
