import { QueryClientProvider, useQueryClient } from "react-query";
import React from "react";
import { Box, Flex, Spacer, Stack, Text } from "@chakra-ui/react";
import AnimatedFlex from "components/AnimatedFlex/MotionFlex";
import CustomRangePicker from "components/CustomRangePicker/CustomRangePicker";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";
import SearchTableRemote from "components/Tables/SearchTableRemote";
import { memberReceivePromotionReportColumnsData } from "variables/columns/member-report/memberReceivePromotionReportColumnsData";
import { APP_PROFILE } from "variables/system";
import { usePromotionSetting } from "hooks/business/system-setting/usePromotionSetting";
import { useMemberReceivePromotion } from "hooks/business/member-report/useMemberRecievePromotion";
import { useSearch } from "hooks/common/useSearch";
import { QueryKeys } from "variables/queryKeys";

const MemberReceivePromotion = ({ pageName }) => {
  const queryClient = useQueryClient();

  const dateFieldsNames = {
    from: "createdDateFrom",
    to: "createdDateTo",
  };
  const dateSearchFormat = "DD/MM/yyyy HH:mm:ss";

  const { promotionData } = usePromotionSetting();

  const searchOptions = promotionData?.map((promotion) => ({
    label: promotion.name,
    value: promotion.name,
  }));

  const handleOptionChange = (selectedOption) => {
    setSearch({
      ...search,
      promotionName: selectedOption ? selectedOption.value : null,
    });
  };

  const role = JSON.parse(localStorage.getItem(APP_PROFILE)).role;
  const { fetchData } = useMemberReceivePromotion(role);

  const { search, setSearch, selectedDates, handleDateChange } = useSearch(
    dateFieldsNames,
    dateSearchFormat,
  );

  return (
    <Flex direction="column">
      <Stack direction="column" spacing="24px" w="100%">
        <AnimatedFlex justify="space-between" duration={0.4} delay={0.4}>
          <Text fontWeight="bold" fontSize="24px" color="green.500">
            {pageName}
          </Text>
        </AnimatedFlex>
        <AnimatedFlex duration={0.4} delay={0.6}>
          <Card px="28px" pb="38px">
            <CardHeader pb="12px" pt="12px">
              <Flex direction="column">
                <Text fontSize="18px" fontWeight="bold" mb="6px" color="#fff">
                  รายงานสมาชิครับโปรโม่ชั่น
                </Text>
              </Flex>
              <Spacer />
              <Box>
                <CustomRangePicker
                  selectedDates={selectedDates}
                  onDateChange={handleDateChange}
                />
              </Box>
            </CardHeader>
            <CardBody>
              <Flex direction="column" align="flex-end" mb="24px" w="100%">
                <Box width="100%">
                  <QueryClientProvider client={queryClient}>
                    <SearchTableRemote
                      query={QueryKeys.MEMBER_RECEIVE_PROMOTION_REPORT}
                      columnsData={memberReceivePromotionReportColumnsData()}
                      fetchData={fetchData}
                      search={search}
                      setSearch={setSearch}
                      showSearchableDropdown={true}
                      searchOptions={searchOptions}
                      onChange={handleOptionChange}
                    />
                  </QueryClientProvider>
                </Box>
              </Flex>
            </CardBody>
          </Card>
        </AnimatedFlex>
      </Stack>
    </Flex>
  );
};

export default MemberReceivePromotion;
