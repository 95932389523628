import { useMutation, useQuery, useQueryClient } from "react-query";

import { useErrorHandler } from "../../common/useErrorHandler";
import { useSuccessHandler } from "../../common/useSuccessHandler";
import { QueryKeys } from "../../../variables/queryKeys";
import { ERROR_MESSAGE } from "../../../variables/system";
import {
  fetchActivityImageSetting,
  updateActivityImageSetting,
} from "../../../services/website-setting/activityImageSettingService";

export const useActivityImage = () => {
  const queryClient = useQueryClient();
  const handleError = useErrorHandler();
  const handleSuccess = useSuccessHandler();

  const { data: activityImageData, isLoading } = useQuery(
    [QueryKeys.ACTIVITY_IMAGE],
    async () => {
      const response = await fetchActivityImageSetting();
      return response.data.data;
    },
    {
      onError: (error) => handleError(error, ERROR_MESSAGE),
    },
  );

  const mutation = useMutation(
    (updatedData) => updateActivityImageSetting({ data: updatedData }),
    {
      onSuccess: (response) => {
        handleSuccess(response.data.message);
        queryClient.invalidateQueries([QueryKeys.ACTIVITY_IMAGE]);
      },
      onError: (error) => handleError(error, ERROR_MESSAGE),
    },
  );

  const handleToggle = (id) => {
    if (!activityImageData) return;

    const updatedData = activityImageData.map((activity) => {
      if (activity.id === id) {
        return { ...activity, active: !activity.active };
      }
      return activity;
    });

    mutation.mutate(updatedData);
  };

  const handleImageUpload = (id, newImageUrl) => {
    if (!activityImageData) return;

    const updatedData = activityImageData.map((activity) => {
      if (activity.id === id) {
        return { ...activity, backgroundImage: newImageUrl };
      }
      return activity;
    });

    mutation.mutate(updatedData);
  };

  return {
    activityImageData,
    isLoading,
    handleToggle,
    handleImageUpload,
    isUpdating: mutation.isLoading,
  };
};
